import axios, {AxiosError, AxiosResponse} from 'axios'
import {UsersQueryResponse} from '../modules/apps/educator-management/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/admin/users`

const getStats = (): Promise<UsersQueryResponse> => {
  return axios.get(`${USER_URL}/stats`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

export {getStats}

import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'

interface Props {
  value: any
  setFieldValue: any
  field: string
}
export const VehicleDetails: FC<Props> = ({value, setFieldValue, field}) => {
  return (
    <>
      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Car registration number:</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`${field}.carRegistrationNumber`}
            placeholder='Car Registration Number'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.carRegistrationNumber`} />
          </div>
        </div>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>No. of seats:</span>
          </label>

          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`${field}.carNoOfSeats`}
            placeholder='No. of seats'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.carNoOfSeats`} />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className=''>Car registration number:</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`${field}.secondCarRegistrationNumber`}
            placeholder='Car Registration Number'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.secondCarRegistrationNumber`} />
          </div>
        </div>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className=''>No. of seats:</span>
          </label>

          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`${field}.secondCarNoOfSeats`}
            placeholder='No. of seats'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.secondCarNoOfSeats`} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>
              Each child meets the individual requirements for seat belts and safety restraints in
              Victoria:
            </span>
          </label>
          <div className='row'>
            <span className='align-items-center fs-5  m-2 col '>
              <Field
                type='radio'
                className='me-2'
                name={`${field}.individualRequirements`}
                value='yes'
              />
              Yes
            </span>
            <span className='align-items-center fs-5  m-2 col '>
              <Field
                type='radio'
                className='me-2'
                name={`${field}.individualRequirements`}
                value='no'
              />
              No
            </span>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.individualRequirements`} />
          </div>
        </div>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className=''>No. of boosters or car seats:</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`${field}.carBoosters`}
            placeholder='No. of Boosters or car seats'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.carBoosters`} />
          </div>
        </div>
      </div>
    </>
  )
}

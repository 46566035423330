import React, {FC, RefObject, useEffect, useRef} from 'react'
import {ErrorMessage, Field} from 'formik'
import DatePicker from '../../../common/DatePicker'
import {AddressDetailInfo} from '../../../common/AddressDetailInfo'
import {SignatureField} from '../../../common/SignatureField'

interface Props {
  values: any
  setFieldValue: any
}

const EducatorFeeSchedule: FC<Props> = ({values, setFieldValue}) => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Educator Fee Schedule</h2>
      </div>
      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Educator Name:</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='educator.basicInformation.fullName'
            placeholder='Full Name'
          />
        </div>

        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Effective From:</span>
          </label>
          <DatePicker
            dateFormat={'MMM dd, yyyy'}
            name='schedule.effectiveFrom'
            selected={(values?.schedule?.effectiveFrom && new Date()) || null}
            placeholderText='Effective From'
            onChange={(value: any) => setFieldValue('schedule.effectiveFrom', value)}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='schedule.effectiveFrom' />
          </div>
        </div>

        <AddressDetailInfo
          values={values}
          setFieldValue={setFieldValue}
          field='educator.basicInformation.dayCareAddress'
          mandatory={false}
        />
        <div className='row'>
          <div className='col-md-12 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Scheme:</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='schedule.scheme'
              placeholder='Scheme Name'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='schedule.scheme' />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='py-8 py-lg-10'>
          <h2 className='fw-bolder text-dark'>Fees</h2>
        </div>

        <div className='col-6'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='fw-bolder text-dark'>Hourly Rate $</span>
          </label>
          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name='schedule.hourlyRate'
            placeholder='Hourly Rate $'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='schedule.hourlyRate' />
          </div>
        </div>

        <div className='col-6'>
          <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
            <span className='fw-bolder text-dark'>Standard Hours</span>
          </label>
          <span>(8am to 6.30 pm Mon–Fri) $</span>
          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name='schedule.standardHoursRate'
            placeholder='Standard Hours Rate($)'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='schedule.standardHoursRate' />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-6'>
          <label className=' align-items-center fs-5 pe-1 fw-bold mb-2'>
            <span className='fw-bolder text-dark'>Before / After School rate</span>
          </label>
          <span>(7am-9am to 3pm-6.30 pm Mon–Fri) $</span>
          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name='schedule.beforeAfterSchool'
            placeholder='Before / After School rate($)'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='schedule.beforeAfterSchool' />
          </div>
        </div>

        <div className='col-6'>
          <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
            <span className='fw-bolder text-dark'>Saturday Rate</span>
          </label>
          <span>($)</span>
          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name='schedule.standardHoursRate'
            placeholder='Saturday Rate($)'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='schedule.standardHoursRate' />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='py-8 py-lg-10'>
          <h2 className='fw-bolder text-dark'>Explanation of fee structure</h2>
        </div>
        <span>
          <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>Standard Hours:</label> Those
          hours between 8am and 6pm Monday through to Friday. This is an hourly rate.
        </span>

        <span>
          <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
            Before / After School:
          </label>
          A rate for school age children during Non-Standard Hours only, i.e. 7am-8am and 6pm-8pm
          Monday to Friday. Applies throughout the year.
        </span>

        <span>
          <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>Saturday:</label>A rate for
          all hours given on a Saturday.
        </span>

        <span>
          <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>Public Holiday:</label>A rate
          for all hours given on a Public Holiday.
        </span>
      </div>
      <div className='row pt-8'>
        <div className='col-6'>
          <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
            <span className='fw-bolder text-dark'>Parent Name</span>
          </label>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`${[values?.guardian?.responsible]}.fullName`}
            placeholder='Parent Name'
          />
        </div>

        <div className='col-6'>
          <SignatureField
            title='Parent Signature'
            field='signature'
            value={values?.signature}
            setFieldValue={setFieldValue}
            disabled={true}
          />
        </div>
      </div>

      <div className='row pt-8'>
        <div className='col-6'>
          <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
            <span className='fw-bolder text-dark'>Scheme Representative Name</span>
          </label>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='schedule.representativeName'
            placeholder='Representative Name'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='schedule.representativeName' />
          </div>
        </div>

        <div className='col-6'>
          <SignatureField
            title='Scheme Representative Signature'
            field='representativeSignature'
            value={values?.representativeSignature}
            setFieldValue={setFieldValue}
            disabled={true}
          />
        </div>
      </div>

      <div className='row pt-8'>
        <div className='col-6'>
          <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
            <span className='fw-bolder text-dark'>Educator Name</span>
          </label>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='educator.basicInformation.fullName'
            placeholder='Educator Name'
          />
        </div>

        <div className='col-6'>
          <SignatureField
            title='Educator Signature'
            field='educator.signature'
            value={values?.educator?.signature}
            setFieldValue={setFieldValue}
            disabled={true}
          />
        </div>
      </div>
    </div>
  )
}

export {EducatorFeeSchedule}

import React, {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {pdfjs, Document, Page} from 'react-pdf'
import UploadFile from '../../../common/UploadFile'
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface Props {
  values: any
  setFieldValue: any
}
const EducatorMedicalCertificate: FC<Props> = ({setFieldValue, values}) => {
  const [numPages, setNumPages] = useState(null)
  function onDocumentLoadSuccess({numPages}: any) {
    setNumPages(numPages)
  }
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>EDUCATOR MEDICAL CERTIFICATE FORM</h2>
        <Link
          className='btn btn-success float-end'
          to='/EducatorMedicalCertificate.pdf'
          target='_blank'
        >
          Download PDF
        </Link>
      </div>
      <div className='all-page-container d-flex justify-content-center'>
        <Document file='/EducatorMedicalCertificate.pdf' onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>

      <div className='col-md-12 fv-row mb-5'>
        <UploadFile
          label='Upload File'
          field='medicalCertificate'
          setFieldValue={setFieldValue}
          lastStep={true}
          value={values?.medicalCertificate}
        />
      </div>
    </div>
  )
}

export {EducatorMedicalCertificate}

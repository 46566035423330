import React from 'react'
import {useListView} from '../../core/ListViewProvider'
import {ChildrenListToolbar} from './ChildrenListToolbar'
import {ChildrenListGrouping} from './ChildrenListGrouping'
import {ChildrenListSearchComponent} from './ChildrenListSearchComponent'

const ChildrenListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ChildrenListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ChildrenListGrouping /> : <ChildrenListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ChildrenListHeader}

import React from 'react'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {EducatorsListWrapper} from './educators-list/EducatorsList'
import {EducatorEditForm} from './educator-view/EducatorEdit'

const educatorsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Educators',
    path: '/apps/educator-management/educators',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Educator',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EducatorsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='educators/:id/view'
          element={
            <>
              <PageTitle breadcrumbs={educatorsBreadcrumbs}>Educator Profile</PageTitle>
              <EducatorEditForm />
            </>
          }
        />

        <Route
          path='educators/:id/edit'
          element={
            <>
              <PageTitle breadcrumbs={educatorsBreadcrumbs}>Educator Edit</PageTitle>
              <EducatorEditForm />
            </>
          }
        />

        <Route
          path='educators'
          element={
            <>
              <PageTitle breadcrumbs={educatorsBreadcrumbs}>Educators list</PageTitle>
              <EducatorsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/educator-management/educators' />} />
    </Routes>
  )
}

export default EducatorsPage

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Field, ErrorMessage, FieldArray} from 'formik'
import {ContactPhoneField} from '../ContactPhoneField'
import {useAuth} from '../../../../auth'
import {AddressDetailInfo} from '../../../common/AddressDetailInfo'
import DatePicker from '../../../common/DatePicker'

const ApplicationForm: FC<any> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  useEffect(() => {
    if (
      !values?.basicInformation?.email ||
      values?.basicInformation?.email !== currentUser?.email
    ) {
      setFieldValue('basicInformation.email', currentUser?.email)
    }
  }, [])
  useEffect(() => {
    if (values.basicInformation?.sameAddress) {
      setFieldValue('basicInformation.postalAddress', values?.basicInformation?.dayCareAddress)
    }
  }, [values?.basicInformation?.dayCareAddress])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Application Forms</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          We are looking for exceptional people to be part of this wondrous community. If you are
          one of them please apply.
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Given Name</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.fullName'
              placeholder='Given Name'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.fullName' />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Family Name</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.familyName'
              placeholder='Family Name'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.familyName' />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>CRN Number</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Centerlink Customer Reference Number'
              ></i>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.CRN'
              placeholder='xxxxxxxxxx'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.CRN' />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>RA Number</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Registration Authority'
              ></i>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.RANumber'
              placeholder='xxxxxxxxxx'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.RANumber' />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Date of Birth</span>
            </label>
            <DatePicker
              name='basicInformation.dob'
              selected={
                (values?.basicInformation?.dob && new Date(values?.basicInformation?.dob)) || null
              }
              placeholderText='Date of birth'
              onChange={(value: any) => setFieldValue('basicInformation.dob', value)}
            />

            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.dob' />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Driving License</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.drivingLicense'
              placeholder='xxxxxx'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.drivingLicense' />
            </div>
          </div>
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>ABN</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Australian Business Number'
              ></i>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.ABN'
              placeholder='xxxxxxxxxxxxx'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.ABN' />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Email Address</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.email'
              placeholder='Email'
              disabled
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.email' />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Contact Phone</span>
        </label>
        <FieldArray
          name='basicInformation.contactPhone'
          render={(arrayHelpers) => (
            <ContactPhoneField
              values={values?.basicInformation?.contactPhone}
              arrayHelpers={arrayHelpers}
              field='basicInformation.contactPhone'
            />
          )}
        />
      </div>

      <AddressDetailInfo
        values={values}
        setFieldValue={setFieldValue}
        field={'basicInformation.dayCareAddress'}
        title={'Address where education and care is provided'}
      />
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5  mb-2'>
          <span className='required'>Checked address on BARR</span>
          <label className='align-items-center fs-5 ms-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.isBARRAddress'
              value='true'
            />
            Yes
          </label>
          <label className='align-items-center fs-5  ms-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.isBARRAddress'
              value='false'
            />
            No
          </label>
        </label>

        <div className='text-danger mt-2'>
          <ErrorMessage name='basicInformation.isBARRAddress' />
        </div>
      </div>
      <div className='fv-row'>
        <label className='d-flex align-items-center fs-5  mb-2'>
          <span className='required'>In bush fire risk zone?</span>
        </label>
        <label className='align-items-center fs-5'>
          <Field type='radio' className='me-2' name='basicInformation.bushFireRisk' value='true' />
          Yes
        </label>
        <label className='align-items-center fs-5  m-2'>
          <Field type='radio' className='me-2' name='basicInformation.bushFireRisk' value='false' />
          No
        </label>
        <div className='text-danger mt-2'>
          <ErrorMessage name='basicInformation.bushFireRisk' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5  mb-2'>
          <span className='required'>Please confirm whether care if provided in a Home:</span>
          <label className='align-items-center fs-5  m-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.dayCareAtHome'
              value='true'
            />
            Home
          </label>
          <label className='align-items-center fs-5  m-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.dayCareAtHome'
              value='false'
            />
            Venue
          </label>
        </label>

        <div className='text-danger mt-2'>
          <ErrorMessage name='basicInformation.dayCareAtHome' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5  mb-2'>
          <span className='required'>
            Please confirm that is there any pool or spa in your home :
          </span>
          <label className='align-items-center fs-5 ms-2'>
            <Field type='radio' className='me-2' name='basicInformation.isPoolOrSpa' value='true' />
            Yes
          </label>
          <label className='align-items-center fs-5  ms-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.isPoolOrSpa'
              value='false'
            />
            No
          </label>
        </label>

        <div className='text-danger mt-2'>
          <ErrorMessage name='basicInformation.isPoolOrSpa' />
        </div>
      </div>
      {/* <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between'>
          <label className='fs-5 fw-bold mb-2 '>
            <span className='required'>Postal Address</span>
          </label>
          <label className='d-flex align-items-center mb-2 '>
            <Field
              type='checkbox'
              className='ms-2'
              name='basicInformation.sameAddress'
              onClick={(e: any) => {
                if (e.target.checked) {
                  setFieldValue(
                    'basicInformation.postalAddress',
                    values?.basicInformation?.dayCareAddress
                  )
                } else {
                  setFieldValue('basicInformation.postalAddress', {
                    streetAddress: [''],
                    suburb: '',
                    state: '',
                    country: 'Australia',
                  })
                }
              }}
            />
            <span className='ms-2'>Same as above</span>
          </label>
        </div>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='basicInformation.postalAddress.streetAddress[0]'
          placeholder='Address line 1'
          disabled={values?.basicInformation?.sameAddress}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='basicInformation.postalAddress.streetAddress[0]' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='basicInformation.postalAddress.streetAddress[1]'
          placeholder='Address line 2'
          disabled={values?.basicInformation?.sameAddress}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='basicInformation.postalAddress.streetAddress[1]' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <div className='row'>
          <div className='col-md-4 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Suburb</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.postalAddress.suburb'
              placeholder='Suburb'
              disabled={values?.basicInformation?.sameAddress}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.postalAddress.suburb' />
            </div>
          </div>

          <div className='col-md-4 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>State</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.postalAddress.state'
              placeholder='State'
              disabled={values?.basicInformation?.sameAddress}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.postalAddress.state' />
            </div>
          </div>
          <div className='col-md-4 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Country</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='basicInformation.postalAddress.country'
              disabled
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='basicInformation.postalAddress.country' />
            </div>
          </div>
        </div>
      </div> */}

      <AddressDetailInfo
        values={values}
        setFieldValue={setFieldValue}
        field={'basicInformation.postalAddress'}
        title={'Postal Address'}
      />
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5  mb-2'>
          <span className='required'>
            Have you ever been charged with or found guilty of a serious indictable offense
          </span>
          <label className='align-items-center fs-5 ms-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.isChargeGuilty'
              value='true'
            />
            Yes
          </label>
          <label className='align-items-center fs-5  ms-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.isChargeGuilty'
              value='false'
            />
            No
          </label>
        </label>

        <div className='text-danger mt-2'>
          <ErrorMessage name='basicInformation.isChargeGuilty' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5  mb-2'>
          <span className='required'>
            Has you Working With Children Check refused, amended or cancelled
          </span>
          <label className='align-items-center fs-5 ms-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.isCheckRefused'
              value='true'
            />
            Yes
          </label>
          <label className='align-items-center fs-5  ms-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.isCheckRefused'
              value='false'
            />
            No
          </label>
        </label>

        <div className='text-danger mt-2'>
          <ErrorMessage name='basicInformation.isCheckRefused' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5  mb-2'>
          <span className='required'>Becomes bankrupt</span>
          <label className='align-items-center fs-5 ms-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.isBankRupted'
              value='true'
            />
            Yes
          </label>
          <label className='align-items-center fs-5  ms-2'>
            <Field
              type='radio'
              className='me-2'
              name='basicInformation.isBankRupted'
              value='false'
            />
            No
          </label>
        </label>

        <div className='text-danger mt-2'>
          <ErrorMessage name='basicInformation.isBankRupted' />
        </div>
      </div>
    </div>
  )
}

export {ApplicationForm}

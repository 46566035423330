import {ErrorMessage, Field, FieldArray} from 'formik'
import {FC} from 'react'
import {DaysAndTimes} from './DaysAndTimes'
import {EstimatedTime} from './EstimatedTime'
import Collapsible from '../../../common/Collapsible'
import UploadFile from '../../../common/UploadFile'

interface IProps {
  value: any
  setFieldValue: any
  field: string
}
export const Timings: FC<IProps> = ({value, setFieldValue, field}) => {
  return (
    <Collapsible title={`Timings`} id={`risk-management-timings`}>
      <FieldArray
        name={`${field}.travelDaysTime`}
        render={(arrayHelpers) => (
          <DaysAndTimes
            value={value?.travelDaysTime || ['']}
            arrayHelpers={arrayHelpers}
            field={`${field}.travelDaysTime`}
            setFieldValue={setFieldValue}
          />
        )}
      />

      <FieldArray
        name={`${field}.travelTimeLocation`}
        render={(arrayHelpers) => (
          <EstimatedTime
            value={value?.travelTimeLocation || ['']}
            arrayHelpers={arrayHelpers}
            field={`${field}.travelTimeLocation`}
            setFieldValue={setFieldValue}
          />
        )}
      />
      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Period Children will be away from the premises</span>
          </label>
          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`${field}.childrenPeriod`}
            placeholder='Children Period'
          />

          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.childrenPeriod`} />
          </div>
        </div>
        <div className='col-md-6 fv-row mb-5'>
          <UploadFile
            label='Proposed route to and from the destination'
            field={`${field}.routeFile`}
            setFieldValue={setFieldValue}
            value={value?.routeFile}
          />
        </div>
      </div>
    </Collapsible>
  )
}

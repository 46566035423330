import React from 'react'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ChildrenListHeader} from './components/header/ChildrenListHeader'
import {ChildrenTable} from './table/ChildrenTable'
import {ChildEditModal} from './children-edit-modal/ChildEditModal'
import {KTCard} from '../../../../../_metronic/helpers'

const EducatorsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ChildrenListHeader />
        <ChildrenTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ChildEditModal />}
    </>
  )
}

const ChildrenListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EducatorsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ChildrenListWrapper}

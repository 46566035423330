/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage, FieldArray} from 'formik'
import {useAuth} from '../../../../auth'
import ChildHeader from '../../../common/ChildFormHeader'
import Select from 'react-select'
import {days} from '../../core/ChildEnrolmentHelpers'
import {TimeRangePicker} from '../../../common/TimeRangePicker'
import {BookingHours} from '../BookingHours'

const ChildSchedule: FC<any> = ({values, setFieldValue, errors}) => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <div className='fv-row mb-10'>
              <div className='row'>
                <div className='col-md-6 fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>Child name</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='child.fullName'
                    placeholder='Child Name'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='child.fullName' />
                  </div>
                </div>
              </div>
            </div>
            <div className='fv-row mb-10'>
              <FieldArray
                name='childSchedule.schoolSchedule'
                render={(arrayHelpers) => (
                  <BookingHours
                    values={
                      values?.childSchedule?.schoolSchedule || [
                        {
                          day: '',
                          beforeSchoolCareTime: '',
                          afterSchoolCareTime: '',
                        },
                      ]
                    }
                    arrayHelpers={arrayHelpers}
                    field='childSchedule.schoolSchedule'
                    setFieldValue={setFieldValue}
                  />
                )}
              />
            </div>
            <div className='fv-row mb-10'>
              <FieldArray
                name='childSchedule.holidaySchedule'
                render={(arrayHelpers) => (
                  <BookingHours
                    values={
                      values?.childSchedule?.holidaySchedule || [
                        {
                          day: '',
                          careTime: '',
                        },
                      ]
                    }
                    arrayHelpers={arrayHelpers}
                    field='childSchedule.holidaySchedule'
                    setFieldValue={setFieldValue}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChildSchedule}

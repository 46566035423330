import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Select from 'react-select'
import {initialUser, User} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createUser, updateUser} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useQuery, useQueryClient} from 'react-query'

type Props = {
  isUserLoading: boolean
  user: User
}

const statusData = [
  {label: 'Active', value: 'Active'},
  {label: 'Inactive', value: 'Inactive'},
  {label: 'Pending', value: 'Pending'},
]

const getSchema = (id: string) => {
  let schema = Yup.object({
    firstName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('First name is required'),
    role: Yup.string().label('Role').required('Please select any option'),
    status: Yup.string().label('Status').required('Please select any option'),
    contact: Yup.string().phone('AU', 'Contact Number is invalid').required('Contact is required'),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    lastName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Last name is required'),
  })

  if (!id) {
    schema = schema.concat(
      Yup.object({
        password: Yup.string()
          .min(1, 'Minimum 1 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Password is required')
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            'Password must contain upper and lowercase letters and at least one number'
          ),
        confirmPassword: Yup.string()
          .required('Password confirmation is required')
          .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              "Password and Confirm Password didn't match"
            ),
          }),
      })
    )
  }
  return schema
}

const UserEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [rolesData, setRolesData]: any = useState([])
  const queryClient = useQueryClient()
  const rolesQueryData: any = queryClient.getQueryData('activeRoles')

  useEffect(() => {
    setRolesData(
      rolesQueryData?.data?.map((role: any) => {
        return {label: role.name, value: role._id}
      })
    )
    // setInitValues({basicInformation: query?.data?.basicInformation})
  }, [])

  const [userForEdit] = useState<User>({
    _id: user._id,
    firstName: user?.firstName || initialUser?.firstName,
    lastName: user?.lastName || initialUser?.lastName,
    email: user.email || initialUser.email,
    [user._id ? '' : 'password']: user._id ? null : '',
    [user._id ? '' : 'confirmPassword']: user._id ? null : '',
    role: user.role || initialUser.role,
    roleId: user.roleId || null,
    status: user.status || '',
    contact: user.contact || '',
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: getSchema(userForEdit._id || ''),
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setSubmitting(true)
      try {
        if (values._id) {
          await updateUser(values)
        } else {
          await createUser(values)
        }
        cancel(true)
      } catch (ex: any) {
        setStatus(ex?.response?.data.message)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <div className='row mb-7'>
            {/* begin::Input group */}
            <div className='col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>First name</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='First name'
                {...formik.getFieldProps('firstName')}
                type='text'
                name='firstName'
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.firstName}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}
            <div className='col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Last name</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Last name'
                {...formik.getFieldProps('lastName')}
                type='text'
                name='lastName'
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.lastName}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}
          </div>
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className='form-control form-control-solid mb-3 mb-lg-0'
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Contact</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Contact'
              {...formik.getFieldProps('contact')}
              className='form-control form-control-solid mb-3 mb-lg-0'
              type='text'
              name='contact'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.contact && formik.errors.contact && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contact}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6'>Status</label>
            {/* end::Label */}
            <Select
              options={statusData}
              placeholder='Select Status'
              name='status'
              className='multi-select-container mt-3'
              classNamePrefix='multi-select'
              onChange={(e: any) => {
                formik.setFieldValue('status', e.value)
              }}
              value={statusData.find((status: any) => formik.values?.status === status.value)}
            />
            {formik.touched.status && formik.errors.status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.status}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {!userForEdit._id && (
            <>
              {/* begin::Form group Password */}
              <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                  <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                  <div className='position-relative mb-3'>
                    <input
                      type='password'
                      placeholder='Password'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='text-muted'>
                  Use 8 or more characters with a mix of letters, numbers & symbols.
                </div>
              </div>
              {/* end::Form group */}

              {/* begin::Form group Confirm password */}
              <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                <input
                  type='password'
                  placeholder='Password confirmation'
                  autoComplete='off'
                  {...formik.getFieldProps('confirmPassword')}
                  className='form-control form-control-solid mb-3 mb-lg-0'
                />

                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirmPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {/* begin::Input group */}
          {formik.values.role !== 'parent' && formik.values.role !== 'educator' && (
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6'>Role</label>
              {/* end::Label */}
              <Select
                options={rolesData}
                placeholder='Select'
                name='role'
                className='multi-select-container mt-3'
                classNamePrefix='multi-select'
                onChange={(e: any) => {
                  formik.setFieldValue('role', e.label)
                  formik.setFieldValue('roleId', e.value)
                }}
                value={rolesData.find((role: any) => formik.values?.roleId === role.value)}
              />
              {formik.touched.role && formik.errors.role && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.role}</span>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>{userForEdit._id ? 'Update' : 'Create'}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {useAuth} from '../../../../auth'
import Collapsible from '../../../common/Collapsible'
import ChildHeader from '../../../common/ChildFormHeader'
import {AddressDetailInfo} from '../../../common/AddressDetailInfo'
import {Link} from 'react-router-dom'
import UploadFile from '../../../common/UploadFile'

const ChildHealthInfo: FC<any> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <Collapsible title={"Doctor's information"} id='doc-info'>
              <div className='fv-row'>
                <div className='row'>
                  <div className='col-md fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Full name of doctor</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='child.doctor.fullName'
                      placeholder='Full name of doctor'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.doctor.fullName' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='fv-row mb-10'>
                <div className='row'>
                  <div className='col-md-6 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Phone of medical service</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='child.doctor.medicalService.contact'
                      placeholder='Contact phone'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.doctor.medicalService.contact' />
                    </div>
                  </div>
                  <div className='col-md-6 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Name of medical service</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='child.doctor.medicalService.name'
                      placeholder='Name'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.doctor.medicalService.name' />
                    </div>
                  </div>
                </div>
              </div>
              <AddressDetailInfo
                values={values}
                setFieldValue={setFieldValue}
                field='child.doctor.address'
              />
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>Ambulance subscription</span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.ambulanceSubscription'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.ambulanceSubscription'
                      value='no'
                    />
                    No
                  </span>
                </div>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.ambulanceSubscription' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <UploadFile
                  label='Medicare Card'
                  field='child.medicareCard'
                  setFieldValue={setFieldValue}
                  value={values?.child?.medicareCard}
                />
              </div>
            </Collapsible>
            <Collapsible
              title={'Child’s medical information and risk minimisation planning'}
              id='doc-info-plan'
            >
              <h3 className='mb-10'></h3>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Does your child have any specific healthcare needs including any medical
                    conditions?
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.medicalCare.healthCareNeed.status'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.medicalCare.healthCareNeed.status'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.medicalCare.healthCareNeed.status' />
                </div>
              </div>
              {values?.child?.medicalCare?.healthCareNeed?.status === 'yes' && (
                <>
                  <div className='fv-row mb-10'>
                    <div className='row'>
                      <Field
                        className='form-control form-control-solid mb-2'
                        as='textarea'
                        rows={4}
                        placeholder='Please provide details'
                        name='child.medicalCare.healthCareNeed.description'
                      ></Field>
                    </div>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.medicalCare.healthCareNeed.description' />
                    </div>
                  </div>
                  <div className='fv-row mb-10'>
                    <UploadFile
                      field='child.medicalCare.healthCareNeed.file'
                      setFieldValue={setFieldValue}
                      value={values?.child?.medicalCare?.healthCareNeed?.file}
                    />
                  </div>
                </>
              )}
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Does your child have any allergies or sensitivity?
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.medicalCare.allergy.status'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.medicalCare.allergy.status'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.medicalCare.allergy.status' />
                </div>
              </div>
              {values?.child?.medicalCare?.allergy?.status === 'yes' && (
                <>
                  <div className='fv-row mb-10'>
                    <div className='row'>
                      <Field
                        className='form-control form-control-solid mb-2'
                        as='textarea'
                        rows={4}
                        placeholder='Please provide details'
                        name='child.medicalCare.allergy.description'
                      ></Field>
                    </div>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.medicalCare.allergy.description' />
                    </div>
                  </div>
                  <div className='fv-row mb-10'>
                    <UploadFile
                      field='child.medicalCare.allergy.file'
                      setFieldValue={setFieldValue}
                      value={values?.child?.medicalCare?.allergy?.file}
                    />
                  </div>
                </>
              )}
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Has your child been diagnosed as being at risk of anaphylaxis?
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.medicalCare.anaphylaxis.status'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.medicalCare.anaphylaxis.status'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.medicalCare.anaphylaxis.status' />
                </div>
              </div>
              {values?.child?.medicalCare?.anaphylaxis?.status === 'yes' && (
                <>
                  <div className='fv-row mb-10'>
                    <div className='row'>
                      <Field
                        className='form-control form-control-solid mb-2'
                        as='textarea'
                        rows={4}
                        placeholder='Please provide details'
                        name='child.medicalCare.anaphylaxis.description'
                      ></Field>
                    </div>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.medicalCare.anaphylaxis.description' />
                    </div>
                  </div>
                  <div className='fv-row mb-10'>
                    <UploadFile
                      field='child.medicalCare.anaphylaxis.file'
                      setFieldValue={setFieldValue}
                      value={values?.child?.medicalCare?.anaphylaxis?.file}
                    />
                  </div>
                </>
              )}
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>Is your child enrolled with NDIS?</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='National disability insurance scheme'
                  ></i>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.medicalCare.NDISEnrolled.status'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='child.medicalCare.NDISEnrolled.status'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.medicalCare.NDISEnrolled.status' />
                </div>
              </div>
              {values?.child?.medicalCare?.NDISEnrolled?.status === 'yes' && (
                <>
                  <div className='fv-row mb-10'>
                    <div className='row'>
                      <Field
                        className='form-control form-control-solid mb-2'
                        as='textarea'
                        rows={4}
                        placeholder='Please provide details'
                        name='child.medicalCare.NDISEnrolled.description'
                      ></Field>
                    </div>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.medicalCare.NDISEnrolled.description' />
                    </div>
                  </div>
                  <div className='fv-row mb-10'>
                    <UploadFile
                      field='child.medicalCare.NDISEnrolled.file'
                      setFieldValue={setFieldValue}
                      value={values?.child?.medicalCare?.NDISEnrolled?.file}
                    />
                  </div>
                </>
              )}
            </Collapsible>

            <Collapsible title='Anaphylaxis' id='anaphylaxis'>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Does your child have any allergies or sensitivity?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.allergyOrSensitivity.status'
                        value='yes'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.allergyOrSensitivity.status'
                        value='no'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.allergyOrSensitivity.status' />
                </div>
              </div>
              {values?.child?.allergyOrSensitivity?.status === 'yes' && (
                <>
                  <div className='fv-row mb-10'>
                    <span className='d-flex justify-content-end fs-5  mb-2 row'>
                      <span className='col-10'>
                        Please download the form to provide an action plan for Anaphylaxis
                      </span>
                      <div className='col-2 align-items-end'>
                        <Link
                          className='btn btn-sm btn-primary float-end'
                          to='/ASCIA_Action_Plan_Anaphylaxis_Red_Anapen_2022.pdf'
                          target='_blank'
                        >
                          Download
                        </Link>
                      </div>
                    </span>
                  </div>
                  <div className='fv-row mb-10'>
                    <UploadFile
                      field='child.allergyOrSensitivity.file'
                      setFieldValue={setFieldValue}
                      value={values?.child?.allergyOrSensitivity.file}
                    />
                  </div>
                </>
              )}
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Does your child have an auto-injection device (eg EpiPen)?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field type='radio' className='me-2 ' name='child.hasEpiPen' value='yes' />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field type='radio' className='me-2' name='child.hasEpiPen' value='no' />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.hasEpiPen' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Has the anaphylaxis medical management plan been provided to the service?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.anaphylaxisManagement'
                        value='yes'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.anaphylaxisManagement'
                        value='no'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.anaphylaxisManagement' />
                </div>
              </div>

              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Is there a completed and current risk management plan?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.riskManagement'
                        value='yes'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field type='radio' className='me-2' name='child.riskManagement' value='no' />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.riskManagement' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='col-10'>
                    First Aid Plan for Anaphylaxis{' '}
                    <a href='/ASCIA_First_Aid_Anaphylaxis_Orange_Anapen_2022.pdf' target='_blank'>
                      ASCIA_Firdt_Aid_Anaphylaxis_Orange_Anapen_2022
                    </a>{' '}
                  </span>
                </span>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='col-10'>
                    How to give (adrenaline) injectors{' '}
                    <a href='/ASCIA_How_to_give_adrenaline_injectors_2022.pdf' target='_blank'>
                      ASCIA_How_to_give_adrenaline_injectors_2022
                    </a>{' '}
                  </span>
                </span>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='col-10'>
                    Note that more information is available at{' '}
                    <a href='https://www.education.vic.gov.au/anaphylaxis' target='_blank'>
                      www.education.vic.gov.au/anaphylaxis
                    </a>{' '}
                  </span>
                </span>
              </div>
            </Collapsible>

            <Collapsible title='Asthma' id='asthma'>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Has your child been diagnosed with asthma?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.diagnosedAsthma.status'
                        value='yes'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.diagnosedAsthma.status'
                        value='no'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.diagnosedAsthma.status' />
                </div>
              </div>
              {values?.child?.diagnosedAsthma?.status === 'yes' && (
                <>
                  <div className='fv-row mb-10'>
                    <span className='d-flex justify-content-end fs-5  mb-2 row'>
                      <span className='col-10'>
                        Please download the form to provide an action plan for Asthma
                      </span>
                      <div className='col-2 align-items-end'>
                        <Link
                          className='btn btn-sm btn-primary float-end'
                          to='/AA2022_Asthma-Action-Plan-A4_v15_colour_editable.pdf'
                          target='_blank'
                        >
                          Download
                        </Link>
                      </div>
                    </span>
                  </div>
                  <div className='fv-row mb-10'>
                    <UploadFile
                      field='child.diagnosedAsthma.file'
                      setFieldValue={setFieldValue}
                      value={values?.child?.diagnosedAsthma?.file}
                    />
                  </div>
                </>
              )}
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Does your child have any devices to help provide medication?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.medicationDevice'
                        value='yes'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.medicationDevice'
                        value='no'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.medicationDevice' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Has the asthma medical management plan been provided to the service?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.asthmaManagement'
                        value='yes'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.asthmaManagement'
                        value='no'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.asthmaManagement' />
                </div>
              </div>

              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Is there a completed and current risk management?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.asthmaRiskManagement'
                        value='yes'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.asthmaRiskManagement'
                        value='no'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.asthmaRiskManagement' />
                </div>
              </div>
            </Collapsible>
            <Collapsible title='Checklist' id='checklist'>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row justify-content-between'>
                  <span className='required col-10'>
                    Parent has been told about{' '}
                    <a href='https://www.education.vic.gov.au/anaphylaxis' target='_blank'>
                      www.education.vic.gov.au/anaphylaxis
                    </a>{' '}
                  </span>
                  <div className='row col-2 d-flex align-items-end'>
                    <Field type='checkbox' name='child.anaphylaxisAwareness' />
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.anaphylaxisAwareness' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row justify-content-between'>
                  <span className='required col-10'>
                    You have been given policy on anaphylaxis and asthma
                  </span>
                  <span className='row col-2 d-flex align-items-end'>
                    <Field type='checkbox' name='child.policyOfAnaphylaxis' />
                  </span>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.policyOfAnaphylaxis' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row justify-content-between'>
                  <span className='required col-10'>
                    You have given us your child’s medical management plan signed by the medical
                    practitioner who is treating your child
                  </span>
                  <div className='row col-2 d-flex align-items-end'>
                    <Field type='checkbox' name='child.hasChildMedicalManagement' />
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.hasChildMedicalManagement' />
                </div>
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChildHealthInfo}

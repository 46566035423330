import React from 'react'
import {useListView} from '../../core/ListViewProvider'
import {EducatorsListFilter} from './EducatorsListFilter'

const EducatorsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <EducatorsListFilter />
    </div>
  )
}

export {EducatorsListToolbar}

import {ErrorMessage, Field} from 'formik'
import React, {FC} from 'react'
import {AddressDetailInfo} from '../../../common/AddressDetailInfo'
import {SignatureField} from '../../../common/SignatureField'

interface ParentEducatorAgreementProps {
  values: any
  setFieldValue: any
}

const EducatorAgreementForm: FC<ParentEducatorAgreementProps> = ({values, setFieldValue}) => {
  return (
    <div className='w-100 '>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Educator’s Agreement</h2>
      </div>
      <div className='w-100 ol-list-container d-flex mb-5'>
        <ol className='list-group list-group-numbered w-100'>
          <li className='list-group-item'>
            <b> Introduction</b>
            <ul>
              <li>
                ME 2 FAMILY DAY CARE services are administered in accordance with the conditions of
                commonwealth and state legislations, policies, procedures and standards.
              </li>
              <li>
                This service is empowered to grant registration to the educators of this agreement,
                to undertake the care of children registered with ME 2 FAMILY DAY CARE.
              </li>
              <li>
                This agreement acknowledges that the educator wishes to be registered with ME 2
                FAMILY DAY CARE.
              </li>
              <li>
                This agreement may from time to time be changed. Should this occur, the educator
                will be provided with details of the agreement in writing.
              </li>
              <li>
                Educator is an <b>independent contractor</b> working on <b>personal ABN</b> and is
                responsible to have all necessary <b>insurances</b> related to his/her work
                industry.
              </li>
              <li>
                Educator is responsible and liable to have all necessary work-related items at
                home-based care.
              </li>
              <li>
                Educator is fully responsible for their own taxes / superannuation commitments on
                income earned as a contractor with ME 2 FAMILY DAY CARE.
              </li>
            </ul>
          </li>
          <li className='list-group-item'>
            <b> Agreement Details</b>
            <ul>
              <li>
                Subject to the other provisions of this agreement, the terms of this agreement will
                commence on the commencement date of education and care for the children registered
                with ME 2 FAMILY DAY CARE and will terminate 1 year (52 weeks) from the date of
                commencement, unless earlier terminated in accordance with this agreement.
              </li>
              <li>
                ME 2 FAMILY DAY CARE may extend the terms of this agreement beyond the initial term,
                for a period that is not less than 4 weeks or not more than 12 weeks from the end of
                this initial term.
              </li>
              <li>
                The educator care will only commence after the annual registration fee and the
                educator will pays for all their trainings
              </li>
              <li>
                The educator should be available to provide education and care to the children
                registered with ME 2 FAMILY DAY CARE for 48 weeks out of the 52 weeks from the date
                of commencement.
              </li>
              <li>
                The educator must be available to educate and care for the children registered with
                ME 2 FAMILY DAY CARE as per the contract hours agreed with the parents of children
                under their care. Exception to this rule will be made under some circumstances.
              </li>
            </ul>
          </li>
          <li className='list-group-item'>
            <b>Obligation to pay fees and costs associated with this agreement</b>
            <ul>
              <li>The educator is responsible to pay for their own Work Cover.</li>
              <li>
                The educator must pay an annual registration fee and the educator pays for all their
                training
              </li>
              <li>
                The educator is responsible for any costs associated with the provision of education
                and care within their homes.
              </li>
              <li>
                The educator is required to pay the fee for{' '}
                <b>
                  Public Liability Insurance Cover, Work Cover, First Aid certificate and the
                  Working with Children Check.
                </b>
                <ul>
                  <li>
                    Please note all the above must be maintained and kept current from year to year.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className='list-group-item'>
            <b>Obligations on the Educator </b>
            <ul>
              <li>
                The <b>educator is required</b> to{' '}
                <b>provide the co-ordinator and the families of the children within their care</b>{' '}
                with <b>at least 4 weeks’ notice,</b> should they be unable to provide education and
                care for any period pursuant to this agreement for any reason
              </li>
              <li>
                The educator is required to provide the co-ordinator and the families of the
                children within their care with at <b>least 2 weeks’ notice</b> should they{' '}
                <b>intend to terminate this agreement.</b>
              </li>

              <li>
                Should <b>the educator</b> be unable to provide education and care due to impending{' '}
                <b>childbirth,</b> the educator is required to <b>provide notice</b> of at least{' '}
                <b>4 weeks.</b>
                <ul>
                  <li>
                    The <b>educator will not be allowed</b> to provide care after <b>6 weeks</b>{' '}
                    from birth with <b>medical certificate</b> from Medical practitioner.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className='list-group-item'>
            <b>Educator’s Responsibilities </b>
            <ul>
              <li>
                The <b>educator must</b> ensure that the children registered with ME 2 FAMILY DAY
                CARE <b>are always adequately and actively supervised whilst in care.</b>
              </li>
              <li>
                The <b>educator accepts to strictly comply</b> with the conditions set out in this
                agreement and the ME 2 FAMILY DAY CARE is a condition of registration under this
                agreement.
              </li>

              <li>
                <b>Educator must not</b> educate and care for <b>more than seven children</b> at a
                family day care residence or approved family day care venue at any one time.
                <br />
                This includes
                <ul>
                  <li>No more than four children preschool age or under</li>
                  <li>
                    The family day care educator's own children and any other children at the
                    residence, if those children are under 13 years of age and there is no other
                    adult present and caring for the children (regulation 124).
                  </li>
                </ul>
              </li>
              <li>
                The educator must maintain proper records of all aspects relating to the education
                and care of children registered with ME 2 FAMILY DAY CARE, including but not limited
                to attendance and absence records, family detail records, observation and planning
                records, medication, accident and illness records and excursion permission forms.
              </li>
              <li>
                The educator is responsible for informing the co-ordinator if any aspect of the
                information provided on “Registration Form” changes during the term of this
                agreement.
              </li>
              <li>
                The educator must notify the co-ordinator if there are any significant changes to
                the educator’s home such as renovation, change of address or any person turned 18
                year
              </li>
              <li>
                The educator must always provide education and care within the authorised premises.
              </li>
              <li>
                Any actions of visitors to the educator’s home while the children registered with ME
                2 FAMILY DAY CARE are in care, under the terms and conditions of this agreement will
                be deemed to be the responsibility of the educator.
                <ul>
                  <li>
                    This means that if any aspect of this agreement is breached by visitors to the
                    educator’s home whilst the children registered with ME 2 FAMILY DAY CARE are in
                    care, the educator will be considered as having breached the agreement.
                  </li>
                </ul>
              </li>

              <li>
                The educator is responsible for maintaining their home environment in accordance
                with ME 2 FAMILY DAY CARE requirements and the Australian standards.
              </li>
              <li>
                The educator is responsible for the safety and the well-being of all children in
                care.
              </li>

              <li>
                If the educator intends to transport children who are registered with ME 2 FAMILY
                DAY CARE, the educator must have a current Victorian Driver’s License.
                <ul>
                  <li>The vehicle must have appropriate child restraints fitted and maintained.</li>
                </ul>
              </li>
              <li>The educator must attend all meetings during the term of this agreement.</li>
              <li>
                The educator will be required to attend all training sessions during the term of
                this agreement.
              </li>
            </ul>
          </li>

          <li className='list-group-item'>
            <b>ME 2FAMILY DAY CARE Responsibilities </b>
            <ul>
              <li>
                <b>Administration</b>
                <ul>
                  <li>
                    ME 2 FAMILY DAY CARE undertakes to efficiently administer the Child Care Subsidy
                    (CCS) received from the Commonwealth and maintain proper and accurate records of
                    the Child Care Subsidy component.
                  </li>
                  <li>Submission of session report </li>
                  <li>Marketing support </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className='list-group-item'>
            <b>Procedure for placing children into ME 2 FAMILY DAY CARE </b>
            <ul>
              <li>
                ME 2 FAMILY DAY CARE will take due care to recommend potential placements to the
                educator in a non-discriminatory manner.
              </li>
              <li>
                ME 2 FAMILY DAY CARE will provide access to qualified and experienced early
                childhood staff to offer on-going support, training and other resources to the
                educator. They will also assist the educators to comply with their obligations
              </li>

              <li>
                ME 2 FAMILY DAY CARE will ensure that placement and care of children are monitored
                in an appropriate manner by telephoning, writing, communicating in person to the
                educator and undertaking a monthly visit and in some cases every fortnight visits to
                the educator’s home.
              </li>
              <li>
                ME 2 FAMILY DAY CARE will undertake to provide any information relating to
                training..
              </li>
            </ul>
          </li>
          <li className='list-group-item'>
            <b>Availability </b>
            <ul>
              <li>
                The educator must be available to provide education and care for hours as per the
                contract with the parents.
              </li>
              <li>
                Educator should be contactable and reachable (via phone & email) during committed
                working hours.
              </li>
            </ul>
          </li>
          <li className='list-group-item'>
            <b>Suspension or termination of this agreement by ME 2 FAMILY DAY CARE </b>
            <ul>
              <li>
                If the Educator breaches any of the obligations pursuant to the Agreement, ME 2
                FAMILY DAY CARE will consider the seriousness of this breach and appropriate action
                will be taken to ensure that the breach is remedied. This may include, initial
                consultation, suspension in the most serious cases, or termination of the Agreement.
              </li>
              <li>
                Any period of suspension initiated by ME 2 FAMILY DAY CARE will require a successful
                health and safety assessment before children will be placed back into the educator’s
                care.
              </li>
              <li>
                Any period of suspension initiated by ME 2 FAMILY DAY CARE will result in weekly
                home monitoring visits until satisfied that the Educator is meeting all terms and
                conditions of this Agreement
              </li>
              <li>
                Automatic Suspension of this Agreement will take place where the Educator or any of
                the household is subject to investigation or prosecution by any State or Federal
                Authority in matters relating to offences against Children and / or minors.
              </li>
              <li>
                Suspension under these circumstances will be until the court or relevant Authority
                deals with the matter/s, so that no further risk exists to the safety or wellbeing
                of children in Care or to their families or other users of the Service.
              </li>
              <li>
                Any Verbal, physical or emotional abuse or endangering any Child in Care or Child’s
                Family, or staff members of the scheme will result to immediate termination of the
                Agreement.
              </li>
              <li>
                Immediate termination of the Agreement will occur if the Educator breaches any of
                the State or Federal Laws relating to Health and Safety.
              </li>
              <li>
                Immediate termination of the Agreement will occur if the Educator is under the
                influence of drugs or alcohol while children registered with ME 2 FAMILY DAY CARE
                are in Care.{' '}
              </li>
              <li>
                Immediate termination of the Agreement will occur if the Educator leaves children
                unsupervised at anytime or in the Care of an unauthorised person.
              </li>
              <li>
                Immediate termination of the Agreement will occur in case of failure by the Educator
                to take out and / or keep current the Public Liability and Legal Plan insurance
                Cover
              </li>
              <li>
                Immediate termination of the Agreement will occur if the educator undertakes the
                education and care of any children not registered with ME 2 FAMILY DAY CARE under
                the terms and conditions outlined in this agreement.
              </li>
            </ul>
          </li>
          <li className='list-group-item'>
            <b>Fraudulence act</b>
            <br />
            Me 2 Family Day Care Ensures that no fraud is committed in obtaining the COMMONWEALTH
            Subsidy. The below aspects are fraud:
            <ul>
              <li>
                If an educator is working and her own children are going to another educator care.
              </li>
              <li>
                If the children are not attending care sessions with educator and educator is
                submitting care sessions as care provided in those sessions.
              </li>
              <li>
                If the children are overseas or left for overseas and educator is submitting
                sessions of care while the child is in overseas.
              </li>
              <li>The Educator using parent pin to submit session of care.</li>
            </ul>
          </li>
          <li className='list-group-item'>
            <b>Monitoring and compliance:</b>
            <ul>
              <li>
                Our risk-based approach seeks to identify risks with the greatest potential impact.
                These risks include any behaviour or action that could lead to harm of a child or
                failure of an educator to properly discharge their responsibilities as described by
                the National Regulations or Law.
              </li>
              <li>
                All educators have access to Educator portal where they electronically sign in/out
                children on each session and submit verified time sheets to demonstrate compliance
                with M2 family Day Care, the National regulation and Law. Me2 Family Day care is
                seeking to provide educators, (as acting independent contractors), with useful tools
                and support to manage their own business within the prescribed Laws and Regulations.
              </li>
              <li>
                Where an educator fails to provide evidence of compliance within the required
                timeframe (5 working days) they will receive an notice of contract suspension. This
                allows the educator to take the time they require to meet compliance without posing
                additional risk to Me2 family day Care or educators registered with Me2 Family day
                Care. Educators can work to have a suspension lifted but will not be able to provide
                sessions of care during contract suspension.
              </li>
              <li>
                Service is hyper Vigilant and will not tolerate any condition where children who
                were also reported as being provided with care at another child services at the same
                time.
              </li>
              <li>
                Service will closely monitor Educator/Children movement of overseas travelling where
                an educator is responsible to inform service one week before travel of
                educators/children with an evidence of travel document/travel tickets, failure to do
                so will have immediate termination of contract.
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div className='fv-row mb-10'>
        <span className='d-flex align-items-center fs-5  mb-2 row'>
          <div>
            <Field type='checkbox' name='agreedOnPolicy' />
            <span className='required col-10 ms-2'>
              I have read and understood the Agreement and will abide by the conditions in this
              Agreement.
            </span>
          </div>
        </span>

        <div className='text-danger mt-2'>
          <ErrorMessage name='agreedOnPolicy' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <h4>Rate that the Educator will be paid:</h4>
        <p className='fw-bold'>
          Educator’s payment will take place within 10 working days after submitting the timesheet{' '}
        </p>
        <h4>Other fees</h4>
        <p>Annual Registration Fee: $200</p>
        <p>Software licensing fee – 9.4/Fortnight</p>
      </div>
      <fieldset disabled>
        <div className='fv-row mb-10'>
          <h4 className='mb-5'>Registration and details</h4>
          <div className='row'>
            <div className='col-md-12 fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>Educators Name:</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='basicInformation.fullName'
                placeholder='Educators Name'
              />
            </div>
          </div>
          <AddressDetailInfo
            values={values}
            setFieldValue={setFieldValue}
            field={'basicInformation.dayCareAddress'}
            mandatory={false}
          />
          <div className='row'>
            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>Contact Person of ME 2 Family Day Care:</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='me2FamilyDayCareContactPerson'
                placeholder='Contact Person of ME 2 Family Day Care'
              />
            </div>
            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>Address of the Service:</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='child.doctor.fullName'
                value='Suite2, level 1/67 Sydney rd, Coburg
              3058'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='child.doctor.fullName' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>Commencement Date of the Agreement:</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='child.doctor.fullName'
                placeholder='Commencement Date of the Agreement'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='child.doctor.fullName' />
              </div>
            </div>
            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>End Date of the Agreement:</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='child.doctor.fullName'
                placeholder='End Date of the Agreement'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='child.doctor.fullName' />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <div className='fv-row mb-10'>
        <h4 className='mb-5'>Educator’s Bank Details</h4>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Name of the Account Holder</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='bankDetails.accountHolderName'
              placeholder='Name of the Account Holder'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='bankDetails.accountHolderName' />
            </div>
          </div>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Name of the Bank</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='bankDetails.bankName'
              placeholder='Name of the Bank'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='bankDetails.bankName' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Branch</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='bankDetails.branchName'
              placeholder='Branch'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='bankDetails.branchName' />
            </div>
          </div>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>BSB Number</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='bankDetails.BSBNumber'
              placeholder='BSB Number'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='bankDetails.BSBNumber' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Account Number</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='bankDetails.accountNumber'
              placeholder='Account Number'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='bankDetails.accountNumber' />
            </div>
          </div>
        </div>
      </div>
      <p>
        I have read and understood the Agreement and will abide by the conditions in this Agreement.
      </p>

      <div className='row'>
        <div className='col-6'>
          <SignatureField
            title='Signed by ME 2 FAMILY DAY CARE:'
            field='me2Signature'
            value={values?.me2Signature}
            setFieldValue={setFieldValue}
            disabled={true}
          />
        </div>
        <div className='col-6'>
          <SignatureField
            title='Signed by Educator:'
            field='signature'
            value={values?.signature}
            setFieldValue={setFieldValue}
            disabled={true}
          />
        </div>
      </div>
    </div>
  )
}
export {EducatorAgreementForm}

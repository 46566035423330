import React, {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

const Collapsible = ({id, title, arrayHelpers, values, index, children}: any) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleCollapse = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='collapsible mb-10'>
      <h3
        className='collapsed cursor-pointer'
        data-bs-toggle='collapse'
        data-bs-target={`#collapse-${id}`}
        aria-expanded={isOpen}
        aria-controls={`collapse-${id}`}
        onClick={toggleCollapse}
      >
        {title}
        <i
          className={
            isOpen
              ? 'bi bi-caret-up-fill border border-2 ms-1 p-1 rounded-circle'
              : 'bi bi-caret-down-fill border border-2 ms-1 p-1 rounded-circle'
          }
        ></i>
        {arrayHelpers && (
          <>
            <button
              type='button'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3 ms-2'
              onClick={() => arrayHelpers.remove(index)}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Remove Item'
              disabled={values?.length > 1 ? false : true}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen027.svg'
                className='svg-icon svg-icon-3'
              />
            </button>
            {values && values.length === index + 1 && (
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Add new nominee'
                onClick={() => {
                  arrayHelpers.push({
                    fullName: '',
                    relationWithChild: '',
                    address: {
                      streetAddress: [''],
                      suburb: '',
                      postcode: '',
                      state: '',
                      country: 'Australia',
                    },
                    homeContact: '',
                    mobileContact: '',
                    email: '',
                    otherContact: '',
                  })
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr013.svg'
                  className='svg-icon  svg-icon-3'
                />
              </button>
            )}
          </>
        )}
      </h3>
      <div className='collapse show' id={`collapse-${id}`} aria-labelledby={`heading-${title}`}>
        <div className='card card-body'>{children}</div>
      </div>
    </div>
  )
}

export default Collapsible

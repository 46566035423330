import {FC} from 'react'
import {ErrorMessage, Field} from 'formik'

interface Props {
  value: any
  setFieldValue: any
  field: string
}
export const Addresses: FC<Props> = ({value, setFieldValue, field}) => {
  return (
    <>
      {/* <Collapsible title={`Departure Address`} id={`risk-management-Departure`}>
        <AddressDetailInfo value={value?.departureAddress} field={`${field}.departureAddress`} />
      </Collapsible>

      
      <Collapsible title={`Destination Address`} id={`risk-management-Destination`}>
        <AddressDetailInfo
          value={value?.destinationAddress}
          field={`${field}.destinationAddress`}
        />
      </Collapsible> */}

      <div className='row fv-row  mb-10'>
        <label className='d-flex fs-5 fw-bold mb-2'>
          <span className='required'>Departure Address</span>
        </label>

        <Field
          className='form-control form-control-solid mb-2'
          as='textarea'
          rows={4}
          placeholder='Departure address'
          name={`${field}.departureAddress`}
        ></Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name={`${field}.departureAddress`} />
        </div>
      </div>

      <div className='row fv-row  mb-10'>
        <label className='d-flex fs-5 fw-bold mb-2'>
          <span className='required'>Destination Address</span>
        </label>

        <Field
          className='form-control form-control-solid mb-2'
          as='textarea'
          rows={4}
          placeholder='Destination address'
          name={`${field}.destinationAddress`}
        ></Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name={`${field}.destinationAddress`} />
        </div>
      </div>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {FC} from 'react'

type Props = {
  educator: any
}

const EducatorAddress: FC<Props> = ({educator}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      {educator?.child &&
        `${educator?.child?.address?.streetAddress}, ${
          educator?.child?.address?.suburb || educator?.child?.address?.city
        }, ${educator?.child?.address?.country}`}
    </div>
  </div>
)

export {EducatorAddress}

import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import EducatorsPage from '../modules/apps/educator-management/EducatorsPage'
import ProfilePage from '../modules/profile/ProfilePage'
import AccountPage from '../modules/accounts/AccountPage'
import UsersPage from '../modules/apps/user-management/UsersPage'
import RolesPage from '../modules/apps/user-management/RolesPage'
import ChildrenPage from '../modules/apps/children-management/ChildrenPage'
import {useAuth} from '../modules/auth'
import SettingsPage from '../modules/apps/settings/SettingsPage'
// import SettingsPage from '../modules/apps/xsettings/SettingsPage'

const PrivateRoutes = () => {
  const {currentUser, logout} = useAuth()
  useEffect(() => {
    if (currentUser?.role?.toLowerCase() !== 'admin') {
      logout()
    }
  }, [])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/educator-management/*'
          element={
            <SuspensedView>
              <EducatorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/child-management/*'
          element={
            <SuspensedView>
              <ChildrenPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

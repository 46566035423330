/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
// import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import * as Yup from 'yup'
// import {updateEducatorStatus} from '../core/_request'
import {Link, useParams} from 'react-router-dom'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ChildProfileForm} from './ChildProfileForm'
import {getEducators, updateChildAssignee, updateChildStatus} from '../core/_request'
import Select from 'react-select'
import {useFormik} from 'formik'
// import bootstrap from 'bootstrap'
import {pdfjs, Document, Page} from 'react-pdf'
import {Modal} from 'react-bootstrap'

const educatorAssigneeSchema = Yup.object({
  educator: Yup.string().required('Please select an assignee'),
})
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const ChildEdit = () => {
  const {id} = useParams()
  const queryClient = useQueryClient()
  const [childData, setChildData]: any = useState(null)
  const [hide, setHide] = useState(false)
  // const updateStatus: any = useMutation(updateEducatorStatus)
  const updateStatus: any = useMutation(updateChildStatus, {
    onSuccess: () => {
      // Refetch the user list after the mutation has completed
      queryClient.refetchQueries(['childData', id])
    },
  })
  const [tab, setTab] = useState('Profile')
  const [educators, setEducators] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalDescription, setModalDescription] = useState('')
  const [status, setStatus] = useState('')
  const showHideModal = () => setShowModal(!showModal)

  const childStatusUpdate = () => {
    updateStatus.mutate({id, status})
    setHide(true)
    showHideModal()
  }

  function onDocumentLoadSuccess({numPages}: any) {}

  const formik = useFormik({
    initialValues: {educator: ''},
    validationSchema: educatorAssigneeSchema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      // setSubmitting(true)
      try {
        await updateChildAssignee(id as string, values)
        document.getElementById('closeButton')?.click()
      } catch (ex: any) {
        setStatus(ex?.response?.data.message)
      } finally {
        setSubmitting(true)
      }
    },
  })

  async function getApprovedEducators() {
    const response: any = await getEducators()
    setEducators(
      response?.map((educator: any) => {
        return {label: educator?.basicInformation?.fullName, value: educator._id}
      })
    )
  }

  useEffect(() => {
    getApprovedEducators()
  }, [])

  return (
    <>
      <Modal show={showModal} onHide={showHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalDescription}</Modal.Body>
        <Modal.Footer>
          <button className=' btn btn-primary' onClick={childStatusUpdate}>
            Yes
          </button>
          <button className=' btn btn-secondary' onClick={showHideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <div className='row d-flex justify-content-center align-items-center h-100'>
        <div className='card card-custom gutter-b'>
          <div className='card-body'>
            <div className='d-flex text-black'>
              <div className='flex-shrink-0'>
                <div className='img-fluid'>
                  <img
                    src={toAbsoluteUrl('/media/avatars/blank.png')}
                    alt=''
                    style={{width: '100px', borderRadius: '10px'}}
                  />
                </div>
              </div>
              <div className='flex-grow-1 ms-3'>
                <h5 className='mb-1'>{childData?.child?.fullName}</h5>
                <p className='mb-1 pb-1 '>
                  <a className='text-decoration-underline' href={`mailto:${childData?.email}`}>
                    {childData?.email}
                  </a>
                </p>
              </div>
              {childData?.status === 'Pending' && (
                <div className={`${hide ? 'd-none' : ''}`}>
                  <button
                    className='btn btn-danger float-end'
                    onClick={() => {
                      setStatus('Rejected')
                      setModalDescription('Do you want to reject this child?')
                      setShowModal(true)
                    }}
                  >
                    Reject
                  </button>
                  <button
                    className='btn btn-success me-4 float-end'
                    onClick={() => {
                      setStatus('Approved')
                      setModalDescription('Do you want to approve this child?')
                      setShowModal(true)
                    }}
                  >
                    Approve
                  </button>
                </div>
              )}

              {childData?.status === 'Approved' && (
                <div className={`${hide ? 'd-none' : ''}`}>
                  <button
                    className='btn btn-danger float-end'
                    onClick={() => {
                      setStatus('Ceased')
                      setModalDescription('Are you sure you want to cease the care for this child?')
                      setShowModal(true)
                    }}
                  >
                    Cease Child Care
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='card-header card-header-stretch overflow-auto'>
        <ul
          className='nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          '
          role='tablist'
        >
          <li className='nav-item'>
            <a
              className={clsx(`nav-link cursor-pointer`, {active: tab === 'Profile'})}
              onClick={() => setTab('Profile')}
              role='tab'
            >
              Profile
            </a>
          </li>
          <li className={`nav-item ${childData?.status !== 'Approved' ? 'd-none' : ''}`}>
            <a
              className={clsx(`nav-link cursor-pointer`, {active: tab === 'Educator'})}
              onClick={() => setTab('Educator')}
              role='tab'
            >
              Educator
            </a>
          </li>
        </ul>
      </div>
      {tab === 'Profile' && <ChildProfileForm updateData={setChildData} />}
      {tab === 'Educator' && (
        <div className='card card-custom gutter-b'>
          <div className='card-body'>
            <div className='d-flex justify-content-between'>
              <ul
                className='nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          '
                role='tablist'
              >
                <li className='nav-item'>
                  <a className='nav-link cursor-pointer active' role='tab'>
                    Educator details
                  </a>
                </li>
              </ul>
              <button
                type='button'
                className='btn btn-sm btn-primary '
                data-bs-toggle='modal'
                data-bs-target='#exampleModal'
              >
                Assign Educator
              </button>
            </div>
            <div
              className='modal fade'
              id='exampleModal'
              tabIndex={-1}
              aria-labelledby='exampleModalLabel'
              aria-hidden='true'
            >
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                      Assign Educator
                    </h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      id='closeButton'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <form
                      id='kt_modal_add_user_form'
                      className='form'
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      <div className='mb-7'>
                        {/* begin::Label */}
                        <label className='fs-6 mb-5'>Select an educator to assign</label>
                        {/* end::Label */}
                        <Select
                          options={educators}
                          placeholder='Select'
                          name='educator'
                          className='multi-select-container'
                          classNamePrefix='multi-select'
                          onChange={(e: any) => {
                            formik.setFieldValue('educator', e.value)
                          }}
                          value={educators.find(
                            (educator: any) => formik.values?.educator === educator._id
                          )}
                        />
                        {formik.touched.educator && formik.errors.educator && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.educator}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Close
                    </button>
                    <button type='button' className='btn btn-primary' onClick={formik.submitForm}>
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {childData?.educator && (
              <>
                <div className='row d-flex justify-content-center align-items-center h-100 border border-1 mt-2'>
                  <div className='card card-custom gutter-b'>
                    <div className='card-body'>
                      <div className='d-flex text-black'>
                        <div className='flex-shrink-0'>
                          <div className='img-fluid'>
                            <img
                              src={toAbsoluteUrl('/media/avatars/blank.png')}
                              alt=''
                              style={{width: '100px', borderRadius: '10px'}}
                            />
                          </div>
                        </div>
                        <div className='flex-grow-1 ms-3'>
                          <h5 className='mb-1'>
                            {childData?.educator?.basicInformation?.fullName}
                          </h5>
                          <p className='mb-1 pb-1 '>
                            <a
                              className='text-decoration-underline'
                              href={`mailto:${childData?.educator?.basicInformation?.email}`}
                            >
                              {childData?.educator?.basicInformation?.email}
                            </a>
                          </p>
                          <p className='mb-2 pb-1'>
                            {childData?.educator?.basicInformation?.contactPhone?.[0]}
                          </p>
                        </div>
                        <div>
                          {' '}
                          <Link
                            className='btn btn-sm btn-primary'
                            to={`/apps/educator-management/educators/${childData?.educator?._id}/view`}
                          >
                            View Educator Profile
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul
                  className='nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          '
                  role='tablist'
                >
                  <li className='nav-item'>
                    <a className='nav-link cursor-pointer active' role='tab'>
                      Educator fee schedule
                    </a>
                  </li>
                </ul>

                <div className='all-page-container d-flex justify-content-center'>
                  <Document
                    file='/EducatoStatutoryDeclaration.pdf'
                    onLoadError={console.error}
                    onLoadSuccess={onDocumentLoadSuccess}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

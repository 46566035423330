import {FC} from 'react'
import {Addresses} from '../../../educator-management/educator-view/risk-assessment-partials/Addresses'
import {Children} from '../../../educator-management/educator-view/risk-assessment-partials/Children'
import {Educator} from '../../../educator-management/educator-view/risk-assessment-partials/Educator'
import {RiskAssessmentMatrix} from '../../../educator-management/educator-view/risk-assessment-partials/RiskAssessmentMatrix'
import {SafetyMeasures} from '../../../educator-management/educator-view/risk-assessment-partials/SafetyMeasures'
import {Timings} from '../../../educator-management/educator-view/risk-assessment-partials/Timings'
import {Transportation} from '../../../educator-management/educator-view/risk-assessment-partials/Transportation'
import {VehicleDetails} from '../../../educator-management/educator-view/risk-assessment-partials/VehicleDetails'

interface ParentEducatorAgreementProps {
  values: any
  setFieldValue: any
  field: string
  title: string
}

const RiskAssessment: FC<ParentEducatorAgreementProps> = ({
  values,
  setFieldValue,
  field,
  title,
}) => {
  return (
    <div className='w-100 '>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Excursion risk management plan - {title}</h2>
      </div>

      <div className='row pb-5'>
        <div className='col-12'>
          Family day care and routine outings--- Family Day Care educators frequently take children
          out of the family day care residence or family day care venue. Instances such as taking
          children to school, shopping, going to playgroup, to the park and other day-to-day outings
          are considered routine outings. Routine Excursions are the important part of the early
          childhood, excursions invite children interact with the world around them. Being out and
          about in their community helps children to learn about their immediate environment.
        </div>
      </div>
      <Educator setFieldValue={setFieldValue} field={field} value={values[field]} />
      <VehicleDetails setFieldValue={setFieldValue} field={field} value={values[field]} />
      <Addresses setFieldValue={setFieldValue} field={field} value={values[field]} />
      <Timings setFieldValue={setFieldValue} field={field} value={values[field]} />
      <Transportation setFieldValue={setFieldValue} field={field} value={values[field]} />
      <Children setFieldValue={setFieldValue} field={field} value={values[field]} title={title} />
      <SafetyMeasures setFieldValue={setFieldValue} field={field} value={values[field]} />
      <RiskAssessmentMatrix
        setFieldValue={setFieldValue}
        field={`${field}.riskAssessment`}
        value={values[field]?.riskAssessment}
      />
    </div>
  )
}

export {RiskAssessment}

import {ErrorMessage, Field, useField} from 'formik'
import React, {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import UploadFile from '../../common/UploadFile'
import DatePicker from '../../common/DatePicker'
interface Props {
  values: any
  arrayHelpers: any
  field: string
  setFieldValue: any
}

export const SecurityFields: FC<Props> = ({values, arrayHelpers, field, setFieldValue}) => {
  return (
    <>
      {values?.length > 0 &&
        values?.map((value: any, index: any) => (
          <table className='table table-responsive ' key={index}>
            <tbody>
              <tr>
                <td data-label='Name'>
                  <div className=' w-100'>
                    <div className='row'>
                      <div className='col-md-10'>
                        <div className='row'>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>Full Name</span>
                            </label>
                            <Field
                              name={`${field}[${index}]fullName`}
                              placeholder='Full Name'
                              className='form-control form-control-md form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}]fullName`} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>Date of Birth</span>
                            </label>

                            <DatePicker
                              name={`${field}[${index}]dob`}
                              selected={(value['dob'] && new Date(value['dob'])) || null}
                              placeholderText='Date of birth'
                              onChange={(value: any) =>
                                setFieldValue(`${field}[${index}]dob`, value)
                              }
                            />

                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}]dob`} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <UploadFile
                              label='Upload Police Check Report'
                              field={`${field}[${index}]policeCheckReport`}
                              setFieldValue={setFieldValue}
                              value={value?.policeCheckReport}
                            />
                          </div>
                        </div>
                        {value.hasOwnProperty('identificationNumber') && (
                          <div className='row mt-4'>
                            <div className='col-md-4'>
                              <label className='d-flex fs-5 fw-bold mb-2'>
                                <span className='required'>Identification Number</span>
                              </label>
                              <Field
                                name={`${field}[${index}]identificationNumber`}
                                placeholder='Identification Number'
                                className='form-control form-control-md form-control-solid'
                              />
                              <div className='text-danger mt-2'>
                                <ErrorMessage name={`${field}[${index}]identificationNumber`} />
                              </div>
                            </div>
                            <div className='col-md-4'>
                              <label className='d-flex fs-5 fw-bold mb-2'>
                                <span className='required'>Expiry</span>
                              </label>
                              <DatePicker
                                className='form-control form-control-lg form-control-solid'
                                name={`${field}[${index}]expiryDate`}
                                selected={
                                  (value['expiryDate'] && new Date(value['expiryDate'])) || null
                                }
                                placeholderText='Expiry Date'
                                onChange={(value: any) =>
                                  setFieldValue(`${field}[${index}]expiryDate`, value)
                                }
                              />

                              <div className='text-danger mt-2'>
                                <ErrorMessage name={`${field}[${index}]expiryDate`} />
                              </div>
                            </div>
                            <div className='col-md-4'>
                              <UploadFile
                                label='Working with Children Check'
                                field={`${field}[${index}]workingWithChildrenCheck`}
                                setFieldValue={setFieldValue}
                                value={value?.workingWithChildrenCheck}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='col-md-2 mt-10'>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                          onClick={() => arrayHelpers.remove(index)}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Remove Item'
                          disabled={values.length > 1 ? false : true}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon svg-icon-3'
                          />
                        </button>
                        {values.length === index + 1 && (
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Add New member'
                            onClick={() => {
                              if (values[0].hasOwnProperty('identificationNumber')) {
                                arrayHelpers.push({
                                  fullName: '',
                                  dob: '',
                                  policeCheckReport: '',
                                  identificationNumber: '',
                                  expiryDate: '',
                                  workingWithChildrenCheck: '',
                                })
                              } else {
                                arrayHelpers.push({
                                  fullName: '',
                                  dob: '',
                                  policeCheckReport: '',
                                })
                              }
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr013.svg'
                              className='svg-icon  svg-icon-3'
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </>
  )
}

import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import Select from 'react-select'
import {days} from '../../core/EducatorHelpers'
import {TimeRangePicker} from '../../../common/TimeRangePicker'

interface IProps {
  value: any
  arrayHelpers: any
  field: string
  setFieldValue: any
}

export const DaysAndTimes: FC<IProps> = ({value, arrayHelpers, setFieldValue, field}) => {
  return (
    <>
      {value?.length > 0 &&
        value?.map((valueObject: any, index: any) => (
          <table className='table table-responsive ' key={index}>
            <tbody>
              <tr>
                <td data-label='Name'>
                  <div className=' w-100'>
                    <div className='row align-items-center'>
                      <div className='col-md-5 fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>
                            Days and Time(s) excursion will take place:
                          </span>
                        </label>

                        <Select
                          options={days}
                          placeholder='Select'
                          name={`${field}[${index}].days`}
                          className='multi-select-container mt-3'
                          classNamePrefix='multi-select'
                          onChange={(e: any) => {
                            setFieldValue(
                              `${field}[${index}].days`,
                              e.map((day: any) => day.value)
                            )
                          }}
                          value={days.filter((day: any) => valueObject?.days?.includes(day.value))}
                          isMulti={true}
                          closeMenuOnSelect={false}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${field}[${index}].days`} />
                        </div>
                      </div>

                      <div className='col-md-5 fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required invisible'>.</span>
                        </label>
                        <TimeRangePicker
                          setFieldValue={setFieldValue}
                          field={`${field}[${index}].time`}
                          value={valueObject?.time}
                          ErrorMessage={ErrorMessage}
                        />
                      </div>

                      <div className='col-md-2 fv-row'>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                          onClick={() => arrayHelpers.remove(index)}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Remove Item'
                          disabled={value.length > 1 ? false : true}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon svg-icon-3'
                          />
                        </button>
                        {value.length === index + 1 && (
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Add New Item'
                            onClick={() => arrayHelpers.push('')}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr013.svg'
                              className='svg-icon  svg-icon-3'
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </>
  )
}

import React from 'react'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {EducatorsListHeader} from './components/header/EducatorsListHeader'
import {EducatorsTable} from './table/EducatorsTable'
import {EducatorEditModal} from './educator-edit-modal/EducatorEditModal'
import {KTCard} from '../../../../../_metronic/helpers'

const EducatorsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <EducatorsListHeader />
        <EducatorsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <EducatorEditModal />}
    </>
  )
}

const EducatorsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EducatorsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EducatorsListWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {User} from '../../core/_models'
import moment from 'moment'

type Props = {
  user: User
}

const UserJoinedDate: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}

    <div className='d-flex flex-column'>{moment(user.createdAt).format('DD-MM-YYYY')}</div>
  </div>
)

export {UserJoinedDate}

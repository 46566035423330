/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {FC} from 'react'
import {useNavigate} from 'react-router-dom'

type Props = {
  child: any
}

const status = ['Pending', 'Rejected', 'Approved', 'Ceased']
const ChildNameCell: FC<Props> = ({child}) => {
  const navigate = useNavigate()
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='d-flex flex-column'>
        {status.includes(child?.status) ? (
          <a
            className='menu-link cursor-pointer'
            onClick={(e) => {
              e.preventDefault()
              navigate(`${child._id}/view`)
            }}
          >
            {child?.child?.fullName}
          </a>
        ) : (
          child?.child?.fullName
        )}
      </div>
    </div>
  )
}

export {ChildNameCell}

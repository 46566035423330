import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/RolesListHeader'
import {UsersTable} from './table/UsersTable'
import {RoleEditModal} from './role-edit-modal/RoleEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {RoleStatusModal} from './role-edit-modal/RoleStatusModal'

const RolesList = () => {
  const {itemIdForUpdate, roleId, roleStatus} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <UsersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <RoleEditModal />}
      {roleId !== undefined && typeof roleStatus === 'boolean' && <RoleStatusModal />}
    </>
  )
}

const RolesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RolesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RolesListWrapper}

import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'
import {TimeRangePicker} from '../../../common/TimeRangePicker'
import {KTSVG} from '../../../../../../_metronic/helpers'

interface Props {
  value: any
  arrayHelpers: any
  field: string
  setFieldValue: any
}
export const EstimatedTime: FC<Props> = ({value, arrayHelpers, setFieldValue, field}) => {
  return (
    <>
      {value?.length > 0 &&
        value?.map((valueObject: any, index: any) => (
          <table className='table table-responsive ' key={index}>
            <tbody>
              <tr>
                <td data-label='Name'>
                  <div className=' w-100'>
                    <div className='row align-items-center'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>
                          Estimated time of travel between the different locations
                        </span>
                      </label>
                      <div className='col-md-6 fv-row mb-5'>
                        <TimeRangePicker
                          setFieldValue={setFieldValue}
                          field={`${field}[${index}].estimatedTime`}
                          value={valueObject?.estimatedTime}
                          ErrorMessage={ErrorMessage}
                        />
                      </div>

                      <div className='col-md-6 fv-row mb-5'>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name={`${field}[${index}].location`}
                          placeholder='Location'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${field}[${index}].location`} />
                        </div>
                      </div>

                      <div className='col-md-2 fv-row mb-5'>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                          onClick={() => arrayHelpers.remove(index)}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Remove Item'
                          disabled={value.length > 1 ? false : true}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon svg-icon-3'
                          />
                        </button>
                        {value.length === index + 1 && (
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Add New Item'
                            onClick={() => arrayHelpers.push({estimatedTime: '', location: ''})}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr013.svg'
                              className='svg-icon  svg-icon-3'
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </>
  )
}

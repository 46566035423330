import React, {FC, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
// import '../../card.css'
import {useAuth} from '../../../../auth'

interface ParentEducatorAgreementProps {
  values: any
  setFieldValue: any
}

const ParentEducatorAgreementForm: FC<ParentEducatorAgreementProps> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100 '>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>
          Between Me 2 FDC, the Educator and the person liable to pay child care fees
        </h2>
      </div>
      <div className='pb-8 pb-lg-10'>
        {' '}
        <p>The parties to this agreement are:</p>
        <h4>Me 2 FDC Pty Ltd.</h4>
        <p>Of suite 2,level 1,67 Sydney Road, Coburg 3058</p>
        <div className='fv-row'>
          <div className='row'>
            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>Educator name</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='educator.basicInformation.fullName'
                placeholder='Educator name'
                // value={currentUser?.educatorName || 'N/A'}
                disabled
              />
            </div>
            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>Parent/Guardian name</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='motherName'
                placeholder='Parent Name'
                value={values[values?.guardian?.responsible as string]?.fullName}
                disabled
              />
            </div>
          </div>
        </div>
        <p>
          (“the child parent or guardian” being the person(s) liable to pay child care fees in
          respect of the child)
        </p>
      </div>
      <h4>THE PARTIES AGREE THAT:</h4>

      <div className='w-100 ol-list-container d-flex justify-content-center mb-5'>
        <ol className='list-group list-group-numbered'>
          <span className='fw-bold'>The Agreement</span>
          <li className='list-group-item'>
            This agreement is in respect to the child named in the attached enrolment form.
          </li>
          <li className='list-group-item'>
            The parties acknowledge and agree that the enrolment form and the schedule (or any
            replacement schedule signed by the parties to note a change in hours in accordance with
            this agreement) are attached to this agreement and form part of the agreement between
            the parties.
          </li>
          <li className='list-group-item'>
            The parties acknowledge and agree that the Educator Agreement between Me 2 FDC and the
            Educator (“the Educator Agreement”) is relevant to and an important part of providing
            high quality child care but that the details of that agreement are confidential between
            Me 2 FDC and the Educator unless required by law to be disclosed.
          </li>
          <li className='list-group-item'>
            The parties acknowledge and agree that Me 2 FDC’s Policy and Procedures is relevant to
            and an important part of providing high quality child care and that the Policy and
            Procedures document is available to the parties at all times at the Me 2 FDC office. A
            copy may be provided to parties upon request. A charge may apply to cover the cost of
            copying and reproduction. The Policy and Procedures document changes from time to time
            in response to the needs of providing high quality child care and the current version
            and date of the version is noted on the document
          </li>
          <span className='fw-bold'>Enrolment</span>
          <li className='list-group-item'>
            The parties agree and acknowledge that the details of care for the child are as set out
            in the schedule to the attached enrolment form and that the parties may change the
            details of care for the child only in writing signed by all parties to this agreement.
          </li>
          <span className='fw-bold'>Educator obligations</span>
          <li className='list-group-item'>
            The Educator has entered into a separate agreement with Me 2 FDC and the terms of that
            agreement (‘the Educator Agreement”) apply to the provision of care under this
            agreement.
          </li>
          <li className='list-group-item'>
            The Educator affirms that all the relevant and necessary insurances are in place and
            undertakes to pay for all insurance on time and to keep the details of such insurance up
            to date in accordance with the Educator Agreement and Me 2 FDC Policy and Procedures.
          </li>
          <li className='list-group-item'>
            The Educator is not required to carry out any duties for the parent other than the
            services of caring for and educating the child.
          </li>
          <li className='list-group-item'>
            The Educator affirms that the home is healthy, safe and hygienic in accordance with Me 2
            FDC Policy and Procedures and the Educator Agreement.
          </li>
          <li className='list-group-item'>
            The Educator is obliged under the Educator Agreement and undertakes in this Agreement
            to:
            <ul>
              <li>Maintain all records including but not limited to attendance records.</li>
              <li>
                Provide notice to Me 2 FDC if the Educator is not available to provide care for the
                child.
              </li>
              <li>
                Promptly advise of any adult that starts living in, or frequently visiting, the
                Educator’s home.
              </li>
            </ul>
          </li>
          <span className='fw-bold'>Me 2 FDC obligations</span>
          <li className='list-group-item'>
            Me 2 FDC provides support and training to the Educator as set out in the Educator
            Agreement.
          </li>
          <li className='list-group-item'>
            Me 2 FDC provides forms and documents to meet record-keeping obligations at a cost. The
            Educator is liable to pay for the cost of forms and documents and this cost may be
            passed on to the Child Parent or Guardian as a charge or covered in the agreed hourly
            rate without a separate charge being made.
          </li>
          <li className='list-group-item'>
            Me 2 FDC will make every reasonable effort to find alternative care for the child if the
            Educator is not available
          </li>
          <span className='fw-bold'>Child Parent or Guardian obligations</span>
          <li className='list-group-item'>
            The Child Parent or Guardian declares that he, she or they is or are a person (or
            persons) with lawful authority of the child referred to in the attached enrolment form.{' '}
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian understands that Government assistance including but not
            limited to CCS and can help pay for child care but the parent is liable for all child
            care costs and undertakes and promises to pay all fees and charges (being the balance
            after the application of Government assistance, if available) promptly and to advise if
            payments cannot be made on time.
          </li>
          <li className='list-group-item'>
            {' '}
            The Child Parent or Guardian undertakes and promises to complete all attendance records
            for the child’s care including signing and noting the time that the child arrives at
            care and signing and noting the time that the child leaves care.{' '}
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian declares that the information in the enrolment form is true
            and correct to the best of his, her or their ability.
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian undertakes to immediately inform Me 2 FDC in the event of
            any change to the information about the child in the enrolment form.
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian undertakes and promises to abide by all the policy and
            procedure of Me 2 FDC and the Act and the Regulations (as amended from time to time)
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian agrees to collect or make arrangements for the collection
            of the child referred to in this agreement if the child becomes unwell while in care.
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian agrees that, in the event of an emergency or if the Child
            Parent or Guardian cannot be contacted, Me 2 FDC staff or the Educator may seek medical
            treatment for the child by a medical practitioner, hospital or ambulance service or,
            where appropriate, administering such emergency medical treatment as is reasonably
            necessary. (Note: there is also an authorisation in the enrolment form.)
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian agrees to reimburse any necessary expenses incurred if the
            child is unwell or needs medical treatment.
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian agrees to provide two weeks’ notice when ending this
            agreement and that such notice shall be to both the Educator and Me 2 FDC. Such notice
            can be by writing or by phoning the Me 2 FDC office.
          </li>
          <span className='fw-bold'>Fees</span>
          <li className='list-group-item'>
            {' '}
            The number of hours for this child in care is the number of hours set out in the
            schedule to the enrolment form
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian pays the fees directly to the Educator. The amount payable
            is the balance owing after the application of any government assistance including but
            not limited to CCS.
          </li>
          <li className='list-group-item'>
            Fees must be paid fortnightly unless otherwise agreed in writing between the parties.
          </li>
          <li className='list-group-item'>
            The parties agree to complete all forms and records required to provide a high quality
            child care service meeting all of the requirements of Me 2 FDC Policy and Procedure and
            government law and regulations (both State and Federal).
          </li>
          <li className='list-group-item'>
            The parties note that:
            <ul>
              <li>The attendance records must accurately reflect the child’s attendance</li>
              <li>
                The Child Parent or Guardian is not asked to sign attendance records before care has
                been provided.
              </li>
              <li>
                The Child Parent or Guardian is not required to sign the attendance records for days
                when the child is not normally in care.
              </li>
              <li>
                The attendance records must clearly identify days when the child has been booked but
                has not attended care. Government assistance is payable for up to 42 days’ absence
                provided that the absence is not before care commenced or after care stopped.
              </li>
              <li>
                Government assistance is not payable for a child’s absence from care if:
                <ul>
                  <li>
                    {' '}
                    The absence occurred on a day that was before the child had actually attended
                    care with the family day care educator. For example, the care arrangement was to
                    start on Monday but the child was sick on Monday so Tuesday was the first day in
                    care. Government assistance is not payable for the absence on that Monday; or
                  </li>
                  <li>
                    The absence occurred on a day that was after the child stopped attending care.
                    For example, the parents decided to stop care and the child stopped attending
                    care on the day that the parents gave notice. There is a14 day notice period.
                    The 14 days are an absence from care but Government assistance is not available
                    to help pay the cost of child care.
                  </li>
                </ul>
              </li>
              <li>
                Where the child attends school or kindergarten and is taken there or collected from
                there by the Educator, the Educator will sign and note the time and the Child Parent
                or Guardian is not required to sign
              </li>
            </ul>
          </li>
          <li className='list-group-item'>
            Where CCS applies, the CCS is paid to Me 2 FDC and then Me 2 FDC pays the Educator.
          </li>
          <li className='list-group-item'>
            If CCS is reduced or refused, the Child Parent or Guardian is liable for the full
            amount.
          </li>
          <li className='list-group-item'>Fees are payable for absences from care.</li>
          <li className='list-group-item'>
            The Child Parent or Guardian may end this agreement upon giving two weeks’ notice in
            writing addressed to Me 2 FDC.{' '}
          </li>
          <li className='list-group-item'>
            {' '}
            Where a CCS applies to booked care, that CCS will continue to be claimed for the care
            provided under this agreement for the two weeks of the notice period and during this
            time the Child Parent or Guardian cannot receive CCS at any other child care service.
          </li>
          <span className='fw-bold'>Charges</span>
          <li className='list-group-item'>
            {' '}
            Charges are expenses that are payable to others and are associated with the care of the
            child. The Child Parent or Guardian is required to provide food and nappies (if
            required) for the child. If the Educator provides food or nappies, the Child Parent or
            Guardian will be charged. Charges might also include the costs of entry into museums or
            cinemas or other places that children visit with the Educator.
          </li>
          <li className='list-group-item'>
            The Educator may provide the Child Parent or Guardian with an invoice or with the
            receipts for the purchase and request reimbursement.
          </li>
          <li className='list-group-item'>
            The Child Parent or Guardian must pay charges directly to the Educator.
          </li>
          <li className='list-group-item'>
            Charges must be paid within 14 days of the request in writing being given to the Parent
          </li>
        </ol>
      </div>
      <div className='fv-row mb-10'>
        <span className='d-flex align-items-center fs-5  mb-2 row'>
          <div>
            <Field type='checkbox' name='policyAgreement' />
            <span className='required col-10 ms-2'>
              {' '}
              <span className='required col-10 ms-2'>
                I agree with the above mentioned agreement terms{' '}
              </span>{' '}
            </span>
          </div>
        </span>

        <div className='text-danger mt-2'>
          <ErrorMessage name='policyAgreement' />
        </div>
      </div>
    </div>
  )
}

export {ParentEducatorAgreementForm}

import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'

interface Props {
  value: any
  arrayHelpers: any
  field: string
  setFieldValue: any
  title: string
  school?: boolean
}
export const NumbersAndChildNames: FC<Props> = ({
  value,
  arrayHelpers,
  setFieldValue,
  field,
  title,
  school = false,
}) => {
  return (
    <>
      {value?.length > 0 &&
        value?.map((valueObject: any, index: any) => (
          <table className='table table-responsive ' key={index}>
            <tbody>
              <tr>
                <td data-label='Name'>
                  <div className=' w-100'>
                    <div className='row align-items-center'>
                      <div className='col-md-12 fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>{!school ? 'Child ' : 'School '}Name:</span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name={`${field}[${index}].name`}
                          placeholder={!school ? 'Child Name' : 'School Name'}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${field}[${index}].name`} />
                        </div>
                      </div>

                      <div className='col-md-12 fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>School address:</span>
                        </label>

                        <Field
                          className='form-control form-control-solid mb-2'
                          as='textarea'
                          rows={4}
                          name={`${field}[${index}].${
                            !school
                              ? title.includes('pick up')
                                ? 'pickUpAddress'
                                : 'dropOffAddress'
                              : 'address'
                          }`}
                          placeholder={`${
                            !school
                              ? title.includes('pick up')
                                ? 'Pick up location - School address'
                                : 'Drop off location - School address'
                              : 'School Address'
                          } `}
                        ></Field>

                        <div className='text-danger mt-2'>
                          <ErrorMessage
                            name={`${field}[${index}].${
                              !school
                                ? title.includes('pick up')
                                  ? 'pickUpAddress'
                                  : 'dropOffAddress'
                                : 'address'
                            }`}
                          />
                        </div>
                      </div>

                      {!school && (
                        <div className='col-md-12 fv-row mb-5'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Home address:</span>
                          </label>

                          <Field
                            className='form-control form-control-solid mb-2'
                            as='textarea'
                            rows={4}
                            name={`${field}[${index}].${
                              title.includes('drop off') ? 'pickUpAddress' : 'dropOffAddress'
                            }`}
                            placeholder={`${
                              title.includes('drop off') ? 'Pick up location' : 'Drop off location'
                            } - Home address`}
                          ></Field>

                          <div className='text-danger mt-2'>
                            <ErrorMessage
                              name={`${field}[${index}].${
                                title.includes('drop off') ? 'pickUpAddress' : 'dropOffAddress'
                              }`}
                            />
                          </div>
                        </div>
                      )}

                      <div className='col-md-2 fv-row '>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                          onClick={() => arrayHelpers.remove(index)}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Remove Item'
                          disabled={value?.length > 1 ? false : true}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon svg-icon-3'
                          />
                        </button>
                        {value?.length === index + 1 && (
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Add New Item'
                            onClick={() => {
                              if (!school) {
                                arrayHelpers.push({name: '', pickUpAddress: '', dropOffAddress: ''})
                              } else {
                                arrayHelpers.push({name: '', address: ''})
                              }
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr013.svg'
                              className='svg-icon  svg-icon-3'
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </>
  )
}

import axios, {AxiosError, AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Role, RolesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ROLE_URL = `${API_URL}/admin/roles`

const getUsers = (query: string): Promise<RolesQueryResponse> => {
  return axios.get(`${ROLE_URL}?${query}`).then((d: AxiosResponse<RolesQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<Role | undefined> => {
  return axios
    .get(`${ROLE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Role>>) => response.data)
    .then((response: Response<Role>) => response.data)
}

const createUser = (user: Role): Promise<Role | undefined | Error> => {
  return axios
    .post(ROLE_URL, user)
    .then((response: AxiosResponse<Response<Role>>) => response.data)
    .then((response: Response<Role>) => response.data)
    .catch((error: AxiosError) => {
      throw error
    })
}

const updateUser = (user: Role): Promise<Role | undefined> => {
  return axios
    .patch(`${ROLE_URL}/${user._id}`, user)
    .then((response: AxiosResponse<Response<Role>>) => response.data)
    .then((response: Response<Role>) => response.data)
}

const updateRoleStatus = ({status, id}: {status: boolean; id: ID}): Promise<Role | undefined> => {
  return axios
    .patch(`${ROLE_URL}/${id}/status-update`, {status})
    .then((response: AxiosResponse<Response<Role>>) => response.data)
    .then((response: Response<Role>) => response.data)
}
const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${ROLE_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${ROLE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  updateRoleStatus,
}

import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'
import DatePicker from '../../../common/DatePicker'

interface Props {
  value: any
  setFieldValue: any
  field: string
}
export const Educator: FC<Props> = ({value, setFieldValue, field}) => {
  return (
    <>
      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Educator name:</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`basicInformation.fullName`}
            placeholder='Educators Name'
            disabled
          />
        </div>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Contact number:</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`basicInformation.contactPhone[0]`}
            placeholder='Contact Number'
            disabled
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Start date:</span>
          </label>
          <DatePicker
            name={`${field}.startDate`}
            selected={(value?.startDate && new Date(value?.startDate)) || null}
            placeholderText='Start Date'
            onChange={(value: any) => setFieldValue(`${field}.startDate`, value)}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.startDate`} />
          </div>
        </div>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Renewal date:</span>
          </label>

          <DatePicker
            name={`${field}.renewalDate`}
            selected={(value?.renewalDate && new Date(value.renewalDate)) || null}
            placeholderText='Renewal Date'
            onChange={(value: any) => setFieldValue(`${field}.renewalDate`, value)}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.renewalDate`} />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>License no:</span>
          </label>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`${field}.licenseNo`}
            placeholder='License No'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.licenseNo`} />
          </div>
        </div>

        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>License expiry:</span>
          </label>
          <DatePicker
            name={`${field}.licenseExpiry`}
            selected={(value?.licenseExpiry && new Date(value?.licenseExpiry)) || null}
            placeholderText='Expiry Date'
            onChange={(value: any) => setFieldValue(`${field}.licenseExpiry`, value)}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.licenseExpiry`} />
          </div>
        </div>
      </div>
    </>
  )
}

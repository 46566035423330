import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const LOGIN_URL = `${API_URL}/admin/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot/password`
export const LOGOUT_URL = `${API_URL}/auth/logout`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset/password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  confirmPassword: string
) {
  return axios.post(REGISTER_URL, {
    email,
    firstName,
    lastName,
    password,
    confirmPassword,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function logoutUser(token: string) {
  return axios.post(LOGOUT_URL, {
    token,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function resetPassword(password: string, token: string) {
  return axios.post<{success: boolean; message: string}>(`${RESET_PASSWORD_URL}`, {
    password,
    token,
  })
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {useAuth} from '../../../../auth'
import Collapsible from '../../../common/Collapsible'
import ChildHeader from '../../../common/ChildFormHeader'

const ChildAuthorizations: FC<any> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <Collapsible title={'Medical treatment'} id='medical-info'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Give permission for Me 2 fdc and /or the family day care educator to seek
                    medical treatment for my child from a registered medical practitioner, hospital,
                    or ambulance service
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.medicalTreatment'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.medicalTreatment'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='permission.medicalTreatment' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Give permission for Me 2 FDC and /or the family day care educator to seek
                    transportation of my child by an ambulance service.
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.ambulanceService'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.ambulanceService'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='permission.ambulanceService' />
                </div>
              </div>
            </Collapsible>

            <Collapsible title='Routine local excursions' id='Excursions'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Allow my/our child to travel in the educator’s car during regular outings
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.canTravelOnEducatorCar'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.canTravelOnEducatorCar'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='permission.canTravelOnEducatorCar' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Allow my/our child to travel on public transport (bus, train or tram) with the
                    educator during regular outings
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.canTravelOnPublicTransport'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.canTravelOnPublicTransport'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='permission.canTravelOnPublicTransport' />
                </div>
              </div>
            </Collapsible>

            <Collapsible title='Sunscreen consent' id='sunscreen-consent'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Give permission for my child to have a 50+ sunscreen applied as per Me 2 FDC’s
                    sun smart policy{' '}
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.sunscreenAllow'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.sunscreenAllow'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='permission.sunscreenAllow' />
                </div>
              </div>
            </Collapsible>
            <Collapsible title='Sanitizer consent' id='sanitizer-consent'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Give permission for my child to have sanitizer applied as per Me 2 FDC’s germ
                    protection policy
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.allowSanitizer'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.allowSanitizer'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='permission.allowSanitizer' />
                </div>
              </div>
            </Collapsible>
            <Collapsible title='Photographic consent' id='photographic-consent'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Give permission for my child to be photographed by Me 2 FDC staff and educators
                    for use by Me 2 FDC only{' '}
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.photographForMe2FDC'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.photographForMe2FDC'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='permission.photographForMe2FDC' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Give permission for my child to be photographed and/or videotaped in the event
                    of media reportage
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.photographForReportage'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.photographForReportage'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='permission.photographForReportage' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Give permission for photographs of my child to be used by Me 2 FDC for posting
                    on its website
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.photographForPosting'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='permission.photographForPosting'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='permission.photographForPosting' />
                </div>
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChildAuthorizations}

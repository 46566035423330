/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {StatisticsWidget5} from '../../../_metronic/partials/widgets'
import {getStats} from '../_request'
import {UserStats} from '../../modules/apps/common/UsersStats'

const DashboardPage: FC = () => {
  const [stats, setStats] = useState<any>({})

  async function getStatsData() {
    const statsData: any = await getStats()
    setStats(statsData.data)
  }

  useEffect(() => {
    getStatsData()
  }, [])
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'></div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen032.svg'
            color='white'
            iconColor='primary'
            title={stats?.totalUsers}
            description='Total Users'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color='dark'
            iconColor='white'
            title={stats?.parent}
            description='Total Families'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin006.svg'
            color='warning'
            iconColor='white'
            title={stats?.educator}
            description='Total Educators'
          />
        </div>
      </div>
      {/* end::Row */}
      {stats?.userChartCounts && (
        <div className='row'>
          <div className='col-xl-6'>
            <UserStats
              className='card-stretch card-stretch-half gutter-b'
              symbolShape='circle'
              baseColor='success'
              adsChartCounts={stats?.userChartCounts}
            />
          </div>
        </div>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}

import React, {FC} from 'react'
import {Field, ErrorMessage, FieldArray} from 'formik'
import {QualificationCard} from '../QualificationCard'

const EducatorQualificationForm: FC<any> = ({values, setFieldValue}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Educator Qualification</h2>
      </div>

      <FieldArray
        name='qualificationsCompleted'
        render={(arrayHelpers) => (
          <QualificationCard
            values={values?.qualificationsCompleted}
            arrayHelpers={arrayHelpers}
            field='qualificationsCompleted'
            setFieldValue={setFieldValue}
          />
        )}
      />

      <div className='pb-10 pb-lg-12'>
        <h4 className='fw-bolder text-dark'>Progress towards completion of qualifications</h4>
      </div>

      <FieldArray
        name='qualificationsInProgress'
        render={(arrayHelpers) => (
          <QualificationCard
            values={values?.qualificationsInProgress}
            arrayHelpers={arrayHelpers}
            field='qualificationsInProgress'
            setFieldValue={setFieldValue}
          />
        )}
      />
    </div>
  )
}

export {EducatorQualificationForm}

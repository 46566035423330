/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {useAuth} from '../../../../auth'
import Collapsible from '../../../common/Collapsible'
import ChildHeader from '../../../common/ChildFormHeader'
import {AddressDetailInfo} from '../../../common/AddressDetailInfo'
import UploadFile from '../../../common/UploadFile'
import DatePicker from '../../../common/DatePicker'

const ChildImmunizationForm: FC<any> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <div className='fv-row mb-5'>
              <label className='d-flex align-items-center fs-5  mb-2'>
                <span className='required'>Has your child been immunised?</span>
              </label>
              <div className='row'>
                <span className='align-items-center fs-5  m-2 col-4 '>
                  <Field type='radio' className='me-2' name='childImmunised' value='yes' />
                  Yes
                </span>
                <span className='align-items-center fs-5  m-2 col-4 '>
                  <Field type='radio' className='me-2' name='childImmunised' value='no' />
                  No
                </span>
              </div>

              <div className='text-danger mt-2'>
                <ErrorMessage name='childImmunised' />
              </div>
            </div>
            {values?.childImmunised === 'yes' && (
              <div className='fv-row mb-5'>
                <UploadFile
                  field='childImmunisedFile'
                  setFieldValue={setFieldValue}
                  value={values?.childImmunisedFile}
                />
              </div>
            )}

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5  mb-2'>
                <span className='required'>Are all immunisations up-to-date?</span>
              </label>
              <div className='row'>
                <span className='align-items-center fs-5  m-2 col-4 '>
                  <Field type='radio' className='me-2' name='immunisationUpToDate' value='yes' />
                  Yes
                </span>
                <span className='align-items-center fs-5  m-2 col-4 '>
                  <Field type='radio' className='me-2' name='immunisationUpToDate' value='no' />
                  No
                </span>
              </div>

              <div className='text-danger mt-2'>
                <ErrorMessage name='immunisationUpToDate' />
              </div>
            </div>

            <Collapsible
              title='Government Assistance with Payment of Child Care Fees'
              id='government-assistance'
            >
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Do you wish to claim ccs for your child and this enrolment?
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='governmentAssistance.claimCCS'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='governmentAssistance.claimCCS'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='governmentAssistance.claimCCS' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Have you provided a copy of the confirmation of your CCS entitlement?
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='governmentAssistance.CCSConfirmation'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='governmentAssistance.CCSConfirmation'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='governmentAssistance.CCSConfirmation' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span>
                    Please contact the Department of Human Services at 136150 if you have any
                    concerns or questions about your entitlement to CCS. You are responsible for
                    child care fees. You must pay the balance of the fees after government
                    assistance (CCS ) if any is applied to your account
                  </span>
                </label>
              </div>
            </Collapsible>

            <Collapsible title='Other Information' id='other-info'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Is your child currently enrolled in any other child care service?
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field type='radio' className='me-2' name='hasOtherService' value='yes' />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field type='radio' className='me-2' name='hasOtherService' value='no' />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='hasOtherService' />
                </div>
              </div>
              {values?.hasOtherService === 'yes' && (
                <>
                  <div className='fv-row mb-10'>
                    <div className='row d-flex align-items-center fs-5  mb-2'>
                      <span className='required col-8'>
                        Please give the date that you gave notice to the other service
                      </span>

                      <div className='col-4'>
                        <DatePicker
                          name='otherServiceNotice.startDate'
                          selected={
                            (values?.otherServiceNotice?.startDate &&
                              new Date(values?.otherServiceNotice?.startDate)) ||
                            null
                          }
                          placeholderText='Notice start date'
                          onChange={(value: any) =>
                            setFieldValue('otherServiceNotice.startDate', value)
                          }
                        />
                      </div>
                    </div>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='otherServiceNotice.startDate' />
                    </div>
                  </div>
                  <div className='fv-row mb-10'>
                    <div className='row d-flex align-items-center fs-5  mb-2'>
                      <span className='required col-8'>
                        Please give the date that will be the last day of care in the other service
                      </span>

                      <div className='col-4'>
                        <DatePicker
                          name='otherServiceNotice.lastDate'
                          selected={
                            (values?.otherServiceNotice?.lastDate &&
                              new Date(values?.otherServiceNotice?.lastDate)) ||
                            null
                          }
                          placeholderText='Last date of notice'
                          onChange={(value: any) =>
                            setFieldValue('otherServiceNotice.lastDate', value)
                          }
                        />
                      </div>
                    </div>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='otherServiceNotice.lastDate' />
                    </div>
                  </div>
                </>
              )}

              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>Does your child currently go to school?</span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='childSchool.goingToSchool'
                      value='yes'
                    />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field
                      type='radio'
                      className='me-2'
                      name='childSchool.goingToSchool'
                      value='no'
                    />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='childSchool.goingToSchool' />
                </div>
              </div>
              {values?.childSchool?.goingToSchool === 'yes' && (
                <>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Name of the school</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='childSchool.name'
                      placeholder='School Name'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='childSchool.name' />
                    </div>
                  </div>
                  <AddressDetailInfo
                    values={values}
                    setFieldValue={setFieldValue}
                    field='childSchool.address'
                  />
                </>
              )}
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChildImmunizationForm}

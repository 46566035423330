import {ErrorMessage, Field} from 'formik'
import React, {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
interface Props {
  values: any
  arrayHelpers: any
  field: string
}

export const ContactPhoneField: FC<Props> = ({values, arrayHelpers, field}) => {
  return (
    <>
      {values?.length > 0 &&
        values?.map((contact: any, index: any) => (
          <table className='table table-responsive ' key={index}>
            <tbody>
              <tr>
                <td data-label='Name'>
                  <div className=' w-100'>
                    <div className='row'>
                      <div className='col-md-10'>
                        <div className='input-group mb-3'>
                          {/* <div className='input-group-prepend'>
                            <span className='input-group-text border-0'>+61</span>
                          </div> */}
                          <Field
                            name={`${field}[${index}]`}
                            placeholder='Contact Phone'
                            className='form-control form-control-md form-control-solid'
                          />
                        </div>

                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${field}[${index}]`} />
                        </div>
                      </div>
                      <div className='col-md-2'>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                          onClick={() => arrayHelpers.remove(index)}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Remove Item'
                          disabled={values.length > 1 ? false : true}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon svg-icon-3'
                          />
                        </button>
                        {values.length === index + 1 && (
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Add New Item'
                            onClick={() => arrayHelpers.push('')}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr013.svg'
                              className='svg-icon  svg-icon-3'
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </>
  )
}

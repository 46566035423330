/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Role} from '../../core/_models'
import moment from 'moment'

type Props = {
  role: Role
}
const statusClass: any = {
  Active: 'badge-light-success',
  Inactive: 'badge-light-danger',
}
const RoleStatus: FC<Props> = ({role}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span className={`badge ${statusClass[role?.status ? 'Active' : 'Inactive']}`}>
        {role?.status ? 'Active' : 'Inactive'}
      </span>
    </div>
  </div>
)

export {RoleStatus}

import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export function uploadSingleFile(data: any) {
  return axios({
    method: 'post',
    url: `${API_URL}/upload`,
    data: data,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

import {ErrorMessage, Field, FieldArray} from 'formik'
import {FC} from 'react'
import Collapsible from '../../../common/Collapsible'
import {NumbersAndChildNames} from './NumbersAndChildNames'

interface Props {
  value: any
  setFieldValue: any
  field: string
  title: string
}
export const Children: FC<Props> = ({value, setFieldValue, field, title}) => {
  return (
    <Collapsible title={`Child transportation`} id={`risk-management-children-${field}`}>
      {title?.includes('school') && (
        <>
          <FieldArray
            name={`${field}.transportationSchools`}
            render={(arrayHelpers) => (
              <NumbersAndChildNames
                value={value?.children || ['']}
                title={title}
                arrayHelpers={arrayHelpers}
                field={`${field}.transportationSchools`}
                setFieldValue={setFieldValue}
                school={true}
              />
            )}
          />
          <FieldArray
            name={`${field}.transportationChildren`}
            render={(arrayHelpers) => (
              <NumbersAndChildNames
                value={value?.children || ['']}
                title={title}
                arrayHelpers={arrayHelpers}
                field={`${field}.transportationChildren`}
                setFieldValue={setFieldValue}
              />
            )}
          />
        </>
      )}

      <div className='row'>
        <div className='col-md-12 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>
              The number of educators / responsible adults, appropriate to provide supervision and
              whether any adults with specialised skills are required:
            </span>
          </label>

          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`${field}.numberOfAdults`}
            placeholder='Enter number of adults'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.numberOfAdults`} />
          </div>
        </div>
        <div className='col-12'>
          <Field
            className='form-control form-control-solid mb-2'
            as='textarea'
            rows={4}
            placeholder='Details of the adults with specialised skills'
            name={`${field}.adultWithSpecialisedSkills`}
          ></Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.adultWithSpecialisedSkills`} />
          </div>
        </div>

        <div className='col-md-12 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>The number of children being transported</span>
          </label>

          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`${field}.numberOfChildren`}
            placeholder='Enter number of child'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.numberOfChildren`} />
          </div>
        </div>
      </div>
    </Collapsible>
  )
}

import {ErrorMessage, Field} from 'formik'
import React, {FC} from 'react'
import DatePicker from '../../../common/DatePicker'
import {KTSVG} from '../../../../../../_metronic/helpers'

interface Props {
  values: any
  arrayHelpers: any
  field: string
  setFieldValue: any
  fieldLabels?: any
}

export const BrandManufacturing: FC<Props> = ({
  values,
  arrayHelpers,
  field,
  setFieldValue,
  fieldLabels,
}) => {
  return (
    <>
      {values?.length > 0 &&
        values?.map((value: any, index: any) => {
          return (
            <React.Fragment key={index}>
              <table className='table table-responsive ' key={index}>
                <tbody>
                  <tr>
                    <td data-label='Name'>
                      <div className=' w-100'>
                        <div className='row'>
                          <div className='col-md-10'>
                            <div className='row'>
                              <div className='col-md-6'>
                                <label className='d-flex fs-5 fw-bold mb-2'>
                                  <span className='required'>{fieldLabels.brandName}</span>
                                </label>
                                <Field
                                  name={`${field}[${index}].brandName`}
                                  placeholder='Brand Name'
                                  className='form-control form-control-md form-control-solid'
                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name={`${field}[${index}].brandName`} />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <label className='d-flex fs-5 fw-bold mb-2'>
                                  <span className='required'>{fieldLabels.manufacturingDate}</span>
                                </label>

                                <DatePicker
                                  name={`${field}[${index}].manufacturingDate`}
                                  selected={
                                    (value['manufacturingDate'] &&
                                      new Date(value['manufacturingDate'])) ||
                                    null
                                  }
                                  placeholderText='Manufacturing Date'
                                  onChange={(value: any) =>
                                    setFieldValue(`${field}[${index}].manufacturingDate`, value)
                                  }
                                />

                                <div className='text-danger mt-2'>
                                  <ErrorMessage name={`${field}[${index}].manufacturingDate`} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-2 mt-10'>
                            <button
                              type='button'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                              onClick={() => arrayHelpers.remove(index)}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              title='Remove Item'
                              disabled={values.length > 1 ? false : true}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon svg-icon-3'
                              />
                            </button>
                            {values.length === index + 1 && (
                              <button
                                type='button'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title='Add New member'
                                onClick={() => {
                                  arrayHelpers.push({
                                    brandName: '',
                                    manufacturingDate: '',
                                  })
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr013.svg'
                                  className='svg-icon  svg-icon-3'
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </React.Fragment>
          )
        })}
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {Field, ErrorMessage, FieldArray} from 'formik'
import ChildHeader from '../../../common/ChildFormHeader'
import DatePicker from '../../../common/DatePicker'
import Collapsible from '../../../common/Collapsible'
import {ParentsDetailInfo} from '../../../common/ParentDetailForm'
import {BookingHours} from '../BookingHours'

const ComplianceWrittenAgreement: FC<any> = ({values, setFieldValue}) => {
  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <div className='fv-row'>
              <div className='row'>
                <div className='col-md-4 fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Child's full name</span>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='child.fullName'
                    placeholder='Full Name'
                    disabled
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='child.fullName' />
                  </div>
                </div>

                <div className='col-md-4 fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Date of Birth</span>
                  </label>
                  <DatePicker
                    name='child.dob'
                    selected={(values?.child?.dob && new Date(values?.child?.dob)) || null}
                    placeholderText='Date of birth'
                    onChange={(value: any) => setFieldValue('child.dob', value)}
                    disabled
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='child.dob' />
                  </div>
                </div>
                <div className='col-md-4 fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>CRN</span>
                  </label>
                  <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='child.CRN'
                    placeholder='CRN'
                    disabled
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='child.CRN' />
                  </div>
                </div>
              </div>
            </div>
            <fieldset disabled={true}>
              {(values?.guardian?.responsible === 'mother' ||
                values?.guardian?.responsible === 'both') && (
                <Collapsible title={'Parent details'} id='mother-info-2'>
                  <ParentsDetailInfo
                    values={values?.mother}
                    setFieldValue={setFieldValue}
                    field='mother'
                  />
                </Collapsible>
              )}

              {(values?.guardian?.responsible === 'father' ||
                values?.guardian?.responsible === 'both') && (
                <Collapsible title='Parent details' id='father-info-2'>
                  <ParentsDetailInfo
                    values={values?.father}
                    setFieldValue={setFieldValue}
                    field='father'
                  />
                </Collapsible>
              )}
            </fieldset>

            <Collapsible title='Care arrangement details' id='care-info-2'>
              <div className='row'>
                <span className='align-items-center fs-5  m-2 col '>
                  <Field type='radio' className='me-2' name='careArrangement' value='Routine' />
                  Routine
                </span>
                <span className='align-items-center fs-5  m-2 col '>
                  <Field type='radio' className='me-2' name='careArrangement' value='Casual' />
                  Casual
                </span>
                <span className='align-items-center fs-5  m-2 col '>
                  <Field type='radio' className='me-2' name='careArrangement' value='Both' />
                  Both
                </span>
              </div>
            </Collapsible>
            <Collapsible
              title='Routine sessions times for pre-school children'
              id='routine-sessions-info-2'
            >
              <div className='fv-row mb-10'>
                <FieldArray
                  name='childSessionsInfo.preSchoolAge'
                  render={(arrayHelpers) => (
                    <BookingHours
                      values={
                        values?.childSessionsInfo?.preSchoolAge || [
                          {
                            day: '',
                            careTime: '',
                          },
                        ]
                      }
                      preSchool={true}
                      title='Routine Schedule'
                      arrayHelpers={arrayHelpers}
                      field='childSessionsInfo.preSchoolAge'
                      setFieldValue={setFieldValue}
                    />
                  )}
                />
              </div>
            </Collapsible>
            <Collapsible
              title='Routine sessions times for school age children'
              id='routine-sessions-school-info-2'
            >
              <div className='fv-row mb-10'>
                <FieldArray
                  name='childSessionsInfo.schoolAge'
                  render={(arrayHelpers) => (
                    <BookingHours
                      values={
                        values?.childSessionsInfo?.schoolAge || [
                          {
                            day: '',
                            beforeSchoolCareTime: '',
                            afterSchoolCareTime: '',
                          },
                        ]
                      }
                      title='Routine Schedule'
                      arrayHelpers={arrayHelpers}
                      field='childSessionsInfo.schoolAge'
                      setFieldValue={setFieldValue}
                    />
                  )}
                />
              </div>
            </Collapsible>

            <Collapsible
              title='Routine sessions times for school age children during School Holidays'
              id='routine-holiday-sessions-info-2'
            >
              <div className='fv-row mb-10'>
                <FieldArray
                  name='childSessionsInfo.schoolAgeHoliday'
                  render={(arrayHelpers) => (
                    <BookingHours
                      values={
                        values?.childSessionsInfo?.schoolAgeHoliday || [
                          {
                            day: '',
                            careTime: '',
                          },
                        ]
                      }
                      preSchool={true}
                      title='Holiday Schedule'
                      arrayHelpers={arrayHelpers}
                      field='childSessionsInfo.schoolAgeHoliday'
                      setFieldValue={setFieldValue}
                    />
                  )}
                />
              </div>
            </Collapsible>

            <Collapsible title='Fees – Charged per hour' id='fees–charged-per-hour-info-2'>
              <div className='fv-row'>
                <div className='row'>
                  <div className='col-md-4 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Standard Fees</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='feesDetails.standardFees'
                      placeholder='Standard Fee'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='feesDetails.standardFees' />
                    </div>
                  </div>
                  <div className='col-md-4 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Non-Standard Fees/Public Holidays</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='feesDetails.nonStandardFees'
                      placeholder='Non Standard Fee'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='feesDetails.nonStandardFees' />
                    </div>
                  </div>
                  <div className='col-md-4 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Weekend Fees</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='feesDetails.weekendFees'
                      placeholder='Weekend fee'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='feesDetails.weekendFees' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='fv-row'>
                <div className='row'>
                  <div className='col-md-4 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Casual Fees</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='feesDetails.casualFees'
                      placeholder='Casual Fees'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='feesDetails.casualFees' />
                    </div>
                  </div>
                  <div className='col-md-4 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Other Fees</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='feesDetails.otherFees'
                      placeholder='Other Fees'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='feesDetails.otherFees' />
                    </div>
                  </div>
                </div>
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ComplianceWrittenAgreement}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ApplicationForm} from './steps/ApplicationForm'
import {EducatorEmergencyContactForm} from './steps/EducatorEmergencyContactForm'
import {EducatorQualificationForm} from './steps/EducatorQualificationForm'
import {EducatorTrainingForm} from './steps/EducatorTrainingForm'
import {EducatorSecurityForm} from './steps/EducatorSecurityForm'
import {EducatorStatutoryDeclaration} from './steps/EducatorStatutoryDeclaration'
import {EducatorMedicalCertificate} from './steps/EducatorMedicalCertificate'
import {
  EducatorSteps,
  createEducatorSchemas,
  educatorKeysData,
  inits,
} from '../core/EducatorHelpers'
import {useMutation, useQuery} from 'react-query'
import {createEducator, getEducatorByID, getEducatorForm, updateEducator} from '../core/_request'
import {useParams} from 'react-router-dom'
import {ICreateEducator} from '../core/_models'
import {StepperAsideMenu} from '../../common/StepperAsideMenu'
import {EducatorAgreementForm} from './steps/EducatorAgreementForm'
import {EducatorComplianceForm} from './steps/EducatorComplianceForm'
import {ComingSoon} from '../../common/ComingSoon'
import {EducatorSelfAssessment} from './steps/EducatorSelfAssessment'
import {RiskAssessment} from './steps/RiskAssessment'
import {EducatorDeclaration} from './risk-assessment-partials/EducatorDeclaration'
import {mergeDeep} from '../../children-management/core/ChildEnrolmentHelpers'

export const EducatorProfileForm = ({updateEducatorData}: any) => {
  const {id} = useParams()
  const navigate = useNavigate()
  const query: any = useQuery(['educatorData', id], () => getEducatorByID(id as string), {
    enabled: !!id, // Only execute the query if the ID is defined and not null
  })
  const educatorFormQuery: any = useQuery(['educatorUserData', id], getEducatorForm, {
    enabled: !!!id, // Only execute the query if the ID is not defined or null
  })

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper: any = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createEducatorSchemas[0])
  const [initValues, setInitValues] = useState<any>(inits[0])
  const [educatorFormValues, setEducatorFormValues] = useState<ICreateEducator>()
  const [editable] = useState(window.location.pathname.includes('edit'))
  const createMutation: any = useMutation(createEducator)
  const updateMutation: any = useMutation(updateEducator)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    updateEducatorData(educatorFormValues)
  }, [educatorFormValues])

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }, [stepper?.current?.currentStepIndex])

  useEffect(() => {
    if (!query.isLoading && query?.isSuccess) {
      setEducatorFormValues(query?.data)
      updateEducatorData(query?.data)
    }
  }, [query.isLoading])

  useEffect(() => {
    if (!educatorFormQuery.isLoading && educatorFormQuery?.isSuccess && educatorFormQuery?.data) {
      setEducatorFormValues(educatorFormQuery?.data)
      navigate(`/educator/edit/${educatorFormQuery?.data?._id}`)
    }
  }, [educatorFormQuery.isLoading])

  const updateStepperWithData = () => {
    setCurrentSchema(createEducatorSchemas[stepper.current.currentStepIndex])
    setInitValues(inits[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper?.current?.goNext()
    } else {
      stepper.current.goto(1)
    }
    if (!educatorFormValues?._id) {
      createMutation?.data && setEducatorFormValues(createMutation?.data)
    } else {
      updateMutation?.data && setEducatorFormValues(updateMutation?.data)
    }
  }

  useEffect(() => {
    if (!updateMutation?.isLoading && updateMutation?.isSuccess) {
      updateStepperWithData()
    }
  }, [updateMutation?.isSuccess])

  useEffect(() => {
    if (!createMutation?.isLoading && createMutation?.isSuccess) {
      updateStepperWithData()
    }
  }, [createMutation?.isSuccess])

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    updateMutation.reset()
    setCurrentSchema(createEducatorSchemas[stepper?.current?.currentStepIndex - 1])
    setInitValues(inits[stepper?.current?.currentStepIndex - 1])

    stepper.current.goPrev()
  }

  const submitStep = async (values: any, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    //CHECK IF ITS NEED TO UPDATE OR NOT
    if (editable) {
      const formDataValues: any = {}
      educatorKeysData[stepper?.current?.currentStepIndex - 1]['keys'].map((key: string) => {
        formDataValues[key] = values[key]
      })

      values._id
        ? await updateMutation.mutate({...formDataValues, _id: values._id})
        : await createMutation.mutate(formDataValues)
    } else {
      updateStepperWithData()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const gotoStep = (step: number) => {
    if (stepper?.current) {
      stepper?.current?.goto(step)
    }
    setInitValues(inits[stepper.current.currentStepIndex])
  }

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills  d-flex flex-row flex-xl-column flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <StepperAsideMenu steps={EducatorSteps} gotoStep={gotoStep} />

      <div className='d-flex flex-row-fluid bg-body rounded'>
        <Formik
          validationSchema={editable && currentSchema}
          enableReinitialize
          initialValues={{...initValues, ...educatorFormValues}}
          onSubmit={submitStep}
        >
          {({values, errors, setFieldValue}) => (
            <Form className='py-20 w-100 px-9' noValidate id='kt_create_account_form'>
              {(createMutation.isError || updateMutation?.isError) && (
                <div>
                  <div className='alert alert-danger'>
                    {Array.isArray(
                      createMutation?.error?.response?.data?.message ||
                        updateMutation?.error?.response?.data?.message
                    )
                      ? createMutation?.error?.response?.data?.message[0]?.split('.').pop() ||
                        updateMutation?.error?.response?.data?.message[0]?.split('.').pop() ||
                        ''
                      : createMutation?.error?.response?.data?.message ||
                        updateMutation?.error?.response?.data?.message}
                  </div>
                </div>
              )}
              <fieldset disabled={!editable}>
                <div className='current' data-kt-stepper-element='content'>
                  <ApplicationForm values={values} setFieldValue={setFieldValue} />
                </div>

                <div data-kt-stepper-element='content'>
                  <EducatorEmergencyContactForm
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />
                </div>

                <div data-kt-stepper-element='content'>
                  <EducatorQualificationForm values={values} setFieldValue={setFieldValue} />
                </div>

                <div data-kt-stepper-element='content'>
                  <EducatorTrainingForm values={values} setFieldValue={setFieldValue} />
                </div>

                <div data-kt-stepper-element='content'>
                  <EducatorSecurityForm values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <EducatorStatutoryDeclaration values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <EducatorMedicalCertificate values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <EducatorAgreementForm values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <EducatorComplianceForm values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <EducatorSelfAssessment values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <RiskAssessment
                    values={values}
                    setFieldValue={setFieldValue}
                    field={'routineExcursion'}
                    title={'routine excursion'}
                  />
                </div>
                <div data-kt-stepper-element='content'>
                  <RiskAssessment
                    values={values}
                    setFieldValue={setFieldValue}
                    field={'parkExcursion'}
                    title={'park excursion'}
                  />
                </div>
                <div data-kt-stepper-element='content'>
                  <RiskAssessment
                    values={values}
                    setFieldValue={setFieldValue}
                    field={'libraryExcursion'}
                    title={'library excursion'}
                  />
                </div>
                <div data-kt-stepper-element='content'>
                  <RiskAssessment
                    values={values}
                    setFieldValue={setFieldValue}
                    field={'schoolDropOffExcursion'}
                    title={'school drop off'}
                  />
                </div>
                <div data-kt-stepper-element='content'>
                  <RiskAssessment
                    values={values}
                    setFieldValue={setFieldValue}
                    field={'schoolPickUpExcursion'}
                    title={'school pick up'}
                  />
                </div>
                <div data-kt-stepper-element='content'>
                  <RiskAssessment
                    values={values}
                    setFieldValue={setFieldValue}
                    field={'shoppingExcursion'}
                    title={'shopping center'}
                  />
                </div>
                <div data-kt-stepper-element='content'>
                  <EducatorDeclaration setFieldValue={setFieldValue} field={''} values={values} />
                </div>
              </fieldset>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className={`btn btn-lg btn-light-primary me-3`}
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' className={`btn btn-lg btn-primary me-3 `}>
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber
                        ? 'Finish'
                        : stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber - 1 && 'Got It'}

                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

import React, {useEffect, useState} from 'react'
import DatePicker from './DatePicker'

export function TimeRangePicker({setFieldValue, value, field, ErrorMessage}: any) {
  const [startDate, setStartDate]: any = useState(null)
  const [endDate, setEndDate]: any = useState(null)

  useEffect(() => {
    if (value && value?.split('-').length > 2) {
      setFieldValue(field, '')
    } else if (value?.split('-').length === 2) {
      const firstTime = value.split('-')[0]
      const secondTime = value.split('-')[1]

      setStartDate(new Date(0, 0, 0, firstTime.split(':')[0], firstTime.split(':')[1]))
      setEndDate(new Date(0, 0, 0, secondTime.split(':')[0], secondTime.split(':')[1]))
    }
  }, [value])

  useEffect(() => {
    if (startDate && endDate) {
      setFieldValue(
        field,
        `${startDate.getHours()}:${startDate.getMinutes()}-${endDate.getHours()}:${startDate.getMinutes()}`
      )
    }
  }, [startDate, endDate])

  return (
    <div className='row'>
      <div className='col-6'>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText='Select start time'
          selectsStart
          startDate={startDate}
          endDate={endDate}
          showTimeSelect
          timeFormat='hh:mm aa'
          timeIntervals={15}
          showTimeSelectOnly
          timeCaption='Time'
          dateFormat='h:mm a'
          minTime={new Date(0, 0, 0, 7, 0)}
          maxTime={new Date(0, 0, 0, 19, 0)}
        />
        {!startDate && (
          <div className='text-danger'>
            <ErrorMessage name={field} />
          </div>
        )}
      </div>
      <div className='col-6'>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText='Select end time'
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          showTimeSelect
          timeFormat='hh:mm aa'
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption='Time'
          dateFormat='h:mm a'
          minTime={new Date(0, 0, 0, startDate?.getHours() || 7, startDate?.getMinutes() + 15 || 0)}
          maxTime={new Date(0, 0, 0, 19, 0)}
        />
        {!endDate && (
          <div className='text-danger'>
            <ErrorMessage name={field} />
          </div>
        )}
      </div>
    </div>
  )
}

import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'
import {buses, days} from '../../core/EducatorHelpers'
import Select from 'react-select'
import Collapsible from '../../../common/Collapsible'
interface ITransportationProps {
  value: any
  setFieldValue: any
  field: string
}
export const Transportation: FC<ITransportationProps> = ({value, setFieldValue, field}) => {
  return (
    <Collapsible title={`Transportation`} id={`risk-management-Transportation`}>
      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Means of transport</span>
          </label>

          <Select
            options={buses}
            placeholder='Select'
            name={`${field}.transportType`}
            className='multi-select-container mt-3'
            classNamePrefix='multi-select'
            onChange={(e: any) => {
              setFieldValue(`${field}.transportType`, e.value)
            }}
            value={buses.find((bus: {value: string}) => value?.days === bus.value)}
            isMulti={false}
            closeMenuOnSelect={true}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.transportType`} />
          </div>
        </div>
      </div>
      <div className='col-md-12 fv-row mb-5'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>
            Requirements for seatbelts or safety restraints in your state or territory have been met
          </span>
        </label>
        <span className='me-3'>
          <Field type='radio' className='me-2' name={`${field}.safetyRequirement`} value='Yes' />
          Yes
        </span>

        <span>
          <Field type='radio' className='me-2' name={`${field}.safetyRequirement`} value='No' />
          No
        </span>

        <div className='text-danger mt-2'>
          <ErrorMessage name={`${field}.safetyRequirement`} />
        </div>
      </div>

      {value?.safetyRequirement === 'Yes' && (
        <div className='row'>
          <div className='col-md-12 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Safety requirements</span>
            </label>
            <Field
              className='form-control form-control-solid mb-2'
              as='textarea'
              rows={4}
              placeholder='Please provide details'
              name={`${field}.safetyRequirementDetails`}
            ></Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.safetyRequirementDetails`} />
            </div>
          </div>
        </div>
      )}

      <div className='row'>
        <div className='col-md-12 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Proposed Activities</span>
          </label>
          <Field
            className='form-control form-control-solid mb-2'
            as='textarea'
            rows={4}
            placeholder='Proposed Activities'
            name={`${field}.proposedActivity`}
          ></Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.proposedActivity`} />
          </div>
        </div>
      </div>
    </Collapsible>
  )
}

import React, {FC, RefObject, useEffect, useRef} from 'react'
import {ErrorMessage, Field} from 'formik'
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from 'react-signature-canvas'

interface ISignatureProps {
  title: string
  field: string
  value: string
  setFieldValue: (field: string, value: any) => void
  disabled?: boolean
}
export const SignatureField: FC<ISignatureProps> = ({
  title,
  value,
  field: fieldName,
  setFieldValue,
  disabled = false,
}) => {
  const signature: RefObject<SignatureCanvas> = useRef(null)

  const disableCanvas = () => {
    const signatureCanvas = signature?.current?.getCanvas()

    // Disable pointer events on the canvas
    if (signatureCanvas) {
      signatureCanvas.style.pointerEvents = 'none'
    }
  }
  useEffect(() => {
    if (value) {
      signature?.current?.fromDataURL(value)
    }

    if (disabled) {
      disableCanvas()
    }
  }, [value])
  return (
    <>
      <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
        <span className='fw-bolder text-dark'>{title}</span>
      </label>
      <fieldset disabled={disabled}>
        <Field name={fieldName}>
          {({field}: any) => (
            <div className={'clearfix'}>
              <ReactSignatureCanvas
                ref={signature}
                canvasProps={{
                  width: 400,
                  height: 200,
                  className: 'sigCanvas border border-3 border-secondary rounded',
                }}
                {...field}
                onEnd={() => {
                  setFieldValue(fieldName, signature?.current?.toDataURL())
                }}
              />
              {!disabled && (
                <button
                  type='button'
                  onClick={() => {
                    signature?.current?.clear()
                    setFieldValue(fieldName, null)
                  }}
                  className='btn btn-sm btn-light-danger d-block'
                >
                  Clear
                </button>
              )}
            </div>
          )}
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name={fieldName} />
        </div>
      </fieldset>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'

type Props = {
  child: any
}

const ChildParentNameCell: FC<Props> = ({child}) => {
  const guardian = child?.guardian?.responsible
  console.log('Guard', guardian)

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='d-flex flex-column'>
        <span>{child?.[guardian]?.fullName || ''}</span>
      </div>
    </div>
  )
}

export {ChildParentNameCell}

import {ErrorMessage, Field, Form, Formik} from 'formik'
import {useReducer} from 'react'
import {initialSettings, settingsReducer, settingsValidation} from '../core/SettingsHelpers'
import {useQuery, useMutation} from 'react-query'
import {getSettings, updateSettings} from '../core/_request'
import {useEffect} from 'react'
import {SettingsLoading} from './SettingsLoading'

export const SettingsAddEdit = () => {
  const [settings, dispatch] = useReducer(settingsReducer, initialSettings)
  const {data, refetch, isLoading} = useQuery('getGeneralSettings', () => getSettings(), {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const {mutate, data: updated}: any = useMutation(updateSettings)

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    dispatch({type: 'SET_VALUES', payload: data?.[0]})
  }, [data])
  useEffect(() => {
    dispatch({type: 'SET_MESSAGE', payload: updated?.message})
  }, [updated])

  const submitValue = async (values: {adminEmail: string}) => {
    await mutate({id: data?.[0]?._id, ...values})

    setTimeout(() => {
      dispatch({type: 'SET_MESSAGE', payload: ''})
    }, 3000)
  }
  // if (isLoading) return <SettingsLoading />
  return (
    <Formik
      validationSchema={settingsValidation}
      enableReinitialize
      initialValues={settings}
      onSubmit={submitValue}
    >
      {({values, errors, setFieldValue}) => (
        <Form className='py-20 w-100' noValidate id='kt_create_account_form'>
          <div className='pb-10 pb-lg-15'>
            <div className='shadow-sm'>
              <div className='d-flex flex-column p-9'>
                <div className='fv-row'>
                  <div className='row'>
                    {settings.settingsUpdated && (
                      <div className='alert alert-success alert-dismissible fade show' role='alert'>
                        {settings.settingsUpdated}
                      </div>
                    )}

                    <div className='col-md-6 fv-row mb-5'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>Admin Email</span>
                      </label>

                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='adminEmail'
                        placeholder='example@gmail.com'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='adminEmail' />
                      </div>
                    </div>
                  </div>
                  <div className='text-end'>
                    <button type='submit' className='btn btn-lg btn-primary me-3 '>
                      <span className='indicator-label'>
                        Update<span className='svg-icon svg-icon-3 ms-2 me-0'></span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

import React from 'react'
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './date.css'

interface DatePickerProps extends ReactDatePickerProps {
  onChange: (date: Date | null) => void
}

const DatePicker: React.FC<DatePickerProps> = ({onChange, ...rest}) => {
  return (
    <div className='date-container'>
      <ReactDatePicker
        className='form-control form-control-lg form-control-solid'
        autoComplete='off'
        showMonthDropdown
        showYearDropdown
        adjustDateOnChange
        onChange={onChange}
        dateFormat='dd/MM/yyyy'
        {...rest}
      />
      <i className='date-icon fa fa-calendar' aria-hidden='true'></i>
    </div>
  )
}

export default DatePicker

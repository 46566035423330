import React, {FC} from 'react'
import {FieldArray} from 'formik'
import {SecurityFields} from '../SecurityFields'

const EducatorSecurityForm: FC<any> = ({values, setFieldValue}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Security details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          People who normally live at the family day care residence (adults and children)
        </div>
      </div>
      <FieldArray
        name='securityDetails.children'
        render={(arrayHelpers) => (
          <SecurityFields
            values={values?.securityDetails?.children}
            arrayHelpers={arrayHelpers}
            field='securityDetails.children'
            setFieldValue={setFieldValue}
          />
        )}
      />
      <div className='pb-10 pb-lg-15'>
        <div className='text-gray-400 fw-bold fs-6'>
          Above 18 residing Working with children check / working with vulnerable people / record of
          criminal history
        </div>
      </div>
      <FieldArray
        name='securityDetails.adults'
        render={(arrayHelpers) => (
          <SecurityFields
            values={values?.securityDetails?.adults}
            arrayHelpers={arrayHelpers}
            field='securityDetails.adults'
            setFieldValue={setFieldValue}
          />
        )}
      />
    </div>
  )
}

export {EducatorSecurityForm}

import {FC, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, Formik, FormikValues, useFormik} from 'formik'
import {initialUser, Role} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/RolesListLoading'
import {createUser, updateUser} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Form, Table} from 'react-bootstrap'

type Props = {
  isUserLoading: boolean
  role: Role
}

const roleSchema = Yup.object({
  name: Yup.string().required('Name is required'),
})

const permissions = [
  {
    _id: '6398822b70775d990ae1c5db',
    label: 'Users',
    subject: 'users',
    action: ['manage', 'create', 'read', 'update'],
    condition: '',
    order: 2,
  },
  {
    _id: '6398822b70775d990ae1c5fc',
    label: 'Educator',
    subject: 'educator',
    action: ['manage', 'create', 'read', 'update'],
    condition: '',
    order: 3,
  },
  {
    _id: '6398822b70775d990ae1c5dc',
    label: 'Parent',
    subject: 'parent',
    action: ['manage', 'create', 'read', 'update'],
    condition: '',
    order: 3,
  },
]

const UserEditModalForm: FC<Props> = ({role, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [userForEdit] = useState<Role>({
    _id: role._id,
    name: role.name || initialUser.name,
    permissions: role.permissions || initialUser.permissions,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const submit = async (values: any, actions: FormikValues) => {
    console.log('submit')
    actions.setSubmitting(true)
    try {
      if (values._id) {
        await updateUser(values)
      } else {
        await createUser(values)
      }
      cancel(true)
    } catch (ex: any) {
      actions.setStatus(ex?.response?.data.message)
    } finally {
      actions.setSubmitting(true)
    }
  }

  return (
    <Formik
      validationSchema={roleSchema}
      enableReinitialize
      initialValues={userForEdit}
      onSubmit={submit}
    >
      {({values, isValid, touched, isSubmitting, status, submitForm}) => (
        <form id='kt_modal_add_user_form' className='form' noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='400px'
          >
            {status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{status}</div>
              </div>
            )}

            <div className='row mb-7'>
              {/* begin::Input group */}

              <div className='col-md-12 fv-row mb-5'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>Role Name</span>
                </label>

                <Field
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='name'
                  placeholder='Role Name'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='name' />
                </div>
              </div>
            </div>

            {/* begin::Input group */}
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-5'>Role permissions</label>
              {/* end::Label */}
              <Table className='table-flush-spacing' responsive>
                <tbody>
                  {permissions?.map((permission, index) => {
                    return (
                      <tr key={index}>
                        <td className='text-nowrap fw-bolder'>{permission?.label}</td>
                        {permission?.action.map((action, key) => {
                          return (
                            <td key={key}>
                              <div className='d-flex'>
                                <Field
                                  type='checkbox'
                                  className='form-check-input mx-1'
                                  name={`permissions.${permission.subject}`}
                                  value={action}
                                />
                                <label className='form-check-label' htmlFor={action}>
                                  {action.charAt(0).toUpperCase() + action.slice(1)}
                                </label>
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={isSubmitting || isUserLoading}
            >
              Cancel
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={(e) => {
                e.preventDefault()
                // Manually submit the form
                submitForm()
              }}
              disabled={isUserLoading || isSubmitting || !isValid || !touched}
            >
              <span className='indicator-label'>{userForEdit._id ? 'Update' : 'Create'}</span>
              {(isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </form>
      )}
    </Formik>
  )
}

export {UserEditModalForm}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
// import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import {updateEducatorStatus} from '../core/_request'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import {ChildrenView} from './ChildrenView'
import {EducatorProfileForm} from './EducatorProfileForm'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'

export const EducatorEditForm = () => {
  const {id} = useParams()
  const queryClient = useQueryClient()
  const [hide, setHide] = useState(false)
  const [educatorData, setEducatorData]: any = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalDescription, setModalDescription] = useState('')
  const [status, setStatus] = useState('')

  const updateStatus: any = useMutation(updateEducatorStatus, {
    onSuccess: () => {
      // Refetch the user list after the mutation has completed
      queryClient.refetchQueries(['educatorData', id])
    },
  })
  const [tab, setTab] = useState('Profile')

  const showHideModal = () => setShowModal(!showModal)

  const educatorStatusUpdate = () => {
    updateStatus.mutate({id, status})
    setHide(true)
    showHideModal()
  }

  return (
    <>
      <Modal show={showModal} onHide={showHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalDescription}</Modal.Body>
        <Modal.Footer>
          <button className=' btn btn-primary' onClick={educatorStatusUpdate}>
            Yes
          </button>
          <button className=' btn btn-secondary' onClick={showHideModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <div className='row d-flex justify-content-center align-items-center h-100'>
        <div className='card card-custom gutter-b'>
          <div className='card-body'>
            <div className='d-flex text-black'>
              <div className='flex-shrink-0'>
                <div className='img-fluid'>
                  <img
                    src={toAbsoluteUrl('/media/avatars/blank.png')}
                    alt=''
                    style={{width: '100px', borderRadius: '10px'}}
                  />
                </div>
              </div>
              <div className='flex-grow-1 ms-3'>
                <h5 className='mb-1'>{educatorData?.basicInformation?.fullName}</h5>
                <p className='mb-1 pb-1 '>
                  <a
                    className='text-decoration-underline'
                    href={`mailto:${educatorData?.basicInformation?.email}`}
                  >
                    {educatorData?.basicInformation?.email}
                  </a>
                </p>
                <p className='mb-2 pb-1'>{educatorData?.basicInformation?.contactPhone?.[0]}</p>
              </div>
              {educatorData?.status === 'Pending' && (
                <div className={`${hide ? 'd-none' : ''}`}>
                  <button
                    className='btn btn-success me-4 '
                    onClick={() => {
                      setStatus('Approved')
                      setModalDescription('Do you want to approve this educator?')
                      setShowModal(true)
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className='btn btn-danger '
                    onClick={() => {
                      setStatus('Rejected')
                      setModalDescription('Do you want to reject this educator?')
                      setShowModal(true)
                    }}
                  >
                    Reject
                  </button>
                </div>
              )}
              {educatorData?.status === 'Approved' && (
                <div className={`${hide ? 'd-none' : ''}`}>
                  <button
                    className='btn btn-danger float-end'
                    onClick={() => {
                      // updateStatus.mutate({id, status: 'Ceased'})
                      setStatus('Ceased')
                      setModalDescription('Do you want to stop this educator service?')
                      setShowModal(true)
                    }}
                  >
                    Stop Educator Services
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='card-header card-header-stretch overflow-auto'>
        <ul
          className='nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          '
          role='tablist'
        >
          <li className='nav-item'>
            <a
              className={clsx(`nav-link cursor-pointer`, {active: tab === 'Profile'})}
              onClick={() => setTab('Profile')}
              role='tab'
            >
              Profile
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={clsx(`nav-link cursor-pointer`, {active: tab === 'Children'})}
              onClick={() => setTab('Children')}
              role='tab'
            >
              Children
            </a>
          </li>
        </ul>
      </div>
      {tab === 'Profile' && <EducatorProfileForm updateEducatorData={setEducatorData} />}
      {tab === 'Children' && <ChildrenView />}
    </>
  )
}

import axios from 'axios'

import {SettingsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const SETTINGS_URL = `${API_URL}/settings`

const updateSettings = (updateData: {id: string; adminEmail: string}) => {
  return axios
    .patch(`${SETTINGS_URL}/${updateData.id}`, updateData)
    .then((response: SettingsQueryResponse) => response.data)
}

const getSettings = () => {
  return axios.get(`${SETTINGS_URL}`).then((response: SettingsQueryResponse) => response.data)
}

export {getSettings, updateSettings}

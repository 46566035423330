import React from 'react'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ChildrenListWrapper} from './children-list/ChildrenList'
import {ChildEdit} from './child-view/ChildEdit'

const childrenBreadcrumbs: Array<PageLink> = [
  {
    title: 'Children',
    path: '/apps/child-management/children',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Child',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ChildrenPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='children/:id/view'
          element={
            <>
              <PageTitle breadcrumbs={childrenBreadcrumbs}>Parent and Children</PageTitle>
              <ChildEdit />
            </>
          }
        />

        <Route
          path='children/:id/edit'
          element={
            <>
              <PageTitle breadcrumbs={childrenBreadcrumbs}>Child Edit</PageTitle>
              <ChildEdit />
            </>
          }
        />

        <Route
          path='children'
          element={
            <>
              <PageTitle breadcrumbs={childrenBreadcrumbs}>Parent and Children</PageTitle>
              <ChildrenListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/child-management/children' />} />
    </Routes>
  )
}

export default ChildrenPage

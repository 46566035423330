/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {User} from '../../core/_models'

type Props = {
  user: User
}
const statusClass: any = {
  Active: 'badge-light-success',
  Inactive: 'badge-light-danger',
  Pending: 'badge-light-info',
}
const UserStatus: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span className={`badge ${statusClass[user?.status || 'Pending']}`}>{user?.status}</span>
    </div>
  </div>
)

export {UserStatus}

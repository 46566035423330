import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../_metronic/helpers'
import {ApplicationForm, ICreateEducator} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CHILD_URL = `${API_URL}/child`
const ADMIN_CHILD_URL = `${API_URL}/admin/child`

const EDUCATORS_URL = `${API_URL}/admin/educators`

const createChild = (formInfo: ICreateEducator): Promise<ICreateEducator | undefined> => {
  return axios
    .post(`${CHILD_URL}`, formInfo)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const updateChild = (formInfo: ICreateEducator): Promise<ICreateEducator | undefined> => {
  return axios
    .patch(`${CHILD_URL}/${formInfo._id}`, formInfo.values)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const updateChildAssignee = (id: string, formInfo: any): Promise<ICreateEducator | undefined> => {
  return axios
    .patch(`${ADMIN_CHILD_URL}/${id}/assign-educator`, formInfo)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const updateChildStatus = (formIfo: {
  id: string
  status: string
}): Promise<ICreateEducator | undefined> => {
  return axios
    .patch(`${ADMIN_CHILD_URL}/${formIfo.id}/status-update`, {status: formIfo.status})
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const getChildByID = (id: string): Promise<ICreateEducator | undefined> => {
  return axios
    .get(`${ADMIN_CHILD_URL}/${id}`)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const getEducators = (): Promise<ICreateEducator | undefined> => {
  return axios
    .get(`${EDUCATORS_URL}/list-approved`)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const getChildForm = (): Promise<ICreateEducator | undefined> => {
  return axios
    .get(`${CHILD_URL}/get-educator-form`)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

export {
  createChild,
  getChildByID,
  updateChild,
  getEducators,
  updateChildStatus,
  getChildForm,
  updateChildAssignee,
}

import React, {FC, useRef, useState} from 'react'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

interface Step {
  title: string
  description: string
  stepValue: string
}

interface Props {
  steps: Step[]
  gotoStep: (step: number) => void
}

export const StepperAsideMenu: FC<Props> = ({steps, gotoStep}) => {
  const xScroll: any = useRef<StepperComponent | null>(null)
  const [editable] = useState(window.location.pathname.includes('edit'))

  const scrollToBottom = () => {
    const educator = window.location.pathname.includes('educator')
    if (educator) {
      window.scrollTo({
        top: 400,
        left: 0,
        behavior: 'smooth',
      })
    } else {
      window.scrollTo({
        top: 800,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const gotoPrv = (scrollOffset: number) => {
    xScroll.current.scrollTo({
      top: 0,
      left: xScroll.current.scrollLeft - scrollOffset,
      behavior: 'smooth',
    })
  }

  const gotoNext = (scrollOffset: number) => {
    xScroll.current.scrollTo({
      top: 0,
      left: xScroll.current.scrollLeft + scrollOffset,
      behavior: 'smooth',
    })
  }

  return (
    <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100  me-9'>
      {/* begin::Wrapper*/}
      <div className='card-body border-bottom d-flex align-items-center'>
        {/* begin::Nav*/}
        <i
          onClick={() => {
            if (!editable) {
              // gotoStep(2 + 1)
              // gotoPrvStep()
              gotoPrv(80)
            }
          }}
          className='bi bi-arrow-left pe-5 cursor-pointer'
          style={{fontSize: '2.5rem'}}
        ></i>
        <div className='stepper-nav hover-scroll-x ' ref={xScroll}>
          {steps.map((step: Step, index: number) => {
            return (
              <div
                className={`stepper-item mx-8 my-4 cursor-pointer ${index === 0 && 'current'}`}
                data-kt-stepper-element='nav'
                key={index}
                onClick={() => {
                  if (!editable) {
                    gotoStep(index + 1)
                  }
                }}
              >
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper d-flex align-items-center'>
                  {/* begin::Icon*/}

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>{step.stepValue}</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 onClick={scrollToBottom} className='stepper-title'>
                      {step?.title}
                    </h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
            )
          })}
        </div>
        {/* end::Nav*/}
        <i
          onClick={() => {
            gotoNext(80)
          }}
          className='bi bi-arrow-right p-5 cursor-pointer'
          style={{fontSize: '2.5rem'}}
        ></i>
      </div>
      {/* end::Wrapper*/}
    </div>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {FC, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {generateEducatorPDFByID} from '../../core/_requests'

type Props = {
  id: string
  status: string
}

const EducatorActionsCell: FC<Props> = ({id, status}) => {
  const navigate = useNavigate()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const {data, refetch} = useQuery(['generatePDF', id], () => generateEducatorPDFByID(id), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
  const generatePDF = (id: string) => {
    refetch()
  }

  return (
    <>
      <a
        href='#'
        className={`btn btn-light btn-active-light-primary btn-sm ${
          status === 'InProgress' ? 'd-none' : ''
        }`}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={(e) => {
              e.preventDefault()
              navigate(`${id}/view`)
            }}
          >
            View
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={(e) => {
              e.preventDefault()
              navigate(`${id}/edit`)
            }}
          >
            Edit
          </a>
        </div>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={(e) => {
              generatePDF(id)
            }}
          >
            Download PDF
          </a>
        </div>

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {EducatorActionsCell}

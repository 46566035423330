import React, {FC, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Field, ErrorMessage, FieldArray} from 'formik'
import Select from 'react-select'
import {ContactPhoneField} from '../ContactPhoneField'
import {days} from '../../core/EducatorHelpers'
import {TimeRangePicker} from '../../../common/TimeRangePicker'
import {AddressDetailInfo} from '../../../common/AddressDetailInfo'
import DatePicker from '../../../common/DatePicker'

const EducatorEmergencyContactForm: FC<any> = ({values, setFieldValue, errors}) => {
  const [timeRange, setTimeRange] = useState({
    startTime: new Date(),
    endTime: new Date(),
  })

  const CustomInput = ({value, onClick}: any) => (
    <input type='text' value={value} onClick={onClick} readOnly />
  )
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Educator emergency contact details
        </h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Full Name</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='emergencyContact.fullName'
              placeholder='Full Name'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='emergencyContact.fullName' />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Email Address</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='emergencyContact.email'
              placeholder='Email'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='emergencyContact.email' />
            </div>
          </div>
        </div>
      </div>

      <AddressDetailInfo
        values={values}
        setFieldValue={setFieldValue}
        field={'emergencyContact.residentialAddress'}
        title={'Postal Address'}
      />
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Contact Phone</span>
        </label>

        <FieldArray
          name='emergencyContact.contactPhone'
          render={(arrayHelpers) => (
            <ContactPhoneField
              values={values?.emergencyContact?.contactPhone || ['']}
              arrayHelpers={arrayHelpers}
              field='emergencyContact.contactPhone'
            />
          )}
        />
      </div>
      <div className='row fv-row  mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>
            The relationship person has to the family day care educator is
          </span>
        </label>
        <Field
          as='select'
          name='emergencyContact.relationship'
          className='form-select form-select-solid'
        >
          <option>Select Relation</option>
          <option value='brother'>Brother</option>
          <option value='sister'>Sister</option>
          <option value='friend'>Friend</option>
          <option value='other'>Other</option>
        </Field>

        <div className='text-danger mt-2'>
          <ErrorMessage name='emergencyContact.relationship' />
        </div>
      </div>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Operations details</h2>
      </div>
      <div className='fv-row d-none'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Date registered with the service</span>
            </label>

            <DatePicker
              name='operationDetails.dateRegistered'
              selected={
                (values?.operationDetails?.dateRegistered &&
                  new Date(values?.operationDetails?.dateRegistered)) ||
                null
              }
              placeholderText='Date of registration'
              onChange={(value: any) => setFieldValue('operationDetails.dateRegistered', value)}
            />

            <div className='text-danger mt-2'>
              <ErrorMessage name='operationDetails.dateRegistered' />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Date registration ceased</span>
            </label>
            <DatePicker
              name='operationDetails.dateRegistrationCeased'
              selected={
                (values?.operationDetails?.dateRegistrationCeased &&
                  new Date(values?.operationDetails?.dateRegistrationCeased)) ||
                null
              }
              placeholderText='Date of registration ceased'
              onChange={(value: any) =>
                setFieldValue('operationDetails.dateRegistrationCeased', value)
              }
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='operationDetails.dateRegistrationCeased' />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>
                Days and hours educator is available to provide education and care
              </span>
            </label>

            <Select
              options={days}
              placeholder='Select'
              name='operationDetails.workingDays'
              className='multi-select-container mt-3'
              classNamePrefix='multi-select'
              onChange={(e: any) => {
                setFieldValue(
                  'operationDetails.workingDays',
                  e.map((day: any) => day.value)
                )
              }}
              value={days.filter((day: any) =>
                values?.operationDetails?.workingDays?.includes(day.value)
              )}
              closeMenuOnSelect={false}
              isMulti
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='operationDetails.workingDays' />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2 invisible'>
              <span className='required'>
                {' '}
                Days and hours educator is available to provide education and care
              </span>
            </label>
            <TimeRangePicker
              setFieldValue={setFieldValue}
              field={'operationDetails.workingTime'}
              value={values?.operationDetails?.workingTime}
              ErrorMessage={ErrorMessage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {EducatorEmergencyContactForm}

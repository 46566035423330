// @ts-nocheck
import {Column} from 'react-table'
import {EducatorActionsCell} from './EducatorActionsCell'
import {EducatorNameCell} from './EducatorNameCell'
import {EducatorStatusCell} from './EducatorStatusCell'
import {EducatorCustomHeader} from './EducatorCustomHeader'
import {ChildNameCell} from './ChildNameCell'
import {EducatorAddress} from './EducatorAddressCell'
import {UserJoinedDate} from './UserJoinedCell'
import {User} from '../../core/_models'
import {ChildParentNameCell} from './ChildParentNameCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <EducatorSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <EducatorSelectionCell id={props.data[props.row.index]._id} />,
  // },
  {
    Header: (props) => (
      <EducatorCustomHeader tableProps={props} title='Child Name' className='min-w-125px' />
    ),
    id: 'child.fullName',
    Cell: ({...props}) => <ChildNameCell child={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <EducatorCustomHeader tableProps={props} title='Parent' className='min-w-125px' />
    ),
    id: 'father.fullName',
    Cell: ({...props}) => <ChildParentNameCell child={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EducatorCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    accessor: 'email',
  },

  {
    Header: (props) => (
      <EducatorCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    id: 'child.address.streetAddress',
    Cell: ({...props}) => <EducatorAddress educator={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EducatorCustomHeader tableProps={props} title='Region' className='min-w-125px' />
    ),
    accessor: 'child.address.region',
  },
  // {
  //   Header: (props) => (
  //     <EducatorCustomHeader tableProps={props} title='Status' className='min-w-125px' />
  //   ),
  //   accessor: 'status',
  // },

  {
    Header: (props) => (
      <EducatorCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <EducatorStatusCell educator={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <EducatorCustomHeader tableProps={props} title='Educator' className='min-w-125px' />
    ),
    id: 'educator',
    Cell: ({...props}) => <EducatorNameCell child={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EducatorCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    id: 'createdAt',
    Cell: ({...props}) => <UserJoinedDate user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <EducatorCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <EducatorActionsCell
        id={props.data[props.row.index]._id}
        status={props.data[props.row.index].status}
      />
    ),
  },
]

export {usersColumns}

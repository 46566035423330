import React, {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {pdfjs, Document, Page} from 'react-pdf'
import UploadFile from '../../../common/UploadFile'
import {selfAssessmentForm} from '../../core/EducatorHelpers'
import Collapsible from '../../../common/Collapsible'
import {ErrorMessage, FastField} from 'formik'
import {VehiclesBrands} from '../self-accessment-partials/VehiclesBrands'
import {AddressDetailInfo} from '../../../common/AddressDetailInfo'
import DatePicker from '../../../common/DatePicker'
import {SignatureField} from '../../../common/SignatureField'

interface Props {
  values: any
  setFieldValue: any
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const EducatorSelfAssessment: FC<Props> = ({values, setFieldValue}) => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Educator Self Assessment</h2>
      </div>

      {selfAssessmentForm.map((item, index) => {
        return (
          <Collapsible
            key={index}
            title={`${item.heading}`}
            id={`self-assessment-info-${item?.id}`}
          >
            {item.fields.map((field, index) => {
              return (
                <React.Fragment key={index}>
                  {field?.field === 'laundryRoom.safetyMeasures' &&
                  values?.laundryRoom?.laundryUsedForFDC !== 'Yes' ? (
                    ''
                  ) : (
                    <div className='row'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span
                          className={` ${
                            field.fieldType !== 'checkbox' &&
                            field.field !== 'educatorSelfAssessment.pets'
                              ? 'required'
                              : ''
                          }`}
                        >
                          {field.label}
                        </span>
                      </label>
                      {field.fieldType === 'text' ? (
                        <>
                          <FastField
                            type='text'
                            className='form-control form-control-solid mb-2'
                            name={field.field}
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={field.field} />
                          </div>
                        </>
                      ) : field.fieldType === 'textarea' ? (
                        <>
                          <FastField
                            className='form-control form-control-solid mb-2'
                            as='textarea'
                            rows={4}
                            placeholder='Please provide the details'
                            name={field.field}
                          ></FastField>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={field.field} />
                          </div>
                        </>
                      ) : field.fieldType === 'checkbox' ? (
                        <FastField type={field.fieldType} name={field.field} />
                      ) : (
                        <div className='row'>
                          <div className='col-4'>
                            <label
                              className='align-items-center fs-5  m-2'
                              htmlFor={`educator-assessment-yes-${field.field}`}
                            >
                              <FastField
                                type={field.fieldType}
                                className='me-2'
                                id={`educator-assessment-yes-${field.field}`}
                                name={field.field}
                                value={'Yes'}
                              />
                              Yes
                            </label>
                          </div>

                          <div className='col-4'>
                            <label
                              className='align-items-center fs-5  m-2'
                              htmlFor={`educator-assessment-no-${field.field}`}
                            >
                              <FastField
                                type={field.fieldType}
                                className='me-2'
                                id={`educator-assessment-no-${field.field}`}
                                name={field.field}
                                value={'No'}
                              />
                              No
                            </label>
                          </div>

                          <div className='col-4'>
                            <label
                              className='align-items-center fs-5  m-2'
                              htmlFor={`educator-assessment-na-${field.field}`}
                            >
                              <FastField
                                type={field.fieldType}
                                className='me-2'
                                id={`educator-assessment-na-${field.field}`}
                                name={field.field}
                                value={'N/A'}
                              />
                              N/A
                            </label>
                          </div>

                          <div className={`text-danger mt-2 `}>
                            <ErrorMessage name={field.field} />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              )
            })}

            {index === 20 && <VehiclesBrands values={values} setFieldValue={setFieldValue} />}
          </Collapsible>
        )
      })}
      <fieldset disabled>
        <div className='row px-4'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Educator Name:</span>
          </label>
          <FastField
            type='text'
            placeholder='Educator Name'
            className='form-control form-control-md form-control-solid'
            name='basicInformation.fullName'
          />
          <div className={`text-danger mt-2 `}>
            <ErrorMessage name={'educatorName'} />
          </div>
        </div>

        <AddressDetailInfo
          values={values}
          setFieldValue={setFieldValue}
          field='basicInformation.dayCareAddress'
        />
      </fieldset>

      <div className='row'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Date:</span>
        </label>
        <DatePicker
          disabled
          name={`selfComplianceSubmittedDate`}
          selected={
            (values['selfComplianceSubmittedDate'] &&
              new Date(values['selfComplianceSubmittedDate'])) ||
            new Date()
          }
          placeholderText='Date'
          onChange={(value: any) => setFieldValue(`selfComplianceSubmittedDate`, value)}
        />
      </div>
      <div className='row float-end mt-4'>
        <div className='col-12'>
          <SignatureField
            title='Signed by Educator:'
            field='signature'
            value={values?.signature}
            setFieldValue={setFieldValue}
            disabled={true}
          />
        </div>
      </div>
    </div>
  )
}

export {EducatorSelfAssessment}

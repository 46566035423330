import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Field, ErrorMessage, FieldArray} from 'formik'
import {QualificationCard} from '../QualificationCard'

const EducatorTrainingForm: FC<any> = ({values, setFieldValue}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Mandatory Training</h2>
      </div>

      <FieldArray
        name='trainingsCompleted'
        render={(arrayHelpers) => (
          <QualificationCard
            values={values?.trainingsCompleted}
            arrayHelpers={arrayHelpers}
            field='trainingsCompleted'
            setFieldValue={setFieldValue}
          />
        )}
      />
    </div>
  )
}

export {EducatorTrainingForm}

import React, {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {pdfjs, Document, Page} from 'react-pdf'
import {ErrorMessage, Field} from 'formik'
import {SignatureField} from '../../../common/SignatureField'

interface Props {
  values: any
  setFieldValue: any
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const EducatorComplianceForm: FC<Props> = ({values, setFieldValue}) => {
  const [numPages, setNumPages] = useState(null)
  function onDocumentLoadSuccess({numPages}: any) {
    setNumPages(numPages)
  }
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>
          Compliance history statement for a prospective family day care educator
        </h2>
        <p>
          It is not mandatory to use this form. This form may assist when determining a person’s
          suitability to be a family day care educator. Completed forms should be retained and
          stored.
        </p>
      </div>
      <div className='row fv-row  mb-10'>
        <label className='d-flex fs-5 fw-bold ps-0 mb-2'>
          <span>
            1. Please provide information about any compliance action or disciplinary proceedings to
            which you have been subject under:
          </span>
        </label>
        <span className='ps-0'>
          The Education and Care Services National Law, including the Education and Care Services
          National Regulations, and any of the laws listed at Table 1 below, in any Australian state
          or territory.
        </span>

        <Field
          className='form-control form-control-solid mb-2'
          as='textarea'
          rows={4}
          placeholder='Please provide the details'
          name='compliance.complianceAction'
        ></Field>
      </div>
      <div className='row fv-row  mb-10'>
        <label className='d-flex fs-5 fw-bold ps-0 mb-2'>
          <span>
            2. Are you or have you ever been subject to a prohibition or suspension notice under the
            Education and Care Services National Law?
          </span>
        </label>
        <div className='row'>
          <span className='align-items-center fs-5  m-2 col-4 '>
            <Field type='radio' className='me-2' name='compliance.anyProhibition' value='yes' />
            Yes
          </span>
          <span className='align-items-center fs-5  m-2 col-4 '>
            <Field type='radio' className='me-2' name='compliance.anyProhibition' value='no' />
            No
          </span>
        </div>
        <span className='ps-0'>Please provide details below</span>

        <Field
          className='form-control form-control-solid mb-2'
          as='textarea'
          rows={4}
          placeholder='Please provide the details'
          name='compliance.prohibitionDetail'
        ></Field>
      </div>
      <div className='row fv-row  mb-10'>
        <label className='d-flex fs-5 fw-bold ps-0 mb-2'>
          <span>
            3. Have you ever held or applied for a license, approval, registration, certification or
            other authorisation under the National Law which the regulatory authority refused,
            refused to renew, suspended or cancelled (for example as a nominated supervisor, a
            person in day to day charge, a person with management or control)?
          </span>
        </label>
        <div className='row'>
          <span className='align-items-center fs-5  m-2 col-4 '>
            <Field type='radio' className='me-2' name='compliance.anySuspension' value='yes' />
            Yes
          </span>
          <span className='align-items-center fs-5  m-2 col-4 '>
            <Field type='radio' className='me-2' name='compliance.anySuspension' value='no' />
            No
          </span>
        </div>
        <span className='ps-0'>Please provide details below</span>

        <Field
          className='form-control form-control-solid mb-2'
          as='textarea'
          rows={4}
          placeholder='Please provide the details'
          name='compliance.suspensionDetail'
        ></Field>
      </div>
      <div className='row fv-row  mb-10'>
        <label className='d-flex fs-5 fw-bold ps-0 mb-2'>
          <span>
            4. Were you formerly registered with another approved provider? If so, please list
            service details and the reason(s) you left your previous role.
          </span>
        </label>
        <div className='row'>
          <span className='align-items-center fs-5  m-2 col-4 '>
            <Field
              type='radio'
              className='me-2'
              name='compliance.anotherApprovedProvider'
              value='yes'
            />
            Yes
          </span>
          <span className='align-items-center fs-5  m-2 col-4 '>
            <Field
              type='radio'
              className='me-2'
              name='compliance.anotherApprovedProvider'
              value='no'
            />
            No
          </span>
        </div>
        <span className='ps-0'>Please provide details below</span>

        <Field
          className='form-control form-control-solid mb-2'
          as='textarea'
          rows={4}
          placeholder='Please provide the details'
          name='compliance.anotherApprovedProviderDetail'
        ></Field>
      </div>
      <div className='all-page-container d-flex justify-content-center'>
        <Document
          file='/EducatorComplianceForm.pdf'
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
      <div className='row float-end'>
        <div className='col-12'>
          <SignatureField
            title='Signed by Educator:'
            field='signature'
            value={values?.signature}
            setFieldValue={setFieldValue}
            disabled={true}
          />
        </div>
      </div>
    </div>
  )
}

export {EducatorComplianceForm}

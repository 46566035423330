// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {UserJoinedDate} from './UserJoinedCell'
import {User} from '../../core/_models'
import {RoleStatus} from './RoleStatusCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='name' className='min-w-125px' />,
    accessor: 'name',
  },
  ,
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <RoleStatus role={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Created Date' className='min-w-125px' />
    ),
    id: 'createdAt',
    Cell: ({...props}) => <UserJoinedDate user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell id={props.data[props.row.index]._id} role={props.data[props.row.index]} />
    ),
  },
]

export {usersColumns}

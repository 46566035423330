import React from 'react'
import {useListView} from '../../core/ListViewProvider'
import {ChildrenListFilter} from './ChildrenListFilter'

const ChildrenListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ChildrenListFilter />
    </div>
  )
}

export {ChildrenListToolbar}

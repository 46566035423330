import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {SettingsAddEdit} from './components/SettingsAddEdit'

const educatorsBreadcrumbs: Array<PageLink> = [
  {
    title: 'General Settings',
    path: '/apps/settings',
    isSeparator: false,
    isActive: false,
  },
]

const SettingsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/settings'
          element={
            <>
              <PageTitle breadcrumbs={educatorsBreadcrumbs}>Settings</PageTitle>
              <SettingsAddEdit />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps' />} />
    </Routes>
  )
}

export default SettingsPage

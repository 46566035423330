import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'
import Collapsible from '../../../common/Collapsible'

interface Props {
  value: any
  setFieldValue: any
  field: string
}
export const SafetyMeasures: FC<Props> = ({value, setFieldValue, field}) => {
  return (
    <Collapsible title={`Safety Measures`} id={`risk-management-safety-measures`}>
      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>
              Any water hazards on proposed route travelled and at each stop?
            </span>
          </label>
          <span className='me-3'>
            <Field type='radio' className='me-2' name={`${field}.anyWaterHazard`} value='Yes' />
            Yes
          </span>

          <span>
            <Field type='radio' className='me-2' name={`${field}.anyWaterHazard`} value='No' />
            No
          </span>

          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.anyWaterHazard`} />
          </div>
        </div>
        {value?.anyWaterHazard === 'Yes' && (
          <div className='col-12'>
            <Field
              className='form-control form-control-solid mb-2'
              as='textarea'
              rows={4}
              placeholder='Please provide the details'
              name={`${field}.waterHazardDetails`}
            ></Field>
          </div>
        )}
      </div>

      <div className='row'>
        <div className='col-md-12 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>
              Describe the process for entering and exiting the service premises and the pick-up
              location or destination (as required); (include how each child is accounted for)
            </span>
          </label>
          <Field
            className='form-control form-control-solid mb-2'
            as='textarea'
            rows={4}
            placeholder='Please provide details'
            name={`${field}.enteringAndExitingProcess`}
          ></Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.enteringAndExitingProcess`} />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>
              Describe the procedures for embarking and disembarking the vehicle; (include how each
              child is accounted for in embarking and disembarking)
            </span>
          </label>
          <Field
            className='form-control form-control-solid mb-2'
            as='textarea'
            rows={4}
            placeholder='Please provide details'
            name={`${field}.embarkingAndDisembarkingProcess`}
          ></Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.embarkingAndDisembarkingProcess`} />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Excursion Check list</span>
          </label>
          <span className='d-block'>
            <Field
              className='me-2'
              type='checkbox'
              value='First aid kit'
              placeholder='Please provide details'
              name={`${field}.excursionChecklist`}
            ></Field>
            First aid kit
          </span>

          <span className='d-block'>
            <Field
              className='me-2'
              type='checkbox'
              value='List of adults involved in transportation'
              name={`${field}.excursionChecklist`}
            ></Field>
            List of adults involved in transportation
          </span>

          <span className='d-block'>
            <Field
              className='me-2'
              type='checkbox'
              value='List of children involved'
              name={`${field}.excursionChecklist`}
            ></Field>
            List of children involved
          </span>

          <span className='d-block'>
            <Field
              className='me-2'
              type='checkbox'
              value='Contact information for each adult'
              name={`${field}.excursionChecklist`}
            ></Field>
            Contact information for each adult
          </span>

          <span className='d-block'>
            <Field
              className='me-2'
              type='checkbox'
              value='Emergency Contact sheet'
              name={`${field}.excursionChecklist`}
            ></Field>
            Emergency Contact sheet
          </span>

          <span className='d-block'>
            <Field
              className='me-2'
              value='Mobile phone / other means of communicating with the service & emergency services'
              type='checkbox'
              name={`${field}.excursionChecklist`}
            ></Field>
            Mobile phone / other means of communicating with the service & emergency services
          </span>

          <span className='d-block'>
            <Field
              className='me-2'
              type='checkbox'
              value='Other items, evacuation bag, water bottles, sunscreen, sanitizer, change of clothes,
            nappies/toileting bag'
              name={`${field}.excursionChecklist`}
            ></Field>
            Other items, evacuation bag, water bottles, sunscreen, sanitizer, change of clothes,
            nappies/toileting bag
          </span>

          <div className='text-danger mt-2'>
            <ErrorMessage name={`${field}.excursionChecklist`} />
          </div>
        </div>
      </div>
    </Collapsible>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'

type Props = {
  child: any
}

const EducatorNameCell: FC<Props> = ({child}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <span>{child?.educator?.basicInformation?.fullName || 'N/A'}</span>
    </div>
  </div>
)

export {EducatorNameCell}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ErrorMessage, Field} from 'formik'
import './card.css'
import {certifications, mandatoryTraining} from '../core/EducatorHelpers'
import UploadFile from '../../common/UploadFile'
import DatePicker from '../../common/DatePicker'

type Props = {
  values: any
  arrayHelpers: any
  field: string
  setFieldValue: any
}

const QualificationCard: FC<Props> = ({values, arrayHelpers, field, setFieldValue}) => {
  return (
    <>
      <table className='table table-responsive '>
        <tbody>
          <tr>
            <td data-label='Name'>
              <div className='fv-row mb-10'>
                <div className='row'>
                  {values?.map((value: any, index: any) => (
                    <div className='col-md-6 col-lg-4 fv-row mb-5' key={index}>
                      <div className='card shadow-sm card-size'>
                        <div className='card-body d-flex flex-column p-9'>
                          <button
                            type='button'
                            className={`btn-close d-flex align-self-end ${
                              (!(values.length > 1) || !field.includes('InProgress')) &&
                              field.includes('trainings')
                                ? 'd-none'
                                : ''
                            }`}
                            onClick={() => arrayHelpers.remove(index)}
                            aria-label='Close'
                            disabled={
                              (!(values.length > 1) || !field.includes('InProgress')) &&
                              field.includes('trainings')
                            }
                          ></button>

                          <h4 className='card-title text-center mb-8'>Certification {index + 1}</h4>
                          <label className='d-flex fs-5 fw-bold mb-2'>
                            <span className='required'>Select Certificate / Diploma</span>
                          </label>
                          <Field
                            as='select'
                            name={`${field}[${index}]certificate`}
                            className='form-select form-select-solid'
                            disabled={field.includes('trainings')}
                          >
                            <option>Select Certificate / Diploma</option>
                            {!field.includes('trainings') &&
                              certifications.map((certificate: any) => (
                                <option value={certificate.value}>{certificate.label}</option>
                              ))}
                            {field.includes('trainings') &&
                              mandatoryTraining.map((certificate: any) => (
                                <option value={certificate.value}>{certificate.label}</option>
                              ))}
                          </Field>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={`${field}[${index}]certificate`} />
                          </div>

                          <label className='d-flex fs-5 fw-bold mb-2'>
                            <span className='required'>Completion Date</span>
                          </label>

                          <DatePicker
                            name={`${field}[${index}]completionDate`}
                            selected={
                              (value['completionDate'] && new Date(value['completionDate'])) || null
                            }
                            placeholderText='Completion Date'
                            onChange={(value: any) =>
                              setFieldValue(`${field}[${index}]completionDate`, value)
                            }
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={`${field}[${index}]completionDate`} />
                          </div>

                          {value?.hasOwnProperty('expirationDate') && (
                            <>
                              <label className='d-flex fs-5 fw-bold mb-2'>
                                <span className='required'>Expiration Date</span>
                              </label>

                              <DatePicker
                                className='form-control form-control-lg form-control-solid'
                                name={`${field}[${index}]expirationDate`}
                                selected={
                                  (value['expirationDate'] && new Date(value['expirationDate'])) ||
                                  null
                                }
                                placeholderText='Expiration Date'
                                onChange={(value: any) =>
                                  setFieldValue(`${field}[${index}]expirationDate`, value)
                                }
                              />

                              <div className='text-danger mt-2'>
                                <ErrorMessage name={`${field}[${index}]expirationDate`} />
                              </div>
                            </>
                          )}

                          <label className='d-flex fs-5 fw-bold mb-2'>
                            <span className=''>Notes</span>
                          </label>
                          <textarea
                            className='form-control form-control-solid mb-2'
                            rows={3}
                            placeholder='Type or paste emails here'
                            name={`${field}[${index}]notes`}
                          ></textarea>
                          <UploadFile
                            label='Upload File'
                            field={`${field}[${index}]files`}
                            setFieldValue={setFieldValue}
                            value={value?.files}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div
                    className={`col-md-6 col-lg-4 fv-row mb-5 flex-center ${
                      field.includes('trainings') && 'd-none'
                    }`}
                  >
                    <div className='card shadow-sm card-size'>
                      <div className='card-body d-flex flex-column p-9'>
                        <h2
                          className='card-title  text-center p-8 m-auto cursor-pointer'
                          onClick={() => {
                            if (values.length > 0 && values[0]?.hasOwnProperty('expirationDate')) {
                              arrayHelpers.push({
                                certificate: '',
                                completionDate: '',
                                expirationDate: '',
                                notes: '',
                                files: '',
                              })
                            } else {
                              arrayHelpers.push({
                                certificate: '',
                                completionDate: '',
                                notes: '',
                                files: '',
                              })
                            }
                          }}
                        >
                          Add Certification <i className='fa fa-plus fa-10x ps-3 fs-1'></i>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export {QualificationCard}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ParentEducatorAgreementForm} from './steps/ParentEducatorAgreementForm'
import {EducatorCompletionSuccess} from './steps/EducatorCompletionSuccess'
import {
  ChildEnrolmentSteps,
  childKeyData,
  createEducatorSchemas,
  inits,
  mergeDeep,
} from '../core/ChildEnrolmentHelpers'
import {useMutation, useQuery} from 'react-query'
import {createChild, getChildByID, updateChild, getChildForm} from '../core/_request'
import {useParams} from 'react-router-dom'
import {ICreateEducator} from '../core/_models'
import {ChildInformationForm} from './steps/ChildInformation'
import {ParentGuardianForm} from './steps/ParentGuardiansForm'
import {ChildNominees} from './steps/ChildNominees'
import {ChildHealthInfo} from './steps/ChildHealthInfo'
import {ChildAuthorizations} from './steps/ChildAuthorizations'
import {ChildImmunizationForm} from './steps/ChildImmunizationForm'
import {ChildSchoolInfo} from './steps/ChildSchoolInfo'
import {StepperAsideMenu} from '../../common/StepperAsideMenu'
import {ChildSchedule} from './steps/ChildSchdule'
import {EducatorFeesAndRelationship} from './steps/FeesAndRelationship'
import {ParentEducatorAgreementPostForm} from './steps/ParentEducatorAgreementPostForm'
import {EducatorFeeSchedule} from './steps/EducatorFeeSchedule'
import {ComingSoon} from '../../common/ComingSoon'
import {StatutoryDeclarationForm} from './steps/StatutoryDeclarationForm'
import {SaturdayStatutoryDeclaration} from './steps/SaturdayStatutoryDeclaration'
import {ComplianceWrittenAgreement} from './steps/ComplianceWrittenAgreement'
import {RegularTransportationPermission} from './steps/RegularTransportationPermission'

export const ChildProfileForm = ({updateData}: any) => {
  const {id} = useParams()
  const navigate = useNavigate()

  const query: any = useQuery(['childData', id], () => getChildByID(id as string), {
    enabled: !!id, // Only execute the query if the ID is defined and not null
  })
  const childFormQuery: any = useQuery(['childUserData', id], getChildForm, {
    enabled: !!!id, // Only execute the query if the ID is not defined or null
  })

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper: any = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createEducatorSchemas[0])
  const [initValues, setInitValues] = useState<any>(inits[0])
  const [editable] = useState(window.location.pathname.includes('edit'))
  const [educatorFormValues, setEducatorFormValues] = useState<ICreateEducator>()
  const createMutation: any = useMutation(createChild)
  const updateMutation: any = useMutation(updateChild)
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef?.current as HTMLDivElement)
  }

  useEffect(() => {
    updateData(educatorFormValues)
  }, [educatorFormValues])

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }, [stepper?.current?.currentStepIndex])

  useEffect(() => {
    if (!query.isLoading && query?.isSuccess) {
      setEducatorFormValues(query?.data)
      // setInitValues({basicInformation: query?.data?.basicInformation})
    }
  }, [query.isLoading])

  useEffect(() => {
    if (!childFormQuery.isLoading && childFormQuery?.isSuccess && childFormQuery?.data) {
      setEducatorFormValues(childFormQuery?.data)
      // setInitValues({basicInformation: educatorFormQuery?.data?.basicInformation})
      navigate(`/child/edit/${childFormQuery?.data?._id}`)
    }
  }, [childFormQuery.isLoading])

  const updateStepperWithData = () => {
    if (editable) {
      setCurrentSchema(createEducatorSchemas[stepper?.current?.currentStepIndex])
      setInitValues(inits[stepper?.current?.currentStepIndex])
    }

    if (stepper?.current?.currentStepIndex !== stepper?.current?.totatStepsNumber) {
      stepper?.current?.goNext()
    } else {
      stepper?.current?.goto(1)
      // actions.resetForm()
    }
    if (!educatorFormValues?._id) {
      // navigate(`/child/edit/${createMutation?.data?._id}`)
      createMutation?.data && setEducatorFormValues(createMutation?.data)
    } else {
      updateMutation?.data && setEducatorFormValues(updateMutation?.data)
    }
  }

  useEffect(() => {
    if (!updateMutation?.isLoading && updateMutation?.isSuccess) {
      updateStepperWithData()
    }
  }, [updateMutation?.isSuccess])

  useEffect(() => {
    if (!createMutation?.isLoading && createMutation?.isSuccess) {
      updateStepperWithData()
    }
  }, [createMutation?.isSuccess])

  const prevStep = () => {
    if (!stepper?.current) {
      return
    }

    stepper?.current?.goPrev()

    setCurrentSchema(createEducatorSchemas[stepper?.current?.currentStepIndex - 1])
    setInitValues(inits[stepper?.current?.currentStepIndex - 1])
  }

  const submitStep = async (values: any, actions: FormikValues) => {
    if (!stepper?.current) {
      return
    }
    if (editable) {
      const formDataValues: any = {}
      childKeyData[stepper?.current?.currentStepIndex - 1]['keys'].map((key: string) => {
        formDataValues[key] = values[key]
      })
      values?._id
        ? await updateMutation.mutate({
            values: {...formDataValues},
            _id: values._id,
          })
        : await createMutation.mutate(formDataValues)
    } else {
      updateStepperWithData()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const gotoStep = (step: number) => {
    if (stepper?.current) {
      stepper?.current?.goto(step)
    }
  }

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills  d-flex flex-row flex-xl-column flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <StepperAsideMenu steps={ChildEnrolmentSteps} gotoStep={gotoStep} />

      <div className='d-flex flex-row-fluid bg-body rounded'>
        <Formik
          validationSchema={editable && currentSchema}
          enableReinitialize
          initialValues={mergeDeep(initValues, educatorFormValues)}
          onSubmit={submitStep}
        >
          {({values, errors, setFieldValue}) => (
            <Form className='py-20 w-100 px-9' noValidate id='kt_create_account_form'>
              {createMutation?.isError && (
                <div>
                  <div className='alert alert-danger'>
                    {createMutation?.error?.response?.data?.message ||
                      createMutation?.error?.response?.data?.message[0] ||
                      ''}
                  </div>
                </div>
              )}
              <fieldset disabled={!editable}>
                <div className='current' data-kt-stepper-element='content'>
                  <ChildInformationForm values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <ParentGuardianForm values={values} setFieldValue={setFieldValue} />
                </div>

                <div data-kt-stepper-element='content'>
                  <ChildNominees values={values} setFieldValue={setFieldValue} />
                </div>

                <div data-kt-stepper-element='content'>
                  <ChildHealthInfo values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <ChildImmunizationForm values={values} setFieldValue={setFieldValue} />
                </div>

                <div data-kt-stepper-element='content'>
                  <ChildAuthorizations values={values} setFieldValue={setFieldValue} />
                </div>

                <div data-kt-stepper-element='content'>
                  <ParentEducatorAgreementForm values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <ChildSchoolInfo values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <ChildSchedule values={values} setFieldValue={setFieldValue} errors={errors} />
                </div>

                <div data-kt-stepper-element='content'>
                  <EducatorFeesAndRelationship values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <ParentEducatorAgreementPostForm values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <EducatorFeeSchedule values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <ComplianceWrittenAgreement values={values} setFieldValue={setFieldValue} />
                </div>
                {/* <div data-kt-stepper-element='content'>
                  <ComingSoon />
                </div> */}
                <div data-kt-stepper-element='content'>
                  <StatutoryDeclarationForm values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <SaturdayStatutoryDeclaration values={values} setFieldValue={setFieldValue} />
                </div>
                <div data-kt-stepper-element='content'>
                  <RegularTransportationPermission values={values} setFieldValue={setFieldValue} />
                </div>
              </fieldset>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber
                        ? 'Finish'
                        : stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber - 1 && 'Got It'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

import {ErrorMessage, Field, useField} from 'formik'
import React, {FC} from 'react'
import Select from 'react-select'
import {days} from '../core/ChildEnrolmentHelpers'
import {TimeRangePicker} from '../../common/TimeRangePicker'
import {KTSVG} from '../../../../../_metronic/helpers'

interface Props {
  values: any
  arrayHelpers: any
  field: string
  setFieldValue: any
}

interface Props {
  values: any
  arrayHelpers: any
  field: string
  setFieldValue: any
  preSchool?: boolean
  title?: string
}

export const BookingHours: FC<Props> = ({values, arrayHelpers, field, setFieldValue, title}) => {
  return (
    <>
      {values?.length > 0 &&
        values?.map((value: any, index: any) => (
          <table className='table table-responsive ' key={index}>
            <tbody>
              <tr>
                <td data-label='Name'>
                  <div className=' w-100'>
                    <div className='row'>
                      <div className=' col-xxl-10'>
                        <div className='row'>
                          <div className={`col-lg-${value?.careTime !== undefined ? '6' : '4'}`}>
                            <label
                              className={`d-flex align-items-center fs-5 fw-bold  ${
                                index !== 0 ? 'd-none' : 'mb-2'
                              }`}
                            >
                              <span className='required'>
                                {title
                                  ? title
                                  : value?.careTime !== undefined
                                  ? 'School holiday time'
                                  : 'Booking hours and days of care required'}
                              </span>
                            </label>

                            <Select
                              options={days}
                              placeholder='Select'
                              name={`${field}[${index}].day`}
                              className={`multi-select-container ${index === 0 ? 'mt-3' : 'mt-2'}`}
                              classNamePrefix='multi-select'
                              onChange={(e: any) => {
                                setFieldValue(`${field}[${index}].day`, e.label)
                              }}
                              value={days.filter((day: any) => value?.day == day.label)}
                              closeMenuOnSelect={true}
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}].day`} />
                            </div>
                          </div>
                          <div className={`col-lg-${value?.careTime !== undefined ? '6' : '4'}`}>
                            <label
                              className={`d-flex align-items-center fs-5 fw-bold ${
                                index !== 0 ? 'd-none' : 'mb-2 '
                              }`}
                            >
                              <span className='required'>
                                {' '}
                                {value?.careTime !== undefined
                                  ? 'Care time'
                                  : 'Before school care time'}
                              </span>
                            </label>
                            <TimeRangePicker
                              setFieldValue={setFieldValue}
                              field={`${field}[${index}].${
                                value?.careTime !== undefined ? 'careTime' : 'beforeSchoolCareTime'
                              }`}
                              value={
                                value[
                                  `${
                                    value?.careTime !== undefined
                                      ? 'careTime'
                                      : 'beforeSchoolCareTime'
                                  }`
                                ]
                              }
                              ErrorMessage={ErrorMessage}
                            />
                          </div>
                          <div
                            className={`col-lg-4 ${value?.careTime !== undefined ? 'd-none' : ''}`}
                          >
                            <label
                              className={`d-flex align-items-center fs-5 fw-bold  ${
                                index !== 0 ? 'd-none' : 'mb-2'
                              }`}
                            >
                              <span className='required'> After school care time</span>
                            </label>
                            <TimeRangePicker
                              setFieldValue={setFieldValue}
                              field={`${field}[${index}].afterSchoolCareTime`}
                              value={value['afterSchoolCareTime']}
                              ErrorMessage={ErrorMessage}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-xxl-2'>
                        <label
                          className={`d-flex align-items-center fs-5 fw-bold mb-2  ${
                            index !== 0 ? 'd-none' : 'invisible'
                          }`}
                        >
                          <span className='required'>time</span>
                        </label>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                          onClick={() => arrayHelpers.remove(index)}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Remove Item'
                          disabled={values.length > 1 ? false : true}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon svg-icon-3'
                          />
                        </button>
                        {values.length === index + 1 && (
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Add New Item'
                            onClick={() => {
                              if (value?.careTime !== undefined) {
                                arrayHelpers.push({
                                  day: '',
                                  careTime: '',
                                })
                              } else {
                                arrayHelpers.push({
                                  day: '',
                                  beforeSchoolCareTime: '',
                                  afterSchoolCareTime: '',
                                })
                              }
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr013.svg'
                              className='svg-icon  svg-icon-3'
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </>
  )
}

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import SVG from 'react-inlinesvg'

import Chart from 'react-apexcharts'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// const useStyles = makeStyles((theme) => ({
//   boxBG: {
//     backgroundColor: '#fff',
//     height: '200px',
//     minHeight: '200px !important',
//   },
//   marginControl: {
//     paddingBottom: '0 !important',
//   },
//   filterHeadings: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'stretch',
//     flexWrap: 'wrap',
//     minHeight: 70,
//     paddingTop: 0,
//     paddingBottom: 0,
//     backgroundColor: 'transparent',
//   },
//   headingBG: {
//     backgroundColor: '#fff',
//   },
//   navPillsColor: {
//     '& li a.active': {
//       backgroundColor: '#1BC5BD !important',
//     },
//   },
// }))

export function UserStats({className, symbolShape, baseColor, adsChartCounts}: any) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  let now: any = new Date()
  let lastMonth = new Date()

  let thisMonthlabel: string[] = []
  let thisMonthcount: number[] = []

  let lastMonthlabel: string[] = []
  let lastMonthcount: number[] = []

  let lastWeeklabel: any[] = []
  let lastWeekcount: any[] = []

  let thisWeeklabel: any[] = []
  let thisWeekcount: any[] = []

  const findCountOnDay = (adsObj: any[], day: number) => {
    return adsObj.filter((ad) => ad._id.day === day)
  }

  const daysInMonth = (month: number, year: number) => {
    return new Date(year, month, 0).getDate()
  }

  // adsChartCounts &&
  //   adsChartCounts.countsLastMonth &&
  //   adsChartCounts.countsLastMonth.map((ad) => {
  //     lastMonthlabel.push(`${ad._id.month}/${ad._id.day}`);
  //     lastMonthcount.push(ad.count);
  //   });

  if (adsChartCounts && adsChartCounts.countsLastMonth) {
    let lastMonthNumber = now.getMonth()
    for (let index = 1; index <= daysInMonth(now.getMonth(), now?.getYear()); index++) {
      const response = findCountOnDay(adsChartCounts.countsLastMonth, index)
      if (response.length > 0) {
        lastMonthlabel.push(`${index}`)
        lastMonthcount.push(response[0].count)
      } else {
        lastMonthlabel.push(`${index}`)
        lastMonthcount.push(0)
      }
    }
  }

  // adsChartCounts &&
  //   adsChartCounts.countsThisMonth &&
  //   adsChartCounts.countsThisMonth.map((ad) => {
  //     thisMonthlabel.push(`${ad._id.month}/${ad._id.day}`);
  //     thisMonthcount.push(ad.count);
  //   });

  if (adsChartCounts && adsChartCounts.countsThisMonth) {
    let lastMonthNumber = now.getMonth() + 1
    for (let index = 1; index <= daysInMonth(now.getMonth() + 1, now.getYear()); index++) {
      const response = findCountOnDay(adsChartCounts.countsThisMonth, index)

      if (response.length > 0) {
        thisMonthlabel.push(`${index}`)
        thisMonthcount.push(response[0].count)
      } else {
        thisMonthlabel.push(`${index}`)
        thisMonthcount.push(0)
      }
    }
  }

  let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  let lastSunday: any = new Date(today.setDate(today.getDate() - today.getDay()))
  let secondlastSunday: any = new Date(lastSunday - 7 * 60 * 60 * 24 * 1000)

  //console.log("lastSunday", lastSunday);
  //console.log("secondlastSunday", secondlastSunday);
  //console.log(secondlastSunday.getMonth() + 1, secondlastSunday.getDate());

  // adsChartCounts &&
  //   adsChartCounts.countsLastWeek &&
  //   adsChartCounts.countsLastWeek.map((ad) => {
  //     lastWeeklabel.push(`${ad._id.month}/${ad._id.day}`);
  //     lastWeekcount.push(ad.count);
  //   });

  if (adsChartCounts && adsChartCounts.countsLastWeek) {
    let lastMonthTotalDays = daysInMonth(
      secondlastSunday.getMonth() + 1,
      secondlastSunday.getYear()
    )

    let lastWeekMonth = secondlastSunday.getMonth() + 1
    let lastWeekStartDay =
      secondlastSunday.getDate() === lastMonthTotalDays ? 1 : secondlastSunday.getDate() + 1

    for (let index = 1; index <= 7; index++) {
      const response = findCountOnDay(adsChartCounts.countsLastWeek, lastWeekStartDay)

      if (response.length > 0) {
        lastWeeklabel.push(`${lastWeekStartDay}/${lastWeekMonth}`)
        lastWeekcount.push(response[0].count)
      } else {
        lastWeeklabel.push(`${lastWeekStartDay}/${lastWeekMonth}`)
        lastWeekcount.push(0)
      }

      if (lastWeekStartDay === lastMonthTotalDays) {
        lastWeekStartDay = 1
        lastWeekMonth = lastWeekMonth + 1
      } else {
        lastWeekStartDay++
      }
    }
  }

  // adsChartCounts &&
  //   adsChartCounts.countsThisWeek &&
  //   adsChartCounts.countsThisWeek.map((ad) => {
  //     thisWeeklabel.push(`${ad._id.month}/${ad._id.day}`);
  //     thisWeekcount.push(ad.count);
  //   });

  if (adsChartCounts && adsChartCounts.countsThisWeek) {
    let lastMonthTotalDays = daysInMonth(lastSunday.getMonth() + 1, lastSunday.getYear())

    let thisWeekMonth = lastSunday.getMonth() + 1
    //let thisWeekStartDay = lastSunday.getDate() + 1;

    let thisWeekStartDay =
      lastSunday.getDate() === lastMonthTotalDays ? 1 : lastSunday.getDate() + 1

    for (let index = 1; index <= 7; index++) {
      const response = findCountOnDay(adsChartCounts.countsThisWeek, thisWeekStartDay)

      if (response.length > 0) {
        thisWeeklabel.push(`${thisWeekStartDay}/${thisWeekMonth}`)
        thisWeekcount.push(response[0].count)
      } else {
        thisWeeklabel.push(`${thisWeekStartDay}/${thisWeekMonth}`)
        thisWeekcount.push(0)
      }

      if (thisWeekStartDay === lastMonthTotalDays) {
        thisWeekStartDay = 1
        thisWeekMonth = thisWeekMonth + 1
      } else {
        thisWeekStartDay++
      }
    }
  }

  const tabs = {
    tab1: 'tab_last_month',
    tab2: 'tab_this_month',
    tab3: 'tab_last_week',
    tab4: 'tab_this_week',
  }
  const [activeTab, setActiveTab] = useState(tabs.tab2)

  const [currentHeading, setcurrentHeading]: any = useState(null)

  const [chartData, setChartData]: any = useState(null)

  const manageFilter = (tab: React.SetStateAction<string>, id: number, heading: string) => {
    setActiveTab(tab)

    let chartHeading: any = heading
    const month = now.getMonth() === 0 ? now.getMonth() + 11 : now.getMonth() - 1

    if (id === 1) {
      chartHeading = `${chartHeading} (${months[month]})`
      renderCharts(lastMonthlabel, lastMonthcount)
    } else if (id === 2) {
      chartHeading = `${chartHeading} (${months[now.getMonth()]})`
      renderCharts(thisMonthlabel, thisMonthcount)
    } else if (id === 3) {
      renderCharts(lastWeeklabel, lastWeekcount)
    } else if (id === 4) {
      renderCharts(thisWeeklabel, thisWeekcount)
    }

    setcurrentHeading(chartHeading)
  }

  const renderCharts = (label: any[], counts: any[]) => {
    setChartData({
      options: {
        theme: {
          palette: 'palette4', // upto palette10
        },
        chart: {
          id: 'apexchart-example',
        },
        xaxis: {
          categories: label,
        },
      },
      series: [
        {
          name: 'Users',
          data: counts,
        },
      ],
    })
  }

  useEffect(() => {
    setcurrentHeading(`This Month Users (${months[now.getMonth()]})`)
    renderCharts(thisMonthlabel, thisMonthcount)
  }, [])

  return (
    <div className={`card card-custom ${className}`}>
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`card-header border-0 pt-5 `}>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label font-weight-bolder text-dark'>Users</span>
          </h3>
          <div className='card-toolbar'>
            <ul className={`nav nav-pills nav-pills-sm nav-dark-75`}>
              <li className='nav-item'>
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab3 && 'active'}`}
                  data-toggle='tab'
                  onClick={() => manageFilter(tabs.tab3, 3, 'Last Week Users')}
                >
                  Last Week
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab4 && 'active'}`}
                  data-toggle='tab'
                  onClick={() => manageFilter(tabs.tab4, 4, 'This Week Users')}
                >
                  This Week
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab2 && 'active'}`}
                  data-toggle='tab'
                  onClick={() => manageFilter(tabs.tab2, 2, 'This Month Users')}
                >
                  This Month
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab1 && 'active'}`}
                  data-toggle='tab'
                  onClick={() => manageFilter(tabs.tab1, 1, 'Last Month Users')}
                >
                  Last Month
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* end::Header */}

        <div
          className={`d-flex align-items-center justify-content-between card-spacer flex-grow-1 `}
        >
          <span className={`symbol ${symbolShape} symbol-50 symbol-light-${baseColor} mr-2`}>
            <span className='symbol-label'>
              <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/Layout-4-blocks.svg')}></SVG>
              </span>
            </span>
          </span>
          <div className='d-flex flex-column text-right'>
            <span className='text-dark-75 font-weight-bolder font-size-h3'>{currentHeading}</span>
          </div>
        </div>

        <div className={`card-rounded-bottom`}>
          {chartData && (
            <Chart
              options={chartData.options}
              series={chartData.series}
              type='bar'
              max-width={600}
              height={200}
            />
          )}
        </div>
      </div>
    </div>
  )
}

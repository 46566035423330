import React, {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {pdfjs, Document, Page} from 'react-pdf'
import UploadFile from '../../../common/UploadFile'

interface Props {
  values: any
  setFieldValue: any
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const StatutoryDeclarationForm: FC<Props> = ({values, setFieldValue}) => {
  const [numPages, setNumPages] = useState(null)
  function onDocumentLoadSuccess({numPages}: any) {
    setNumPages(numPages)
  }
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Statutory declaration</h2>
        <Link
          className='btn btn-success float-end '
          to='/StatutoryDeclaration.pdf'
          target='_blank'
          style={{zIndex: 100000}}
        >
          Download PDF
        </Link>
      </div>
      <div className='all-page-container d-flex justify-content-center'>
        <Document
          file='/StatutoryDeclaration.pdf'
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
      <div className='col-md-12 fv-row mb-5'>
        <UploadFile
          label='Upload File'
          field='statutoryDeclarationForm'
          setFieldValue={setFieldValue}
          // lastStep={true}
          value={values?.statutoryDeclarationForm}
        />
      </div>
    </div>
  )
}

export {StatutoryDeclarationForm}

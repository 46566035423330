import * as Yup from 'yup'
import 'yup-phone-lite'
import {ICreateEducator, ICreateEducatorArray} from './_models'

const address = Yup.object({
  streetAddress: Yup.array().of(Yup.string().trim().required('Address is required')).min(1),
  suburb: Yup.string().trim().label('Suburb').required('Suburb is required'),
  postcode: Yup.string().trim().label('postcode').required('Postcode is required'),
  region: Yup.string().required('Please select an option above'),
  state: Yup.string().trim().label('State').required('State is required'),
  country: Yup.string().trim().label('Country').required('Country is required'),
})

const stringRequired = (message: string) => Yup.string().trim().required(message)

const riskAssessmentMatrixSchema = {
  activity: stringRequired('Description is required'),
  hazardIdentified: stringRequired('Description is required'),
  riskAssessmentMatrix: stringRequired('Description is required'),
  eliminationControlMatrix: stringRequired('Description is required'),
  responsibleForControl: stringRequired('Description is required'),
  whenControlImplemented: stringRequired('Description is required'),
}

const getExcursionSchema: any = (school: boolean) => {
  const excursionObject = {
    startDate: Yup.date().required('Start date is required').nullable(true),
    renewalDate: Yup.date()
      .min(Yup.ref('startDate'), "Renewal date can't be lesser than start date date")
      .required('Renewal date is required')
      .nullable(true),
    licenseNo: stringRequired('License number is required'),
    licenseExpiry: Yup.date()
      .required('License expiry date is required')
      .min(new Date(Date.now()), "License expiry date should be on or after today's date")
      .nullable(true),
    carRegistrationNumber: stringRequired('Car registration number is required'),
    carNoOfSeats: stringRequired('Car seat count is required'),
    individualRequirements: stringRequired('Please select an option to above'),
    carBoosters: stringRequired('Car booster count is required'),
    departureAddress: stringRequired('Departure address is required'),
    destinationAddress: stringRequired('Destination address is required'),
    travelTimeLocation: Yup.array(
      Yup.object({
        estimatedTime: stringRequired('Time is required'),
        location: stringRequired('Location is required'),
      })
    ),
    travelDaysTime: Yup.array(
      Yup.object({
        days: Yup.array().of(stringRequired('Please select a day')).min(1, 'Please select a day'),
        time: stringRequired('Time is required'),
      })
    ),
    [school ? 'transportationChildren' : '']: school
      ? Yup.array(
          Yup.object({
            name: stringRequired('Name is required'),
            dropOffAddress: stringRequired('Drop off address is required'),
            pickUpAddress: stringRequired('Pick up address is required'),
          })
        )
      : '',
    [school ? 'transportationSchools' : '']: school
      ? Yup.array(
          Yup.object({
            name: stringRequired('Name is required'),
            address: stringRequired('Address is required'),
          })
        )
      : '',
    // // children: [{fullName: '', address: ''}],
    childrenPeriod: stringRequired('Children period is required'),
    routeFile: stringRequired('Please upload an image'),
    transportType: stringRequired('Please select an option'),
    safetyRequirement: stringRequired('Please select an option'),
    // safetyRequirementDetails: stringRequired('Please select an option'),
    proposedActivity: stringRequired('Proposed activity is required'),
    numberOfAdults: stringRequired('No. of adults are required'),
    adultWithSpecialisedSkills: stringRequired('Names and description of adults are required'),
    numberOfChildren: stringRequired('No. of children are required'),
    anyWaterHazard: stringRequired('Please select an option'),
    // waterHazardDetails: '',
    enteringAndExitingProcess: stringRequired('Description is required'),
    embarkingAndDisembarkingProcess: stringRequired('Description is required'),
    excursionChecklist: Yup.array()
      .of(stringRequired('Please check the list'))
      .min(1, 'Please check the list'),
    riskAssessment: Yup.array()
      .of(Yup.object(riskAssessmentMatrixSchema))
      .min(6, 'Description is required'),
  }

  return excursionObject
}

export const createEducatorSchemas = [
  Yup.object({
    basicInformation: Yup.object({
      fullName: Yup.string().trim().label('Name').required('Name is required'),
      familyName: Yup.string().trim().label('Family Name').required('Family Name is required'),
      CRN: Yup.string()
        .trim()
        .label('CRN')
        .required('CRN is required')
        .matches(/^[0-9]{9}[A-Z]{1}$/, 'Invalid CRN'),
      dob: Yup.date().label('Date of Birth').required('Date of Birth is required'),
      // .max(new Date(Date.now() - 567648000000), 'You must be at least 18 years'),
      drivingLicense: Yup.string()
        .label('Driving License')
        .required('Driving License is required')
        .matches(/^[A-Z0-9]{9}$/, 'Invalid Driving license'),
      ABN: Yup.string()
        .label('ABN')
        .required('ABN is required')
        .matches(/^(\d *?){11}$/, 'Invalid ABN'),
      RANumber: Yup.string()
        .label('RA Number')
        .required('RA Number is required')
        .matches(/^[A-Za-z0-9]{10,11}$/, 'Invalid RA'),
      email: Yup.string().label('Email').required('Email is required').email(),
      contactPhone: Yup.array()
        .of(
          Yup.string()
            .phone('AU', 'Contact Number is invalid')
            .required('Contact Phone is required')
        )
        .required('Contact Phone is required'),
      dayCareAddress: address,
      isBARRAddress: Yup.boolean().required('Please select an option above'),
      bushFireRisk: Yup.boolean().label('Is Bush Fire').required('Please select an option above'),
      dayCareAtHome: Yup.boolean()
        .label('Is Day Care At Home')
        .required('Please select an option above'),
      isPoolOrSpa: Yup.boolean().label('Is pool or spa').required('Please select an option above'),
      postalAddress: address,
      isBankRupted: Yup.boolean().required('Please select an option'),
      isCheckRefused: Yup.boolean().required('Please select an option'),
      isChargeGuilty: Yup.boolean().required('Please select an option'),
    }),
  }),
  Yup.object({
    emergencyContact: Yup.object({
      fullName: Yup.string().trim().label('Name').required('Name is required'),
      email: Yup.string().label('Email').required().email('Email is required'),
      residentialAddress: address,
      contactPhone: Yup.array().of(
        Yup.string()
          .label('Contact Phone')
          .required('Contact Phone is required')
          .phone('AU', 'Contact Number is invalid')
      ),
      relationship: Yup.string().label('Relationship').required('Please select relation'),
    }),
    operationDetails: Yup.object({
      // dateRegistered: Yup.date()
      //   .label('Registered Date')
      //   .required('Registered date is required')
      //   .max(new Date(Date.now()), "Registered date should be on or before today's date")
      //   .nullable(true),
      // dateRegistrationCeased: Yup.date()
      //   .min(
      //     Yup.ref('dateRegistered'),
      //     "Registration ceased date can't be earlier than the date of registration."
      //   )
      //   .label('Registration Ceased Date')
      //   .required('Registration ceased date is required')
      //   .nullable(true),

      workingDays: Yup.array()
        .of(Yup.string().label('Working days').required('Please select working day/s'))
        .min(1, 'Working days is required'),
      workingTime: Yup.string().required('Please select working time'),
    }),
  }),
  Yup.object({
    qualificationsCompleted: Yup.array()
      .of(
        Yup.object({
          certificate: Yup.string().label('Certificate').required('Certificate is required'),
          completionDate: Yup.date()
            .label('Completion Date')
            .required('Completion date is required')
            .max(new Date(Date.now()), "Completion date should be on or before today's date")
            .nullable(true),
          notes: Yup.string(),
          files: Yup.string().label('Certificate File').required('Certificate file is required'),
        })
      )
      .min(1),
    qualificationsInProgress: Yup.array()
      .of(
        Yup.object({
          certificate: Yup.string().label('Certificate').required('Certificate is required'),
          completionDate: Yup.date()
            .label('Completion Date')
            .required('Completion date is required')
            .min(new Date(Date.now() + 86400), "Completion date should be after today's date.")
            .nullable(true),

          notes: Yup.string(),
          files: Yup.string().label('Certificate File').required('Certificate file is required'),
        })
      )
      .min(0),
  }),

  Yup.object({
    trainingsCompleted: Yup.array()
      .of(
        Yup.object({
          certificate: Yup.string().label('Certificate').required('Certificate is required'),
          completionDate: Yup.date()
            .label('Completion Date')
            .required('Completion date is required'),
          expirationDate: Yup.date()
            .min(
              Yup.ref('completionDate'),
              "Completion date can't be lesser than the date expiration"
            )
            .label('Expiration Date')
            .required('Expiration date is required')
            .nullable(true),
          notes: Yup.string(),
          files: Yup.string().label('Certificate File').required('Certificate file is required'),
        })
      )
      .min(1),
  }),

  Yup.object({
    securityDetails: Yup.object({
      children: Yup.array()
        .of(
          Yup.object({
            fullName: Yup.string().trim().label('Full Name').required('Full name is required'),
            dob: Yup.date()
              .label('Date of Birth')
              .required('Date of birth is required')
              .max(new Date(Date.now()), "Date of birth should be on or before today's date")
              .nullable(true),
            policeCheckReport: Yup.string()
              .label('Police Check Report')
              .required('Police check report is required'),
          })
        )
        .min(1),
      adults: Yup.array()
        .of(
          Yup.object({
            fullName: Yup.string().label('Full Name').required('Full name is required'),
            dob: Yup.date()
              .label('Date of Birth')
              .required('Date of birth is required')
              .nullable(true)
              .max(new Date(Date.now() - 567648000000), 'He / She must be at least 18 years'),
            policeCheckReport: Yup.string()
              .label('Police Check Report')
              .required('Police check report is required'),
            identificationNumber: Yup.string()
              .trim()
              .label('Identification Number')
              .required('Identification Number is required'),
            // .matches(/^\d{3}\s\d{3}\s\d{3}$/, 'Invalid Identification number'),
            expiryDate: Yup.date()
              .label('Expiry Date')
              .required('Expiry date is required')
              .nullable(true),
            workingWithChildrenCheck: Yup.string()
              .label('Working with Children Check')
              .required('Working with children check is required'),
          })
        )
        .min(1),
    }),
  }),
  Yup.object({
    statutoryDeclarationForm: Yup.string().required('Please upload the filled form'),
  }),
  Yup.object({
    medicalCertificate: Yup.string().required('Please upload the filled form'),
  }),
  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    agreedOnPolicy: Yup.boolean()
      .oneOf([true], 'Please select the checkbox')
      .required('Please select the checkbox'),
    bankDetails: Yup.object({
      accountHolderName: stringRequired('Account holder name is required'),
      bankName: stringRequired('Bank name is required'),
      branchName: stringRequired('Bank name is required'),
      BSBNumber: stringRequired('BSB number is required'),
      accountNumber: stringRequired('Account number is required'),
    }),
  }),
  Yup.object({signature: Yup.string().required('Signature is required').nullable()}),
  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    educatorSelfAssessment: Yup.object({
      emergencyEvacuationMap: Yup.object({
        floorPlanOfHouse: stringRequired('Please select an option above'),
        garageDoors: stringRequired('Please select an option above'),
        frontYard: stringRequired('Please select an option above'),
        backYard: stringRequired('Please select an option above'),
        FDCRooms: stringRequired('Please select an option above'),
        externalDoors: stringRequired('Please select an option above'),
        comment: stringRequired('Please provide any comment'),
      }),
      doorSafety: Yup.object({
        frontDoorSecured: stringRequired('Please select an option above'),
        backDoorSecured: stringRequired('Please select an option above'),
        externalDoorsSecured: stringRequired('Please select an option above'),
        KeysNotAccessible: stringRequired('Please select an option above'),
      }),

      keysRequiredInEmergency: Yup.object({
        frontDoor: stringRequired('Please select an option above'),
        backDoor: stringRequired('Please select an option above'),
        otherDoor: stringRequired('Please select an option above'),
      }),

      emergencyEvacuation: Yup.object({
        collectEmergencyEvacuationOutsideTheHouse: stringRequired('Please select an option above'),
        accessToSnacksAndWater: stringRequired('Please select an option above'),
        firstAidKitStocked: stringRequired('Please select an option above'),
        haveAccessToWipes: stringRequired('Please select an option above'),
        haveFolder: stringRequired('Please select an option above'),
        childMedicalContacts: stringRequired('Please select an option above'),
        guardianNumbers: stringRequired('Please select an option above'),
        emergencyNumbers: stringRequired('Please select an option above'),
        riskManagementForms: stringRequired('Please select an option above'),
      }),

      kitchen: Yup.object({
        KitchenAreaUsed: stringRequired('Please select an option above'),
        KitchenAreaSecure: stringRequired('Please select an option above'),
      }),

      lockDownRoom: Yup.object({
        whichRoom: stringRequired('This field is required'),
        doorLocked: stringRequired('Please select an option above'),
        cleanWater: stringRequired('Please select an option above'),
        communicationEquipment: stringRequired('Please select an option above'),
      }),

      tv: Yup.object({
        securedTv: stringRequired('Please select an option above'),
        glassTVsSecured: stringRequired('Please select an option above'),
        TVCabinetFrontCovered: stringRequired('Please select an option above'),
        understandGuidelines: stringRequired('Please select an option above'),
        fourHoursPerDay: stringRequired('Please select an option above'),
      }),

      indoorSpace: Yup.object({
        furnitureFixedToWalls: stringRequired('Please select an option above'),
        cordsReach: stringRequired('Please select an option above'),
        electricalSafety: stringRequired('Please select an option above'),
        electricalCordsReach: stringRequired('Please select an option above'),
        furnitureCornerCovers: stringRequired('Please select an option above'),
        sharpObjects: stringRequired('Please select an option above'),
        unstableFurniture: stringRequired('Please select an option above'),
        lightersReach: stringRequired('Please select an option above'),
        trippingHazards: stringRequired('Please select an option above'),
        safetyGuards: stringRequired('Please select an option above'),
        toysStored: stringRequired('Please select an option above'),
        walkwaysClear: stringRequired('Please select an option above'),
        medicationsRemoved: stringRequired('Please select an option above'),
        equipmentAgeAppropriate: stringRequired('Please select an option above'),
        smallerObjectsStored: stringRequired('Please select an option above'),
        shatterRestraintFilm: stringRequired('Please select an option above'),
      }),

      bathrooms: Yup.object({
        glassFilmProtection: stringRequired('Please select an option above'),
        electricalAppliances: stringRequired('Please select an option above'),
        unusedPowerPointsSafety: stringRequired('Please select an option above'),
        waterSystemTurnedDown: stringRequired('Please select an option above'),
        bathAndSinkPlugs: stringRequired('Please select an option above'),
        poisonsAndToiletReach: stringRequired('Please select an option above'),
        sharpObjectsStored: stringRequired('Please select an option above'),
        floorsKeptDry: stringRequired('Please select an option above'),
        matsArePlaced: stringRequired('Please select an option above'),
        curtainCordsReach: stringRequired('Please select an option above'),
        cosmeticsProducts: stringRequired('Please select an option above'),
        bathroomClean: stringRequired('Please select an option above'),
        toiletAccessible: stringRequired('Please select an option above'),
        washingSink: stringRequired('Please select an option above'),
      }),

      laundryRoom: Yup.object({
        laundryUsedForFDC: stringRequired('Please select an option above'),
        safetyMeasures: Yup.string().when('laundryUsedForFDC', {
          is: 'Yes',
          then: stringRequired('Please select an option above'),
        }),

        electricalAppliancesInLaundry: stringRequired('Please select an option above'),
        detergentsKeptAway: stringRequired('Please select an option above'),
      }),

      toysResources: Yup.object({
        caredAndRemovedToys: stringRequired('Please select an option above'),
        equipmentSharpEdgesReach: stringRequired('Please select an option above'),
        toysAreNonToxic: stringRequired('Please select an option above'),
        highchairsFitted: stringRequired('Please select an option above'),
        boxLidsSecure: stringRequired('Please select an option above'),
      }),

      trampoline: Yup.object({
        haveTrampoline: stringRequired('Please select an option above'),
        approvedSafetyNetInstalled: stringRequired('Please select an option above'),
        superviseUsed: stringRequired('Please select an option above'),
        jumpingChildren: stringRequired('Please select an option above'),
      }),

      swimmingPoolSpa: Yup.object({
        haveSwimmingPool: stringRequired('Please select an option above'),
        fenceAroundYourPool: stringRequired('Please select an option above'),
        objectsEnable: stringRequired('Please select an option above'),
        gateLocked: stringRequired('Please select an option above'),
        spaFilterReach: stringRequired('Please select an option above'),
        safetyAndResuscitation: stringRequired('Please select an option above'),
        poolAreSecured: stringRequired('Please select an option above'),
        climbObjectRemoved: stringRequired('Please select an option above'),
        allowEnrolledFDC: stringRequired('Please select an option above'),
      }),

      playFixtures: Yup.object({
        secureWorkingOrder: stringRequired('Please select an option above'),
        maximiseStability: stringRequired('Please select an option above'),
        thoroughfares: stringRequired('Please select an option above'),
        sufficientDrop: stringRequired('Please select an option above'),
        highEquipmentSoftArea: stringRequired('Please select an option above'),
      }),

      outdoorSpace: Yup.object({
        deckingGoodRepair: stringRequired('Please select an option above'),
        deckingTheCorrectDepth: stringRequired('Please select an option above'),
        outsideAreaRepair: stringRequired('Please select an option above'),
        plantsInTheGarden: stringRequired('Please select an option above'),
        anyClimbableTrees: stringRequired('Please select an option above'),
        anyDrains: stringRequired('Please select an option above'),
        ensureChildrenPreschool: stringRequired('Please select an option above'),
        shadeClothIsAttached: stringRequired('Please select an option above'),
        doorFullyFunctional: stringRequired('Please select an option above'),
        powerPointsReach: stringRequired('Please select an option above'),
        allHolesReach: stringRequired('Please select an option above'),
        fishpondAppropriatelyReach: stringRequired('Please select an option above'),
        allBucketsBowls: stringRequired('Please select an option above'),
        poolsAndBucketsEmpty: stringRequired('Please select an option above'),
        outsideAreaIsClean: stringRequired('Please select an option above'),
      }),

      doubleStoreyHouse: Yup.object({
        houseTwoStorey: stringRequired('Please select an option above'),
        accessToUpstairs: stringRequired('Please select an option above'),
        stairsHaveSecureLocked: stringRequired('Please select an option above'),
      }),

      roomsNotIdentified: Yup.object({
        roomDescription: stringRequired('This option is required'),
      }),

      SIDSSafeSleepingGuideline: Yup.object({
        babiesSleepInCot: stringRequired('Please select an option above'),
        schoolAgeChildrenSleepInFloor: stringRequired('Please select an option above'),
        allowSleepOnAnotherBed: stringRequired('Please select an option above'),
        sleepingSafely: stringRequired('Please select an option above'),
        babyFaceCovered: stringRequired('Please select an option above'),
        tummyPlayEncouraged: stringRequired('Please select an option above'),
        coverTheirHead: stringRequired('Please select an option above'),
        mattressShouldClean: stringRequired('Please select an option above'),
        heatersReach: stringRequired('Please select an option above'),
        mustSleepInCot: stringRequired('Please select an option above'),
        sleepingOnFloor: stringRequired('Please select an option above'),
        enoughCotSheets: stringRequired('Please select an option above'),
        furnitureGuarded: stringRequired('Please select an option above'),
        preventShattering: stringRequired('Please select an option above'),
        openedMoreThan100mm: stringRequired('Please select an option above'),
        powerPointsReach: stringRequired('Please select an option above'),
        withStandardASNZS: stringRequired('Please select an option above'),
        cotBedLinenConforms: stringRequired('Please select an option above'),
        pillowsAndMobiles: stringRequired('Please select an option above'),
        toiletriesMedication: stringRequired('Please select an option above'),
        smallerObjectsReach: stringRequired('Please select an option above'),
        curtainAndBlindCordsReach: stringRequired('Please select an option above'),
        electricalCordsReach: stringRequired('Please select an option above'),
        bunkBedsReach: stringRequired('Please select an option above'),
        bedroomClean: stringRequired('Please select an option above'),
      }),

      sleepingArrangements: Yup.object({
        fixedCot: stringRequired('Please select an option above'),
        foldableCot: stringRequired('Please select an option above'),
        sleepingMattress: stringRequired('Please select an option above'),
        usedByOtherMember: stringRequired('Please select an option above'),
        fittedSheetReach: stringRequired('Please select an option above'),
        accessChildrenNotAbleToWalk: stringRequired('Please select an option above'),
        IsRubber: stringRequired('Please select an option above'),
        vinylMattressProtector: stringRequired('Please select an option above'),
      }),

      pets: Yup.object({
        petAccessible: stringRequired('Please select an option above'),
        informedOfAllPets: stringRequired('Please select an option above'),
        petLitterBoxesReach: stringRequired('Please select an option above'),
      }),

      vehicles: Yup.object({
        carType: stringRequired('This field is required'),
        carRego: stringRequired('This field is required'),
        awareChildBetween: stringRequired('Please select an option above'),
        slotsAboveShoulders: stringRequired('Please select an option above'),
        moveStrapsHigher: stringRequired('Please select an option above'),
        carSeats: Yup.array()
          .of(
            Yup.object({
              brandName: stringRequired('This field is required'),
              manufacturingDate: stringRequired('This field is required'),
            })
          )
          .min(1),

        boosters: Yup.array()
          .of(
            Yup.object({
              brandName: stringRequired('This field is required'),
              manufacturingDate: stringRequired('This field is required'),
            })
          )
          .min(1),
      }),
    }),
  }),
  Yup.object({routineExcursion: Yup.object(getExcursionSchema(false))}),
  Yup.object({parkExcursion: Yup.object(getExcursionSchema(false))}),
  Yup.object({libraryExcursion: Yup.object(getExcursionSchema(false))}),
  Yup.object({schoolDropOffExcursion: Yup.object(getExcursionSchema(true))}),
  Yup.object({schoolPickUpExcursion: Yup.object(getExcursionSchema(true))}),
  Yup.object({shoppingExcursion: Yup.object(getExcursionSchema(false))}),
  Yup.object({signature: Yup.string().required('Signature is required').nullable()}),
]

const riskAssessmentInit = {
  activity: '',
  hazardIdentified: '',
  riskAssessmentMatrix: '',
  eliminationControlMatrix: '',
  responsibleForControl: '',
  whenControlImplemented: '',
}

const excursionInit = {
  startDate: '',
  renewalDate: '',
  licenseNo: '',
  licenseExpiry: '',
  carRegistrationNumber: '',
  carNoOfSeats: '',
  secondCarRegistrationNumber: '',
  secondCarNoOfSeats: '',
  individualRequirements: '',
  carBoosters: '',
  departureAddress: '',
  destinationAddress: '',
  transportationChildren: [{name: '', dropOffAddress: '', pickUpAddress: ''}],
  transportationSchools: [{name: '', address: ''}],
  travelDaysTime: [{days: [''], time: ''}],
  travelTimeLocation: [{estimatedTime: '', location: ''}],
  // children: [{fullName: '', address: ''}],
  childrenPeriod: '',
  routeFile: '',
  transportType: '',
  safetyRequirement: '',
  safetyRequirementDetails: '',
  proposedActivity: '',
  numberOfAdults: '',
  adultWithSpecialisedSkills: '',
  numberOfChildren: '',
  anyWaterHazard: '',
  waterHazardDetails: '',
  enteringAndExitingProcess: '',
  embarkingAndDisembarkingProcess: '',
  excursionChecklist: [],
  riskAssessment: [
    riskAssessmentInit,
    riskAssessmentInit,
    riskAssessmentInit,
    riskAssessmentInit,
    riskAssessmentInit,
    riskAssessmentInit,
  ],
}

export const inits = [
  {
    basicInformation: {
      fullName: '',
      familyName: '',
      CRN: '',
      RANumber: '',
      dob: '',
      drivingLicense: '',
      ABN: '',
      email: '',
      sameAddress: false,
      contactPhone: [''],
      dayCareAddress: {
        streetAddress: [''],
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
      isBARRAddress: '',
      bushFireRisk: '',
      dayCareAtHome: '',
      isPoolOrSpa: '',
      postalAddress: {
        streetAddress: [''],
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
      isBankRupted: '',
      isCheckRefused: '',
      isChargeGuilty: '',
    },
  },
  {
    emergencyContact: {
      fullName: '',
      email: '',
      contactPhone: [''],
      residentialAddress: {
        streetAddress: [''],
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
      relationship: '',
    },
    operationDetails: {
      dateRegistered: '',
      dateRegistrationCeased: '',
      workingDays: [],
      workingTime: '',
    },
  },
  {
    qualificationsCompleted: [
      {
        certificate: '',
        completionDate: '',
        notes: '',
        files: '',
      },
    ],
    qualificationsInProgress: [],
  },
  {
    trainingsCompleted: [
      {
        certificate: 'First Aid',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
      {
        certificate: 'CPR',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
      {
        certificate: 'Advanced Diploma in Children’s Services',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
      {
        certificate: 'Anaphylaxis Management',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
      {
        certificate: 'Asthma Management',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
    ],
  },
  {
    securityDetails: {
      children: [
        {
          fullName: '',
          dob: '',
          policeCheckReport: '',
        },
      ],
      adults: [
        {
          fullName: '',
          dob: '',
          policeCheckReport: '',
          identificationNumber: '',
          expiryDate: '',
          workingWithChildrenCheck: '',
        },
      ],
    },
  },
  {
    statutoryDeclarationForm: '',
  },
  {
    medicalCertificate: '',
  },
  {educatorAgreementForm: ''},
  {
    agreedOnPolicy: '',
    signature: '',
    bankDetails: {
      accountHolderName: '',
      bankName: '',
      branchName: '',
      BSBNumber: '',
      accountNumber: '',
    },
  },
  {signature: ''},
  {
    signature: '',
    // educatorName: '',
    // educatorAddress: '',
    // selfComplianceSubmittedDate: '',
    educatorSelfAssessment: {
      emergencyEvacuationMap: {
        floorPlanOfHouse: '',
        garageDoors: '',
        frontYard: '',
        backYard: '',
        FDCRooms: '',
        externalDoors: '',
        comment: '',
      },
      doorSafety: {
        frontDoorSecured: '',
        backDoorSecured: '',
        externalDoorsSecured: '',
        KeysNotAccessible: '',
        comment: '',
      },
      keysRequiredInEmergency: {
        frontDoor: '',
        backDoor: '',
        otherDoor: '',
        comment: '',
      },
      emergencyEvacuation: {
        collectEmergencyEvacuationOutsideTheHouse: '',
        accessToSnacksAndWater: '',
        firstAidKitStocked: '',
        haveAccessToWipes: '',
        haveFolder: '',
        childMedicalContacts: '',
        guardianNumbers: '',
        emergencyNumbers: '',
        riskManagementForms: '',
        comment: '',
      },
      kitchen: {
        KitchenAreaUsed: '',
        KitchenAreaSecure: '',
        comment: '',
      },
      lockDownRoom: {
        whichRoom: '',
        doorLocked: '',
        cleanWater: '',
        communicationEquipment: '',
        comment: '',
      },
      tv: {
        securedTv: '',
        glassTVsSecured: '',
        TVCabinetFrontCovered: '',
        understandGuidelines: '',
        fourHoursPerDay: '',
        comment: '',
      },
      indoorSpace: {
        furnitureFixedToWalls: '',
        cordsReach: '',
        electricalSafety: '',
        electricalCordsReach: '',
        furnitureCornerCovers: '',
        sharpObjects: '',
        unstableFurniture: '',
        lightersReach: '',
        trippingHazards: '',
        safetyGuards: '',
        toysStored: '',
        walkwaysClear: '',
        medicationsRemoved: '',
        equipmentAgeAppropriate: '',
        smallerObjectsStored: '',
        shatterRestraintFilm: '',
        comment: '',
      },
      bathrooms: {
        glassFilmProtection: '',
        electricalAppliances: '',
        unusedPowerPointsSafety: '',
        waterSystemTurnedDown: '',
        bathAndSinkPlugs: '',
        poisonsAndToiletReach: '',
        sharpObjectsStored: '',
        floorsKeptDry: '',
        matsArePlaced: '',
        curtainCordsReach: '',
        cosmeticsProducts: '',
        bathroomClean: '',
        toiletAccessible: '',
        washingSink: '',
        comment: '',
      },
      laundryRoom: {
        laundryUsedForFDC: '',
        safetyMeasures: '',
        electricalAppliancesInLaundry: '',
        detergentsKeptAway: '',
        comment: '',
      },
      toysResources: {
        caredAndRemovedToys: '',
        equipmentSharpEdgesReach: '',
        toysAreNonToxic: '',
        highchairsFitted: '',
        boxLidsSecure: '',
        comment: '',
      },
      trampoline: {
        haveTrampoline: '',
        approvedSafetyNetInstalled: '',
        superviseUsed: '',
        jumpingChildren: '',
        comment: '',
      },
      swimmingPoolSpa: {
        haveSwimmingPool: '',
        fenceAroundYourPool: '',
        objectsEnable: '',
        gateLocked: '',
        safetyAndResuscitation: '',
        poolAreSecured: '',
        climbObjectRemoved: '',
        spaFilterReach: '',
        allowEnrolledFDC: '',
        comment: '',
      },
      playFixtures: {
        secureWorkingOrder: '',
        maximiseStability: '',
        thoroughfares: '',
        sufficientDrop: '',
        highEquipmentSoftArea: '',
        comment: '',
      },
      outdoorSpace: {
        deckingGoodRepair: '',
        deckingTheCorrectDepth: '',
        outsideAreaRepair: '',
        plantsInTheGarden: '',
        anyClimbableTrees: '',
        anyDrains: '',
        ensureChildrenPreschool: '',
        shadeClothIsAttached: '',
        doorFullyFunctional: '',
        powerPointsReach: '',
        allHolesReach: '',
        fishpondAppropriatelyReach: '',
        allBucketsBowls: '',
        poolsAndBucketsEmpty: '',
        outsideAreaIsClean: '',
        comment: '',
      },
      doubleStoreyHouse: {
        houseTwoStorey: '',
        accessToUpstairs: '',
        stairsHaveSecureLocked: '',
        comment: '',
      },
      roomsNotIdentified: {
        roomDescription: '',
        comment: '',
      },
      SIDSSafeSleepingGuideline: {
        babiesSleepInCot: '',
        schoolAgeChildrenSleepInFloor: '',
        allowSleepOnAnotherBed: '',
        sleepingSafely: '',
        babyFaceCovered: '',
        tummyPlayEncouraged: '',
        coverTheirHead: '',
        mattressShouldClean: '',
        heatersReach: '',
        mustSleepInCot: '',
        sleepingOnFloor: '',
        // haveBedsAndProperlyLabelled: '',
        enoughCotSheets: '',
        furnitureGuarded: '',
        preventShattering: '',
        openedMoreThan100mm: '',
        powerPointsReach: '',
        withStandardASNZS: '',
        cotBedLinenConforms: '',
        pillowsAndMobiles: '',
        toiletriesMedication: '',
        smallerObjectsReach: '',
        curtainAndBlindCordsReach: '',
        electricalCordsReach: '',
        bunkBedsReach: '',
        bedroomClean: '',
        comment: '',
      },
      sleepingArrangements: {
        fixedCot: '',
        foldableCot: '',
        sleepingMattress: '',
        usedByOtherMember: '',
        fittedSheetReach: '',
        accessChildrenNotAbleToWalk: '',
        IsRubber: '',
        vinylMattressProtector: '',
        comment: '',
      },
      pets: {
        dog: '',
        cat: '',
        birds: '',
        others: '',
        petAccessible: '',
        informedOfAllPets: '',
        petLitterBoxesReach: '',
        comment: '',
      },
      vehicles: {
        carType: '',
        carRego: '',
        awareChildBetween: '',
        slotsAboveShoulders: '',
        moveStrapsHigher: '',
        carSeats: [
          {
            brandName: '',
            manufacturingDate: '',
          },
        ],
        boosters: [
          {
            brandName: '',
            manufacturingDate: '',
          },
        ],
      },
    },
  },
  {routineExcursion: excursionInit},
  {parkExcursion: excursionInit},
  {libraryExcursion: excursionInit},
  {schoolDropOffExcursion: excursionInit},
  {schoolPickUpExcursion: excursionInit},
  {shoppingExcursion: excursionInit},
  {signature: ''},
]

export const educatorKeysData: {keys: string[]}[] = [
  {
    keys: ['basicInformation'],
  },
  {
    keys: ['emergencyContact', 'operationDetails'],
  },
  {
    keys: ['qualificationsCompleted', 'qualificationsInProgress'],
  },
  {
    keys: ['trainingsCompleted'],
  },
  {
    keys: ['securityDetails'],
  },
  {
    keys: ['statutoryDeclarationForm'],
  },
  {
    keys: ['medicalCertificate'],
  },
  {
    keys: ['agreedOnPolicy', 'bankDetails', 'signature'],
  },
  {
    keys: ['compliance', 'signature'],
  },
  {
    keys: ['educatorSelfAssessment', 'signature'],
  },
  {
    keys: ['compliance'],
  },
  {
    keys: ['routineExcursion'],
  },
  {
    keys: ['parkExcursion'],
  },
  {
    keys: ['libraryExcursion'],
  },
  {
    keys: ['schoolDropOffExcursion'],
  },
  {
    keys: ['schoolPickUpExcursion'],
  },
  {
    keys: ['shoppingExcursion'],
  },
  {
    keys: ['signature', 'riskAssessmentSignedDate'],
  },
]

export const days = [
  {
    value: 'Monday',
    label: 'Monday',
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
  },
  {
    value: 'Thursday',
    label: 'Thursday',
  },
  {
    value: 'Friday',
    label: 'Friday',
  },
  {
    value: 'Saturday',
    label: 'Saturday',
  },
]

export const certifications = [
  {
    label: 'Certificate III in Children’s Services',
    value: 'Certificate III in Children’s Services',
  },
  {label: 'Diploma in Children’s Services', value: 'Diploma in Children’s Services'},
  {
    label: 'Advanced Diploma in Children’s Services',
    value: 'Advanced Diploma in Children’s Services',
  },
  {label: 'Bachelor of Education', value: 'Bachelor of Education'},
  {
    label: 'Other relevant qualification, please give',
    value: 'Other relevant qualification, please give',
  },
]

export const mandatoryTraining = [
  {
    label: 'First Aid',
    value: 'First Aid',
  },
  {label: 'CPR', value: 'CPR'},
  {
    label: 'Advanced Diploma in Children’s Services',
    value: 'Advanced Diploma in Children’s Services',
  },
  {label: 'Anaphylaxis Management', value: 'Anaphylaxis Management'},
  {
    label: 'Asthma Management',
    value: 'Asthma Management',
  },
]

export const EducatorSteps = [
  {
    title: 'Application Forms',
    description: 'Setup your account details',
    stepValue: '1',
  },
  {
    title: 'Emergency Contact & Operations',
    description: 'Your emergency contact details',
    stepValue: '2',
  },
  {
    title: 'Educator Qualification',
    description: 'Your certifications details',
    stepValue: '3',
  },
  {
    title: 'Training',
    description: 'Your training details',
    stepValue: '4',
  },
  {
    title: 'Security Check',
    description: 'Enter your security details',
    stepValue: '5',
  },
  {
    title: 'Statutory Declaration',
    description: 'Statutory Declarations Act',
    stepValue: '6',
  },
  {
    title: 'Medical Certificate',
    description: 'Your medical certification details',
    stepValue: '7',
  },
  {
    title: 'Educator Agreement',
    description: 'Please fill out the form',
    stepValue: '8',
  },
  {
    title: 'Compliance',
    description: 'Compliance Form',
    stepValue: '9',
  },
  {
    title: 'Educator Self Assessment',
    description: 'Educator Self Assessment',
    stepValue: '10',
  },

  {
    title: 'Routine Excursion',
    description: '',
    subStep: true,
    stepValue: '11.1',
  },
  {
    title: 'Park Excursion',
    description: '',
    subStep: true,
    stepValue: '11.2',
  },
  {
    title: 'Library Excursion',
    description: '',
    subStep: true,
    stepValue: '11.3',
  },
  {
    title: 'School Drop off',
    description: '',
    subStep: true,
    stepValue: '11.4',
  },
  {
    title: 'School Pick up',
    description: '',
    subStep: true,
    stepValue: '11.5',
  },
  {
    title: 'Shopping Center',
    description: '',
    subStep: true,
    stepValue: '11.6',
  },
  {
    title: 'Declaration and Risk Matrix',
    description: '',
    subStep: true,
    stepValue: '11.7',
  },
]
export const regions = ['Area 1', 'Area 2', 'Area 3', 'Area 4', 'Area 5']

export const allStatus = [
  {label: 'Pending', value: 'Pending'},
  {label: 'Approved', value: 'Approved'},
  {label: 'Rejected', value: 'Rejected'},
  {label: 'InProgress', value: 'In Progress'},
  {label: 'Ceased', value: 'Ceased'},
]

export const selfAssessmentForm = [
  {
    heading: 'Safety Measure 1: Emergency Evacuation Map',
    id: 'safetyMeasure1',
    subHeading: '',
    fields: [
      {
        label: 'Floor plan of the house is clearly displayed near the front door',
        field: 'educatorSelfAssessment.emergencyEvacuationMap.floorPlanOfHouse',
        fieldType: 'radio',
      },
      {
        label: `Any side gates or garage doors that may need to be used to exit the back yard in the event
        the house cannot be accessed`,
        field: 'educatorSelfAssessment.emergencyEvacuationMap.garageDoors',
        fieldType: 'radio',
      },

      {
        label: `The front yard fenced area`,
        field: 'educatorSelfAssessment.emergencyEvacuationMap.frontYard',
        fieldType: 'radio',
      },
      {
        label: `The back yard fenced area`,
        field: 'educatorSelfAssessment.emergencyEvacuationMap.backYard',
        fieldType: 'radio',
      },
      {
        label: `Any rooms that will not be used for FDC (rooms that are out of bounds for children)`,
        field: 'educatorSelfAssessment.emergencyEvacuationMap.FDCRooms',
        fieldType: 'radio',
      },
      {
        label: `Any external doors that can be used to exit the building`,
        field: 'educatorSelfAssessment.emergencyEvacuationMap.externalDoors',
        fieldType: 'radio',
      },
      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.emergencyEvacuationMap.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: 'Safety Measure 2: Door safety',
    id: 'safetyMeasure2',
    subHeading: '',
    fields: [
      {
        label: 'The front door is secured',
        field: 'educatorSelfAssessment.doorSafety.frontDoorSecured',
        fieldType: 'radio',
      },
      {
        label: `The back door is secured`,
        field: 'educatorSelfAssessment.doorSafety.backDoorSecured',
        fieldType: 'radio',
      },

      {
        label: `Any other external doors are secured`,
        field: 'educatorSelfAssessment.doorSafety.externalDoorsSecured',
        fieldType: 'radio',
      },
      {
        label: `Keys are not accessible by children`,
        field: 'educatorSelfAssessment.doorSafety.KeysNotAccessible',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.doorSafety.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 3: You have all the keys required to exit your home in the event of an
    emergency`,
    id: 'safetyMeasure3',
    subHeading: '',
    fields: [
      {
        label: 'The Front Door',
        field: 'educatorSelfAssessment.keysRequiredInEmergency.frontDoor',
        fieldType: 'radio',
      },
      {
        label: `Back Door`,
        field: 'educatorSelfAssessment.keysRequiredInEmergency.backDoor',
        fieldType: 'radio',
      },

      {
        label: `Other doors if applicable Side gate Side garage doors`,
        field: 'educatorSelfAssessment.keysRequiredInEmergency.otherDoor',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.keysRequiredInEmergency.comment',
        fieldType: 'textarea',
      },
    ],
  },
  {
    heading: `Safety Measure 4: Emergency Evacuation/Excursion Bag`,
    id: 'safetyMeasure4',
    subHeading: '',
    fields: [
      {
        label: `An emergency evacuation bag or excursion bag is up to date and stored near the main exit
        to the house so you can collect it on the way out of your house.`,
        field:
          'educatorSelfAssessment.emergencyEvacuation.collectEmergencyEvacuationOutsideTheHouse',
        fieldType: 'radio',
      },
      {
        label: `Did you have access to snacks and water (consider bottled water and long life biscuits)`,
        field: 'educatorSelfAssessment.emergencyEvacuation.accessToSnacksAndWater',
        fieldType: 'radio',
      },

      {
        label: `Is your First aid kit stocked and up to date?`,
        field: 'educatorSelfAssessment.emergencyEvacuation.firstAidKitStocked',
        fieldType: 'radio',
      },
      {
        label: `Did you have access to spare nappies, wipes and gloves.`,
        field: 'educatorSelfAssessment.emergencyEvacuation.haveAccessToWipes',
        fieldType: 'radio',
      },
      {
        label: `Do you have a folder or place to store the following information in your bag?`,
        field: 'educatorSelfAssessment.emergencyEvacuation.haveFolder',
        fieldType: 'radio',
      },
      {
        label: `Child Medical Contacts?`,
        field: 'educatorSelfAssessment.emergencyEvacuation.childMedicalContacts',
        fieldType: 'radio',
      },
      {
        label: `All parent/guardian numbers?`,
        field: 'educatorSelfAssessment.emergencyEvacuation.guardianNumbers',
        fieldType: 'radio',
      },
      {
        label: `Child emergency numbers?`,
        field: 'educatorSelfAssessment.emergencyEvacuation.emergencyNumbers',
        fieldType: 'radio',
      },
      {
        label: 'Excursion Risk Management Forms',
        field: 'educatorSelfAssessment.emergencyEvacuation.riskManagementForms',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.emergencyEvacuation.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 5: Kitchen`,
    id: 'safetyMeasure5',
    subHeading: '',
    fields: [
      {
        label: `Kitchen area will be used for FDC service`,
        field: 'educatorSelfAssessment.kitchen.KitchenAreaUsed',
        fieldType: 'radio',
      },
      {
        label: 'Kitchen area has a secure door',
        field: 'educatorSelfAssessment.kitchen.KitchenAreaSecure',
        fieldType: 'radio',
      },
      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.kitchen.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 6: Lock down room`,
    id: 'safetyMeasure6',
    subHeading: `A lock down room is a room you can escape to if directed to by emergency services. This
    could be in the event of a terror attack, a dangerous criminal in the neighbourhood or because
    of a intruder or hostage situation. Bathrooms make great lock down rooms.`,
    fields: [
      {
        label: `Which room will be used for a lock down room?`,
        field: 'educatorSelfAssessment.lockDownRoom.whichRoom',
        fieldType: 'text',
      },
      {
        heading: `Please confirm the following features of your lock down room:`,
        label: 'Door can be locked from inside',
        field: 'educatorSelfAssessment.lockDownRoom.doorLocked',
        fieldType: 'radio',
      },
      {
        label: 'Access to clean water',
        field: 'educatorSelfAssessment.lockDownRoom.cleanWater',
        fieldType: 'radio',
      },
      {
        label: 'Access to communication equipment – e.g. mobile phone',
        field: 'educatorSelfAssessment.lockDownRoom.communicationEquipment',
        fieldType: 'radio',
      },
      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.lockDownRoom.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 7: TV`,
    id: 'safetyMeasure7',
    subHeading: `Please confirm the following in relation to your TV:`,
    fields: [
      {
        label: `TV is secured`,
        field: 'educatorSelfAssessment.tv.securedTv',
        fieldType: 'radio',
      },
      {
        label: `Glass TVs secured to TV cabinets or walls to prevent falls`,
        field: 'educatorSelfAssessment.tv.glassTVsSecured',
        fieldType: 'radio',
      },
      {
        label: `Glass in the TV cabinet front is covered with safety to prevent shattering`,
        field: 'educatorSelfAssessment.tv.TVCabinetFrontCovered',
        fieldType: 'radio',
      },
      {
        label: `That you understand Australian medical guidelines state that more than 2 hours of screen
        time per day causes brain damage`,
        field: 'educatorSelfAssessment.tv.understandGuidelines',
        fieldType: 'radio',
      },
      {
        label: `Given that the average Australian child watches 4 hours per day there is no reason for
        children to be using screens during a paid education service`,
        field: 'educatorSelfAssessment.tv.fourHoursPerDay',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.tv.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 8: Indoor Space`,
    id: 'safetyMeasure8',
    subHeading: `Please confirm the following features of your indoor play spaces`,
    fields: [
      {
        label: `All furniture that a child could climb and fall 1.5 metres from removed, securely fixed to
        walls or guarded`,
        field: 'educatorSelfAssessment.indoorSpace.furnitureFixedToWalls',
        fieldType: 'radio',
      },
      {
        label: `Curtain and blind cords out of reach of children`,
        field: 'educatorSelfAssessment.indoorSpace.cordsReach',
        fieldType: 'radio',
      },
      {
        label: `All electrical power points have safety covers in place`,
        field: 'educatorSelfAssessment.indoorSpace.electricalSafety',
        fieldType: 'radio',
      },
      {
        label: `All electrical cords and leads keep out of reach of children`,
        field: 'educatorSelfAssessment.indoorSpace.electricalCordsReach',
        fieldType: 'radio',
      },
      {
        label: `Furniture with sharp edges to have corner covers`,
        field: 'educatorSelfAssessment.indoorSpace.furnitureCornerCovers',
        fieldType: 'radio',
      },
      {
        label: `Fragile or sharp objects put away`,
        field: 'educatorSelfAssessment.indoorSpace.sharpObjects',
        fieldType: 'radio',
      },
      {
        label: `Furniture and furnishing in a good condition - no unstable heavy furniture`,
        field: 'educatorSelfAssessment.indoorSpace.unstableFurniture',
        fieldType: 'radio',
      },
      {
        label: `Matches, fragrant oil burner, incense and lighters kept out of reach of children`,
        field: 'educatorSelfAssessment.indoorSpace.lightersReach',
        fieldType: 'radio',
      },
      {
        label: `No tripping hazards`,
        field: 'educatorSelfAssessment.indoorSpace.trippingHazards',
        fieldType: 'radio',
      },
      {
        label: `Safety guards for heating and cooling appliance in place`,
        field: 'educatorSelfAssessment.indoorSpace.safetyGuards',
        fieldType: 'radio',
      },
      {
        label: `Toys stored in an area to allow free passage after use`,
        field: 'educatorSelfAssessment.indoorSpace.toysStored',
        fieldType: 'radio',
      },
      {
        label: `All walkways clear`,
        field: 'educatorSelfAssessment.indoorSpace.walkwaysClear',
        fieldType: 'radio',
      },
      {
        label: `All alcohol, medications must be removed from sight and in a locked cupboard inaccessible
        to children`,
        field: 'educatorSelfAssessment.indoorSpace.medicationsRemoved',
        fieldType: 'radio',
      },
      {
        label: `Toys and equipment age appropriate`,
        field: 'educatorSelfAssessment.indoorSpace.equipmentAgeAppropriate',
        fieldType: 'radio',
      },
      {
        label: `Small toys, equipment and smaller objects stored out of reach to children under 3`,
        field: 'educatorSelfAssessment.indoorSpace.smallerObjectsStored',
        fieldType: 'radio',
      },
      {
        label: `Safety glass or shatter restraint film or hard barrier fitted to windows or glass doors, or
        barrier fitted on all glass below 600mm from floor height`,
        field: 'educatorSelfAssessment.indoorSpace.shatterRestraintFilm',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.tv.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 9: Bathrooms`,
    id: 'safetyMeasure9',
    subHeading: ``,
    fields: [
      {
        label: `All glass below 600 mm from floor height is safety glass or has an film protection affixed
        to it to prevent shattering`,
        field: 'educatorSelfAssessment.bathrooms.glassFilmProtection',
        fieldType: 'radio',
      },
      {
        label: `Electrical appliances unplugged, and cords kept out of reach of young children`,
        field: 'educatorSelfAssessment.bathrooms.electricalAppliances',
        fieldType: 'radio',
      },
      {
        label: `All unused power points have safety plugs`,
        field: 'educatorSelfAssessment.bathrooms.unusedPowerPointsSafety',
        fieldType: 'radio',
      },
      {
        label: `Hot water taps for the basin and bath out of reach, or unable to be opened by young
        children or hot water system turned down to prevent scolding`,
        field: 'educatorSelfAssessment.bathrooms.waterSystemTurnedDown',
        fieldType: 'radio',
      },
      {
        label: `Bath and sink plugs stored out of reach of young children`,
        field: 'educatorSelfAssessment.bathrooms.bathAndSinkPlugs',
        fieldType: 'radio',
      },
      {
        label: `Medicines, poisons, and toilet deodorising blocks stored in a locked cupboard out of reach
        of children`,
        field: 'educatorSelfAssessment.bathrooms.poisonsAndToiletReach',
        fieldType: 'radio',
      },
      {
        label: `Razors, scissors, and other sharp objects stored in a locked cupboard or out of reach of
        children`,
        field: 'educatorSelfAssessment.bathrooms.sharpObjectsStored',
        fieldType: 'radio',
      },
      {
        label: `Floors kept dry`,
        field: 'educatorSelfAssessment.bathrooms.floorsKeptDry',
        fieldType: 'radio',
      },
      {
        label: `Non-slip mats are placed`,
        field: 'educatorSelfAssessment.bathrooms.matsArePlaced',
        fieldType: 'radio',
      },
      {
        label: `All curtain and blind cords out of reach of children`,
        field: 'educatorSelfAssessment.bathrooms.curtainCordsReach',
        fieldType: 'radio',
      },
      {
        label: `Cosmetics products , soaps stored away`,
        field: 'educatorSelfAssessment.bathrooms.cosmeticsProducts',
        fieldType: 'radio',
      },
      {
        label: `Bathroom clean and hygienic`,
        field: 'educatorSelfAssessment.bathrooms.bathroomClean',
        fieldType: 'radio',
      },
      {
        label: `Toilet is accessible with either portable potty, seat insert and step to enable access for small
        children`,
        field: 'educatorSelfAssessment.bathrooms.toiletAccessible',
        fieldType: 'radio',
      },
      {
        label: `Hand washing sink is accessible with a small step`,
        field: 'educatorSelfAssessment.bathrooms.washingSink',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.bathrooms.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 10: Laundry Room`,
    id: 'safetyMeasure10',
    subHeading: ``,
    fields: [
      {
        label: `Will the laundry room be used for FDC?`,
        field: 'educatorSelfAssessment.laundryRoom.laundryUsedForFDC',
        fieldType: 'radio',
      },

      {
        label: `Are the safety measures up to date`,
        field: 'educatorSelfAssessment.laundryRoom.safetyMeasures',
        fieldType: 'radio',
      },
      {
        label: `Electrical appliances in the laundry room are unplugged, and cords kept out of reach of
        young children`,
        field: 'educatorSelfAssessment.laundryRoom.electricalAppliancesInLaundry',
        fieldType: 'radio',
      },
      {
        label: `Detergents are kept away in a locked cupboard out of the reach of the children
        Comment:`,
        field: 'educatorSelfAssessment.laundryRoom.detergentsKeptAway',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.laundryRoom.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 11: Toys and Resources`,
    id: 'safetyMeasure11',
    subHeading: `In relation to your toys and resources please confirm the following:`,
    fields: [
      {
        label: `If children under 3 are to be cared for have all toys and objects smaller than a 50-cent piece
        been removed?`,
        field: 'educatorSelfAssessment.toysResources.caredAndRemovedToys',
        fieldType: 'radio',
      },
      {
        label: `Any equipment/ activities with sharp edges have these been removed or managed`,
        field: 'educatorSelfAssessment.toysResources.equipmentSharpEdgesReach',
        fieldType: 'radio',
      },
      {
        label: `Equipment and toys are non-toxic toys and equipment available is age appropriate`,
        field: 'educatorSelfAssessment.toysResources.toysAreNonToxic',
        fieldType: 'radio',
      },
      {
        label: `All highchairs fitted with full functioning, 5-point harnesses`,
        field: 'educatorSelfAssessment.toysResources.highchairsFitted',
        fieldType: 'radio',
      },
      {
        label: `Toy box lids secure, toy boxes must have slow closing, soft struts Warning Labels on toys
        checked before buying All toys, equipment, and resources clean and hygienic`,
        field: 'educatorSelfAssessment.toysResources.boxLidsSecure',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.toysResources.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 12: Trampoline`,
    id: 'safetyMeasure13',
    subHeading: ``,
    fields: [
      {
        label: `Do you have a trampoline? Yes/No`,
        field: 'educatorSelfAssessment.trampoline.haveTrampoline',
        fieldType: 'radio',
      },
      {
        label: `That there is an approved safety net installed to prevent children falling off`,
        field: 'educatorSelfAssessment.trampoline.approvedSafetyNetInstalled',
        fieldType: 'radio',
      },
      {
        label: `That you will supervise use at all times`,
        field: 'educatorSelfAssessment.trampoline.superviseUsed',
        fieldType: 'radio',
      },
      {
        label: `That you will ensure no more than 2 children of the same age are jumping at the same time`,
        field: 'educatorSelfAssessment.trampoline.jumpingChildren',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.trampoline.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 13: Swimming Pool or Spa`,
    id: 'safetyMeasure13',
    subHeading: `Please confirm the following in relation to your pool/spa:`,
    fields: [
      {
        label: `Do you have a swimming pool or spa?`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.haveSwimmingPool',
        fieldType: 'radio',
      },
      {
        label: `There is a fence around your pool/spa and that it is of regulation height`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.fenceAroundYourPool',
        fieldType: 'radio',
      },
      {
        label: `It is clear from objects that could enable a child to climb over the fence`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.objectsEnable',
        fieldType: 'radio',
      },
      {
        label: `Pool has a locked gate`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.gateLocked',
        fieldType: 'radio',
      },
      {
        label: `Pool safety and resuscitation posters are clearly displayed`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.safetyAndResuscitation',
        fieldType: 'radio',
      },
      {
        label: `Spa and Pool are securely covered`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.poolAreSecured',
        fieldType: 'radio',
      },
      {
        label: `All objects that could help a child to climb into the pool or spa removed`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.climbObjectRemoved',
        fieldType: 'radio',
      },
      {
        label: `Pool and spa filter out of reach of children`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.spaFilterReach',
        fieldType: 'radio',
      },
      {
        label: `That you will never allow enrolled FDC children to swim in your pool/spa`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.allowEnrolledFDC',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.swimmingPoolSpa.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 14: Play Fixtures`,
    id: 'safetyMeasure14',
    subHeading: `Please confirm the following in relation to permanent play fixtures (Swings/ cubbies/ mud
    kitchens):`,
    fields: [
      {
        label: `They are secure and in proper working order`,
        field: 'educatorSelfAssessment.playFixtures.secureWorkingOrder',
        fieldType: 'radio',
      },
      {
        label: `They are setup in a way to maximise stability`,
        field: 'educatorSelfAssessment.playFixtures.maximiseStability',
        fieldType: 'radio',
      },
      {
        label: `They are not in thoroughfares where people can be knocked`,
        field: 'educatorSelfAssessment.playFixtures.thoroughfares',
        fieldType: 'radio',
      },
      {
        label: `They have a sufficient drop/fall zones clear of hard or pointy objects`,
        field: 'educatorSelfAssessment.playFixtures.sufficientDrop',
        fieldType: 'radio',
      },
      {
        label: `High equipment has soft fall areas in place (tanbark/sports matts)`,
        field: 'educatorSelfAssessment.playFixtures.highEquipmentSoftArea',
        fieldType: 'radio',
      },
      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.playFixtures.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 15: Outdoor Space`,
    id: 'safetyMeasure15',
    subHeading: `Please confirm the following in relation to your outdoor space.`,
    fields: [
      {
        label: `Any decking, is in good repair`,
        field: 'educatorSelfAssessment.outdoorSpace.deckingGoodRepair',
        fieldType: 'radio',
      },
      {
        label: `Steps to the decking the correct depth and tread for ease of access`,
        field: 'educatorSelfAssessment.outdoorSpace.deckingTheCorrectDepth',
        fieldType: 'radio',
      },
      {
        label: `Outside area is in good repair`,
        field: 'educatorSelfAssessment.outdoorSpace.outsideAreaRepair',
        fieldType: 'radio',
      },
      {
        label: `Plants in the garden have been checked for poisons ad thorns`,
        field: 'educatorSelfAssessment.outdoorSpace.plantsInTheGarden',
        fieldType: 'radio',
      },
      {
        label: `There any no climbable trees in the garden`,
        field: 'educatorSelfAssessment.outdoorSpace.anyClimbableTrees',
        fieldType: 'radio',
      },
      {
        label: `Any drains, or gully traps are securely covered`,
        field: 'educatorSelfAssessment.outdoorSpace.anyDrains',
        fieldType: 'radio',
      },
      {
        label: `Ensure children preschool or under cannot go through, over or under the fence`,
        field: 'educatorSelfAssessment.outdoorSpace.ensureChildrenPreschool',
        fieldType: 'radio',
      },
      {
        label: `Shade cloth is attached to the inside of fence in case of risk of climbing by children`,
        field: 'educatorSelfAssessment.outdoorSpace.shadeClothIsAttached',
        fieldType: 'radio',
      },
      {
        label: `The fence or door has a fully functional child-resistant, self-latching gate or lock`,
        field: 'educatorSelfAssessment.outdoorSpace.doorFullyFunctional',
        fieldType: 'radio',
      },
      {
        label: `Any outside power points or switches are covered`,
        field: 'educatorSelfAssessment.outdoorSpace.powerPointsReach',
        fieldType: 'radio',
      },
      {
        label: `All holes, wells, trenches, and excavations adequately fenced and covered`,
        field: 'educatorSelfAssessment.outdoorSpace.allHolesReach',
        fieldType: 'radio',
      },
      {
        label: `Fishpond appropriately covered and fenced`,
        field: 'educatorSelfAssessment.outdoorSpace.fishpondAppropriatelyReach',
        fieldType: 'radio',
      },
      {
        label: `All buckets, bowls, drums, and any other water containers adequately covered or locked
        away`,
        field: 'educatorSelfAssessment.outdoorSpace.allBucketsBowls',
        fieldType: 'radio',
      },
      {
        label: `Wading pools and buckets empty of water when they are not being used`,
        field: 'educatorSelfAssessment.outdoorSpace.poolsAndBucketsEmpty',
        fieldType: 'radio',
      },
      {
        label: `The outside area is clean, tidy, and hygienic`,
        field: 'educatorSelfAssessment.outdoorSpace.outsideAreaIsClean',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.outdoorSpace.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 16: Double Storey House`,
    id: 'safetyMeasure16',
    subHeading: ``,
    fields: [
      {
        label: `Is the house 2 storey or more?`,
        field: 'educatorSelfAssessment.doubleStoreyHouse.houseTwoStorey',
        fieldType: 'radio',
      },
      {
        label: `It Yes, will the children have access to upstairs?`,
        field: 'educatorSelfAssessment.doubleStoreyHouse.accessToUpstairs',
        fieldType: 'radio',
      },
      {
        label: `If no, stairs have a secure locked gate at the starting point`,
        field: 'educatorSelfAssessment.doubleStoreyHouse.stairsHaveSecureLocked',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.doubleStoreyHouse.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 17: Other Rooms Not Identified`,
    id: 'safetyMeasure17',
    subHeading: ``,
    fields: [
      {
        label: `Please list the room names and description of purpose of each room`,
        field: 'educatorSelfAssessment.roomsNotIdentified.roomDescription',
        fieldType: 'text',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.roomsNotIdentified.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 18: Sudden Infant Death Syndrome SIDS Safe Sleeping Guideline`,
    id: 'safetyMeasure18',
    subHeading: `Do you intend to provide care to the following children:`,
    fields: [
      {
        label: `Babies that are not yet walking (must sleep in cot)`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.babiesSleepInCot',
        fieldType: 'radio',
      },
      {
        label: `Toddlers and school age children that are walking (sleep in a mattress on the floor)`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.schoolAgeChildrenSleepInFloor',
        fieldType: 'radio',
      },
      {
        label: `You confirm that you will never allow children to sleep in an adult bed or the bed of
        another child residing in the FDC premises`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.allowSleepOnAnotherBed',
        fieldType: 'radio',
      },
      {
        label: `To make sure your baby is sleeping safely, place them on their back in the cot and remove
        any loose quilts, toys, bumpers and blankets`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.sleepingSafely',
        fieldType: 'radio',
      },
      {
        label: `Ensure your baby’s head and face are uncovered`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.babyFaceCovered',
        fieldType: 'radio',
      },
      {
        label: `Babies who sleep on their tummies or sides are more at risk of Sudden Infant Death
        Syndrome (SIDS) as they are more likely to choke or suffocate. This is why tummy play is
        only encouraged during the day when your baby is supervised`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.tummyPlayEncouraged',
        fieldType: 'radio',
      },
      {
        label: `Place their feet at the end of the cot and remove anything that might cover their head (hats,
          hoods, etc.)`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.coverTheirHead',
        fieldType: 'radio',
      },
      {
        label: `The mattress should be clean and completely flat at for maximum comfort.`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.mattressShouldClean',
        fieldType: 'radio',
      },
      {
        label: `Make sure there are no cords from blinds or curtains in reach, and that all heaters and
        electric appliances are kept well away`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.heatersReach',
        fieldType: 'radio',
      },
      {
        label: `Children that are not yet walking must sleep in a cot`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.mustSleepInCot',
        fieldType: 'radio',
      },
      {
        label: `Children that are walking will be sleeping on the floor on a vinyl mattress`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.sleepingOnFloor',
        fieldType: 'radio',
      },
      {
        label: `Must purchase enough cot sheets for each child in care and each sheet should be properly
        labelled`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.enoughCotSheets',
        fieldType: 'radio',
      },
      {
        heading: `Please confirm following in relation to sleeping space`,
        label: `All furniture that a child could climb and fall 1.5 metres from removed, securely fixed, or
        guarded`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.furnitureGuarded',
        fieldType: 'radio',
      },
      {
        label: `All glass below 600 mm from floor height is safety glass or has a film protection affixed to
        it to prevent shattering`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.preventShattering',
        fieldType: 'radio',
      },
      {
        label: `All windows or other openings more than 1.5 metres above the ground fixed so they cannot
        be opened more than 100 mm (10cm)`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.openedMoreThan100mm',
        fieldType: 'radio',
      },
      {
        label: `All unused power points have safety plugs`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.powerPointsReach',
        fieldType: 'radio',
      },
      {
        label: `The cots comply with the Australian Standard AS/NZS 2172:2003`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.withStandardASNZS',
        fieldType: 'radio',
      },
      {
        label: `The cot bed linen conforms to the safe sleeping environment as outlined Safe Sleep Image
        Guide`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.cotBedLinenConforms',
        fieldType: 'radio',
      },
      {
        label: `All bumpers, pillows and mobiles removed from cots`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.pillowsAndMobiles',
        fieldType: 'radio',
      },
      {
        label: `Toiletries, medication, sleeping pills, perfume, jewellery, sharps, and plastic bags all out of
        reach of children`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.toiletriesMedication',
        fieldType: 'radio',
      },
      {
        label: `All objects smaller than a 50-cent piece out of reach of children`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.smallerObjectsReach',
        fieldType: 'radio',
      },
      {
        label: `All curtain and blind cords out of reach of children`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.curtainAndBlindCordsReach',
        fieldType: 'radio',
      },

      {
        label: `All electrical cords and leads kept out of reach of children`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.electricalCordsReach',
        fieldType: 'radio',
      },
      {
        label: `Bunk beds must not be used for family day care`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.bunkBedsReach',
        fieldType: 'radio',
      },
      {
        label: `The bedroom clean and hygienic`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.bedroomClean',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.SIDSSafeSleepingGuideline.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 19: Sleeping Arrangements`,
    id: 'safetyMeasure19',
    subHeading: `Type of cot`,
    fields: [
      {
        label: `Fixed Cot (non-folding type)`,
        field: 'educatorSelfAssessment.sleepingArrangements.fixedCot',
        fieldType: 'radio',
      },
      {
        label: `Foldable Cot`,
        field: 'educatorSelfAssessment.sleepingArrangements.foldableCot',
        fieldType: 'radio',
      },
      {
        label: `Sleeping Mattress`,
        field: 'educatorSelfAssessment.sleepingArrangements.sleepingMattress',
        fieldType: 'radio',
      },
      {
        heading: `Declarations:`,
        label: `The mattress will not be used by other members of the household`,
        field: 'educatorSelfAssessment.sleepingArrangements.usedByOtherMember',
        fieldType: 'radio',
      },
      {
        label: `Each child will have access to their own fitted sheet (no loose sheets allowed)`,
        field: 'educatorSelfAssessment.sleepingArrangements.fittedSheetReach',
        fieldType: 'radio',
      },
      {
        label: `Will not be used for children not able to walk`,
        field: 'educatorSelfAssessment.sleepingArrangements.accessChildrenNotAbleToWalk',
        fieldType: 'radio',
      },
      {
        heading: 'Mattresses have one of the following:',
        label: `Is rubber of vinyl`,
        field: 'educatorSelfAssessment.sleepingArrangements.IsRubber',
        fieldType: 'radio',
      },
      {
        label: `Has a rubber cover or vinyl mattress protector`,
        field: 'educatorSelfAssessment.sleepingArrangements.vinylMattressProtector',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.sleepingArrangements.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 20: Pets`,
    id: 'safetyMeasure20',
    subHeading: `Any Pet at the service`,
    fields: [
      {
        label: `Dog`,
        field: 'educatorSelfAssessment.pets.dog',
        fieldType: 'checkbox',
      },
      {
        label: `Cat`,
        field: 'educatorSelfAssessment.pets.cat',
        fieldType: 'checkbox',
      },

      {
        label: `Birds`,
        field: 'educatorSelfAssessment.pets.birds',
        fieldType: 'checkbox',
      },
      {
        label: `Others`,
        field: 'educatorSelfAssessment.pets.others',
        fieldType: 'text',
      },

      {
        heading: 'Declaration',
        label: `Family pet must be in an area that is only accessible to children under supervision`,
        field: 'educatorSelfAssessment.pets.petAccessible',
        fieldType: 'radio',
      },

      {
        label: `Families are informed of all pets/animals on the premises`,
        field: 'educatorSelfAssessment.pets.informedOfAllPets',
        fieldType: 'radio',
      },
      {
        label: `Pet litter boxes, feed dishes and water bowls are kept in an area inaccessible to children`,
        field: 'educatorSelfAssessment.pets.petLitterBoxesReach',
        fieldType: 'radio',
      },

      {
        label: `Comment:`,
        field: 'educatorSelfAssessment.pets.comment',
        fieldType: 'textarea',
      },
    ],
  },

  {
    heading: `Safety Measure 21: Vehicles`,
    id: 'safetyMeasure21',
    subHeading: `Type of cot`,
    fields: [
      {
        label: `Car type:`,
        field: 'educatorSelfAssessment.vehicles.carType',
        fieldType: 'text',
      },
      {
        label: `Car Rego:`,
        field: 'educatorSelfAssessment.vehicles.carRego',
        fieldType: 'text',
      },

      {
        label: `You are aware a child between 4 and 7 years must have a forward-facing child restraint
        with an inbuilt harness or booster seat`,
        field: 'educatorSelfAssessment.vehicles.awareChildBetween',
        fieldType: 'radio',
      },

      {
        label: `When putting the inbuilt harness on your child, its straps must come out of the slots just
        above their shoulders`,
        field: 'educatorSelfAssessment.vehicles.slotsAboveShoulders',
        fieldType: 'radio',
      },

      {
        label: `If the straps are more than 25mm below your child's shoulders, you will need to move the
        straps to a higher slot.`,
        field: 'educatorSelfAssessment.vehicles.moveStrapsHigher',
        fieldType: 'radio',
      },
    ],
  },
]

export const buses = [
  {
    value: 'public-bus',
    label: 'Public Bus',
  },

  {
    value: 'private-bus',
    label: 'Private Bus',
  },

  {
    value: 'private-car',
    label: 'Private Car',
  },

  {
    value: 'taxi',
    label: 'Taxi',
  },

  {
    value: 'tram',
    label: 'Tram',
  },

  {
    value: 'walk',
    label: 'Walk',
  },
]

export const riskAssessment = [
  'Activity',
  'Hazard identified',
  'Risk assessment(use matrix)',
  'Elimination/control measures',
  'Who responsible for control',
  'When(is control Implemented)',
]

import * as Yup from 'yup'
import 'yup-phone-lite'

export const settingsValidation = Yup.object().shape({
  adminEmail: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export const initialSettings = {
  adminEmail: 'admin@me2fdcs.com',
  id: '',
  settingsUpdated: '',
}

export const settingsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_VALUES':
      console.log('🚀 ~ file: SettingsHelpers.ts:19 ~ settingsReducer ~ action:', action)
      return {
        id: action.payload?._id,
        adminEmail: action.payload?.adminEmail,
      }

    case 'SET_MESSAGE':
      return {
        settingsUpdated: action.payload,
      }
    default:
      return state
  }
}

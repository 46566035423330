import React from 'react'
import {useListView} from '../../core/ListViewProvider'
import {EducatorsListToolbar} from './EducatorsListToolbar'
import {EducatorsListGrouping} from './EducatorsListGrouping'
import {EducatorsListSearchComponent} from './EducatorsListSearchComponent'

const EducatorsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <EducatorsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <EducatorsListGrouping /> : <EducatorsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {EducatorsListHeader}

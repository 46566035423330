/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'

type Props = {
  educator: any
}

const statusClass: any = {
  Pending: 'badge-light-info',
  Approved: 'badge-light-success',
  Rejected: 'badge-light-danger',
  InProgress: 'badge-light-secondary text-dark',
  Ceased: 'badge-light-danger text-dark',
}

const EducatorStatusCell: FC<Props> = ({educator}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <span className={`badge ${statusClass[educator?.status || 'Rejected']}`}>
        {educator?.status === 'InProgress' ? 'In Progress' : educator?.status}
      </span>
    </div>
  </div>
)

export {EducatorStatusCell}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useQueryClient} from 'react-query'
import {Link, useParams} from 'react-router-dom'

type Props = {}

function getAge(dateString: string) {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

const ChildrenView: FC<Props> = ({}) => {
  const {id} = useParams()
  const [searchTerm, setSearchTerm] = useState('')
  const queryClient = useQueryClient()
  const educatorData: any = queryClient.getQueryData(['educatorData', id])
  return (
    <div className='card border border-2'>
      <div className='card-title'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative m-3'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search children'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* end::Search */}
      </div>
      <div className='card-header pt-9 border-0'>
        <div className='fs-3 '>
          <span className='fw-bold text-dark'>Total Children:</span>{' '}
          {educatorData?.children?.length || 0}
        </div>
      </div>
      <div className='row p-5'>
        {educatorData?.children?.map((child: any) => (
          <div className='col-4 mt-2'>
            <div className='card border border-1 pt-9'>
              <div className='card-body text-center'>
                <div className='symbol symbol-circle symbol-100px'>
                  <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
                </div>
                <div className='fw-bold mt-2'>{child?.child?.fullName}</div>
                <div className='text-muted'>Age: {getAge(child?.child?.dob) || 0}</div>
                <div className='text-muted'>
                  Centrelink Reference:{' '}
                  {child[child?.guardian?.centrelinkRecord]?.fullName || 'N/A'}
                </div>
                <div className='text-muted'>Day and Time: Monday - Tuesday 7 am - 1 pm</div>
                <div>
                  <Link
                    className='btn btn-sm btn-primary mt-1'
                    to={`/apps/child-management/children/${child?._id}/view`}
                  >
                    {' '}
                    View Details
                  </Link>
                </div>
                <div>
                  <button className='btn btn-sm btn-danger mt-1'> Cease Care</button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export {ChildrenView}

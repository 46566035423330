import {ID, Response} from '../../../../../../_metronic/helpers'

export type Role = {
  _id?: string
  name?: string
  permissions?: {}
  status?: boolean
  createdAt?: string
}

export type RolesQueryResponse = Response<Array<Role>>

export const initialUser: Role = {
  name: '',
  permissions: {},
}

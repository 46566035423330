import {Field} from 'formik'
import {FC, useEffect, useRef} from 'react'
import Collapsible from '../../../common/Collapsible'
import DatePicker from '../../../common/DatePicker'
import {SignatureField} from '../../../common/SignatureField'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

interface Props {
  values: any
  setFieldValue: any
  field: string
}

export const EducatorDeclaration: FC<Props> = ({values, setFieldValue, field}) => {
  useEffect(() => {
    if (!values?.riskAssessmentSignedDate) {
      setFieldValue('riskAssessmentSignedDate', new Date())
    }
  }, [])
  return (
    <Collapsible title={`Educator Declaration`} id={`risk-management-educator-declaration`}>
      <div className='row'>
        <div className='col-12'>
          I have read and understood all the risks and controls associated with this activity. I
          have completed the necessary actions to implement the controls including communicating any
          responsibilities to relevant parties. I have purchased the required safety equipment as
          specified and inspected it to ensure it is in full manufacturing condition and complies
          with any applicable Australian standards.
        </div>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className=''>Educator Name:</span>
          </label>
          <Field
            className='form-control form-control-lg form-control-solid me-2'
            type='text'
            placeholder='Educator Name'
            name='basicInformation.fullName'
            disabled
          ></Field>
        </div>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className=''>Date:</span>
          </label>
          <DatePicker
            name={`riskAssessmentSignedDate`}
            selected={
              values?.riskAssessmentSignedDate
                ? new Date(values?.riskAssessmentSignedDate)
                : new Date()
            }
            // value={new Date()}}
            placeholderText='Date'
            onChange={(value: any) => setFieldValue(`riskAssessmentSignedDate`, value)}
            disabled
          />
        </div>
        <div className='row'>
          <div className='col-6'>
            <SignatureField
              title='Signature'
              field='signature'
              value={values?.signature}
              setFieldValue={setFieldValue}
              disabled={true}
            />
          </div>
        </div>

        <img src={toAbsoluteUrl('/riskAssessment_matrix.png')} />
      </div>
    </Collapsible>
  )
}

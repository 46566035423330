import {FieldArray} from 'formik'
import {BrandManufacturing} from './BrandManufacturing'
import {FC} from 'react'

interface Props {
  values: any
  setFieldValue: any
}
export const VehiclesBrands: FC<Props> = ({values, setFieldValue}) => {
  return (
    <>
      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
        <span className=''>How many car seats?</span>
      </label>

      <FieldArray
        name='educatorSelfAssessment.vehicles.carSeats'
        render={(arrayHelpers: any) => (
          <BrandManufacturing
            values={values?.educatorSelfAssessment?.vehicles?.carSeats}
            arrayHelpers={arrayHelpers}
            setFieldValue={setFieldValue}
            field='educatorSelfAssessment.vehicles.carSeats'
            fieldLabels={{
              brandName: 'Brand Name',
              manufacturingDate: 'Manufacturing Date',
            }}
          />
        )}
      />

      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
        <span className=''>How many boosters?</span>
      </label>

      <FieldArray
        name='educatorSelfAssessment.vehicles.boosters'
        render={(arrayHelpers: any) => (
          <BrandManufacturing
            values={values?.educatorSelfAssessment?.vehicles?.boosters}
            arrayHelpers={arrayHelpers}
            setFieldValue={setFieldValue}
            field='educatorSelfAssessment.vehicles.boosters'
            fieldLabels={{
              brandName: 'Brand Name',
              manufacturingDate: 'Manufacturing Date',
            }}
          />
        )}
      />
    </>
  )
}

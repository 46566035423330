import React from 'react'
import {uploadSingleFile} from './_request'
import {ErrorMessage, Field} from 'formik'

interface UploadFileProps {
  label?: string
  field: string
  lastStep?: boolean
  setFieldValue: (field: string, value: string) => void
  value: any
}
const API_URL = process.env.REACT_APP_API_URL

const UploadFile: React.FC<UploadFileProps> = ({
  setFieldValue,
  label = '',
  lastStep = false,
  field,
  value,
}) => {
  return (
    <>
      {label && (
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>{label}</span>
        </label>
      )}

      <div className='row'>
        <Field
          type='file'
          className='form-control form-control-lg form-control-solid'
          name={field}
          placeholder={label}
          value={undefined}
          onChange={async (e: any) => {
            if (e.target.files[0]) {
              const formData = new FormData()
              formData.append('file', e.target.files[0])
              const uploadedFile: any = await uploadSingleFile(formData)
              setFieldValue(field, uploadedFile.data.path)
              // if (lastStep) {
              //   setFieldValue('status', 'Pending')
              // }
            }
          }}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={field} />
        </div>
        {value && (
          <a href={`${API_URL}/${value}`} className='d-flex justify-content-center' target='_blank'>
            {value}
          </a>
        )}
      </div>
    </>
  )
}

export default UploadFile

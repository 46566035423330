/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  educator: any
}

const EducatorAddress: FC<Props> = ({educator}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      {educator?.basicInformation &&
        `${educator?.basicInformation?.postalAddress?.streetAddress}, ${
          educator?.basicInformation?.postalAddress?.suburb ||
          educator?.basicInformation?.postalAddress?.city
        }, ${educator?.basicInformation?.postalAddress?.country}`}
    </div>
  </div>
)

export {EducatorAddress}

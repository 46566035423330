import {ID, Response} from '../../../../../../_metronic/helpers'
export type User = {
  status?: string
  _id?: string
  password?: string | undefined
  email?: string
  firstName?: string
  lastName?: string
  contact?: string
  confirmPassword?: string
  fullName?: string
  role?: string
  roleId?: string | null
  createdAt?: string
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  role: '',
  contact: '',
}

import React, {useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {updateRoleStatus} from '../core/_requests'
import {useListView} from '../core/ListViewProvider'
interface Props {}

const RoleStatusModal: React.FC<Props> = () => {
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {roleStatus, roleId, setRoleId, setRoleStatus} = useListView()

  const handleClose = () => {
    setRoleId?.(undefined)
    // setRoleStatus?.(false)
  }

  const updateStatus = useMutation(
    () => updateRoleStatus({status: !roleStatus as boolean, id: roleId}),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        handleClose()
      },
    }
  )

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        id='kt_modal_select_location'
        className='modal fade show d-block'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{!roleStatus ? 'Enable Role' : 'Disable Role'} </h5>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={handleClose}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
              </div>
            </div>
            <div className='modal-body'>
              <p>
                {!roleStatus
                  ? 'Are you sure want to enable this role?'
                  : 'Are you sure want to disable this role?'}{' '}
              </p>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light-primary' onClick={handleClose}>
                No
              </button>
              <button
                id='submit'
                type='button'
                className='btn btn-primary'
                onClick={() => updateStatus.mutateAsync()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {RoleStatusModal}

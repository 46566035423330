/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='text-white menu-section  text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/educator-management/educators'
        icon='/media/icons/duotune/communication/com014.svg'
        title='Educators'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/apps/educator-management/educators' title='List' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/child-management/children'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Parents & Children'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/apps/child-management/children' title='List' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/user-management/users'
        icon='/media/icons/duotune/communication/com006.svg'
        title='User management'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/apps/user-management/users' title='User List' hasBullet={true} />
        <SidebarMenuItem to='/apps/user-management/roles' title='Roles List' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/settings'
        icon='/media/icons/duotune/communication/com013.svg'
        title='General Settings'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/apps/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}

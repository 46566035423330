import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../_metronic/helpers'
import {ApplicationForm, ICreateEducator, User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const EDUCATOR_URL = `${API_URL}/educators`
const GET_USERS_URL = `${API_URL}/users`
const ADMIN_EDUCATOR_URL = `${API_URL}/admin/educators`

const createEducator = (formInfo: ICreateEducator): Promise<ICreateEducator | undefined> => {
  return axios
    .post(`${EDUCATOR_URL}`, formInfo)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const updateEducatorStatus = (formIfo: {
  id: string
  status: string
}): Promise<ICreateEducator | undefined> => {
  return axios
    .patch(`${ADMIN_EDUCATOR_URL}/${formIfo.id}/status-update`, {status: formIfo.status})
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const updateEducator = (formInfo: ICreateEducator): Promise<ICreateEducator | undefined> => {
  console.log('🚀 ~ file: _request.ts:28 ~ updateEducator ~ formInfo:', formInfo)

  return axios
    .patch(`${EDUCATOR_URL}/${formInfo._id}`, formInfo)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const getEducatorByID = (id: string): Promise<ICreateEducator | undefined> => {
  return axios
    .get(`${ADMIN_EDUCATOR_URL}/${id}`)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const getEducatorForm = (): Promise<ICreateEducator | undefined> => {
  return axios
    .get(`${EDUCATOR_URL}/get-educator-form`)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

export {createEducator, getEducatorByID, updateEducator, getEducatorForm, updateEducatorStatus}

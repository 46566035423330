import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'
import {riskAssessment} from '../../core/EducatorHelpers'
import Collapsible from '../../../common/Collapsible'

interface Props {
  value: any
  setFieldValue: any
  field: string
}
export const RiskAssessmentMatrix: FC<Props> = ({value, setFieldValue, field}) => {
  console.log(`Risk assessment matrix`, value)
  return (
    <>
      {value?.length > 0 && (
        <Collapsible title={`Risk Assessment`} id={`risk-management-risk-assessment-matrix`}>
          {console.log(`Risk assessment matrix condition true`, riskAssessment)}
          <div className='row'>
            {riskAssessment?.map((label, index) => {
              return (
                <div className='col-md-2 fv-row mb-5' key={index}>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className=''>{label}</span>
                  </label>
                </div>
              )
            })}
          </div>
          {value?.map((riskAssessmentMatrix: any, index: number) => (
            <div className='row' key={index}>
              {/* {Object?.keys(riskAssessmentMatrix)?.map((key: string) => {
                return ( */}

              {/* ) */}
              {/* })} */}

              <div className='col-2'>
                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  name={`${field}[${index}].activity`}
                />
                <div className='text-danger mt-2 mb-2'>
                  <ErrorMessage name={`${field}[${index}].activity`} />
                </div>
              </div>
              <div className='col-2'>
                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  name={`${field}[${index}].hazardIdentified`}
                />
                <div className='text-danger mt-2 mb-2'>
                  <ErrorMessage name={`${field}[${index}].hazardIdentified`} />
                </div>
              </div>
              <div className='col-2'>
                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  name={`${field}[${index}].riskAssessmentMatrix`}
                />
                <div className='text-danger mt-2 mb-2'>
                  <ErrorMessage name={`${field}[${index}].riskAssessmentMatrix`} />
                </div>
              </div>
              <div className='col-2'>
                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  name={`${field}[${index}].eliminationControlMatrix`}
                />
                <div className='text-danger mt-2 mb-2'>
                  <ErrorMessage name={`${field}[${index}].eliminationControlMatrix`} />
                </div>
              </div>
              <div className='col-2'>
                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  name={`${field}[${index}].responsibleForControl`}
                />
                <div className='text-danger mt-2 mb-2'>
                  <ErrorMessage name={`${field}[${index}].responsibleForControl`} />
                </div>
              </div>
              <div className='col-2'>
                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  name={`${field}[${index}].whenControlImplemented`}
                />
                <div className='text-danger mt-2 mb-2'>
                  <ErrorMessage name={`${field}[${index}].whenControlImplemented`} />
                </div>
              </div>
            </div>
          ))}
        </Collapsible>
      )}
    </>
  )
}
